export function isStagedCatalog(dealerCode) {
  return dealerCode.indexOf("__STG_") !== -1;
}

export function getEnhancedDealerCode(dealerCode) {
  const startStaging = dealerCode.indexOf("__STG_");
  return startStaging !== -1
    ? { isStaging: true, dealerCode: dealerCode.substring(0, startStaging) }
    : { isStaging: false, dealerCode };
}
