/* refer:
https://www.quirksmode.org/js/detect.html
https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
*/

export const isIE = document.documentMode;
export const isEdge = /Edge/.test(navigator.userAgent);
export const isFirefox = typeof InstallTrigger !== "undefined";
export const isIEorEdge = isIE || isEdge;

export const detectBrowser = () => {
  const sUsrAg = window.navigator.userAgent;
  let sBrowser = "unknown";
  // The order matters here, and this may report false positives for unlisted browsers.
  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Chrome";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  return sBrowser;
};

export const getBrowserId = () => {
  const aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"],
    sUsrAg = navigator.userAgent,
    nIdx = aKeys.length - 1;
  for (let nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

  return nIdx;
};

export const getMetaTags = () => document.getElementsByTagName("meta");

/* Util to reload application with new UrlParams */
export const loadPageByWebkey = (webKey, locale, isStaged) => {
  const localeParam = `&locale=${locale}`;
  const isStagedParam = isStaged ? "&isStaged=1" : "";
  const { href, hostname } = window.location;
  const uri = href.substring(0, href.indexOf("?"));
  let url = `${uri}?webKey=${webKey}${localeParam}${isStagedParam}`;
  // Testing: For Local development, append userName to URL.
  if (hostname === "localhost") {
    const userParam = "&userName=rajap";
    url = `${uri}?webKey=${webKey}${isStagedParam}${localeParam}${userParam}`;
  }
  const tab = window.location.replace(url);
  tab.focus();
};

function convertToCSV(objArray) {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += array[i] + "\r\n";
  }
  return str;
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = fileTitle + ".csv" || "export.csv";

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (window.navigator.msSaveBlob) {
    // IE 10+
    window.navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
