// refer: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const IS_MOBILE = window.navigator.userAgent.indexOf("Mobi") > -1;
export const IS_IE = window.navigator.userAgent.indexOf("Trident") > -1;
export const IS_EDGE = window.navigator.userAgent.indexOf("Edge") > -1;
export const IS_CHROME = window.navigator.userAgent.indexOf("Chrome") > -1;

/* Use this file, Add ag-grid controls to global state  */
export const initAppConfig = {
  dashboard: {
    make: "all",
    lastPublishMsg: ""
  },
  operationsGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    variantType: "ALL",
    viewMode: "basic"
  },
  opcodeValidationGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    filters: {
      make: "all",
      type: "all",
      kind: "all",
      opcodestatus: "all",
      contentstatus: "all",
      searchkey: ""
    }
  },
  partsPricingGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    variantType: "ALL"
  },
  fluidsPricingGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    variantType: "ALL"
  },
  laborRatesGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: ""
  },
  menuTypesGrid: {
    selectedMenuType: "all",
    showDisabled: true
  },
  dealerMenusGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    make: "",
    searchKey: ""
  },
  packagesGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    packageType: "ALL"
  },
  reportsGrid: {
    make: ""
  },
  translationGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    viewMode: "ALL"
  }
};

/* Util - Updates App Context with operations ag-grid controls state */
export const setOperationGridState = gridState => {
  // console.log("grid state in context", gridState);
  this.setState(() => {
    return {
      opsColState: gridState.colState,
      opsFilterState: gridState.filterState,
      opsSortState: gridState.sortState,
      opsPivotState: gridState.pivotState
    };
  });
};

/* Util - to restore ag-grid controls,filters,sorters from App Context when user re-visits page */
export const restoreGridState = (state, gridApi, gridColumnApi) => {
  const { opsColState, opsFilterState, opsSortState, opsPivotState } = state;
  if (gridApi && gridColumnApi) {
    this.gridColumnApi.setColumnState(opsColState);
    this.gridColumnApi.setPivotMode(opsPivotState);
    this.gridApi.setSortModel(opsSortState);
    this.gridApi.setFilterModel(opsFilterState);
    // console.log(
    //   "[restoreGridState] AG_grid state restored",
    //   opsColState,
    //   opsFilterState,
    //   opsSortState,
    //   opsPivotState
    // );
  }
};

/* Util - to save save ag-grid {column, filter, pivot, sort} to local state
 */
export const saveGridState = (gridApi, gridColumnApi) => {
  if (this.gridApi && this.gridColumnApi) {
    const gridState = {
      colState: this.gridColumnApi.getColumnState(),
      pivotState: this.gridColumnApi.isPivotMode(),
      sortState: this.gridApi.getSortModel(),
      filterState: this.gridApi.getFilterModel()
    };
    this.setState(() => {
      return {
        opsColState: this.gridColumnApi.getColumnState(),
        opsPivotState: this.gridColumnApi.isPivotMode(),
        opsSortState: this.gridApi.getSortModel(),
        opsFilterState: this.gridApi.getFilterModel()
      };
    });
    this.context.setOperationGridState(gridState);
  }
};
