import React from "react";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import SelectInput from "@cx/ui/SelectInput";
import PriceInput from "@cx/ui/PriceInput";
import TextInput from "@cx/ui/TextInput";
import PropTypes from "prop-types";
import * as formvalidator from "../../../../../formik/formvalidator";
import { formatPrice } from "../../../../../../commonUtil/utils/formatter";
import { isDifferentValue } from "../../../../../../commonUtil/utils/string";
import { doesEmpty } from "../../../../../../commonUtil/utils/object";
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import { OperationContext } from "../../../operation-context";
import { getCatalogFromMakeVariantMap } from "./../../../../../../constants/ModuleConstants";
import StatusBox from "../../../../../../commonUtil/components/templates/StatusBox";
/* eslint-disable no-console */

class BaseSettings extends React.Component {
  static contextType = OperationContext;
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.onBlurPrice = this.onBlurPrice.bind(this);
    this.onBlurCaption = this.onBlurCaption.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChange = this.onChange.bind(this);

    const { loadOperation, localeStrings, makeRateCodesMap } = context;
    const laborRateOptions = makeRateCodesMap[loadOperation.make];
    const price = formatPrice(loadOperation.price);
    this.initializeLocaleValues(localeStrings);
    this.state = {
      dirty: false,
      payCode: "",
      priceCaption: loadOperation.priceCaption,
      price,
      dealerLaborRateCodeId: loadOperation.dealerLaborRateCodeId,
      laborRateCodeDesc: loadOperation.laborRateCodeDesc,
      laborRateOptions,
      errors: {
        price: "",
        priceCaption: "",
        dealerLaborRateCodeId: ""
      }
    };
    console.log("Base Settings record", loadOperation, laborRateOptions);
  }

  componentDidMount() {
    // Add logic here if needed
  }

  initializeLocaleValues(localeStrings) {
    this.baseSettingsLabel =
      localeStrings["xmm.portal.operations.tab.pricing_opcodes.base_settings"];
    this.defaultTotalPriceLabel =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.default_total_price"
      ];
    this.defaultLaborRateLabel =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.default_labor_rate"
      ];
    this.priceCaptionLabel =
      localeStrings["xmm.portal.operations.tab.pricing_opcodes.price_caption"];
  }

  // used for pay code
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    this.setState({ [name]: value });
  };

  onChangeLaborRate = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const { dealerCode } = this.context;
    if (isDifferentValue(this.state[name], value)) {
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            dirty: true, // set dirty flag to avoid server call when there is no change
            [name]: value,
            errors
          };
        },
        () => {
          // callback here
          const payload = {
            dealerLaborRateCodeId: doesEmpty(value) ? "" : parseInt(value, 10),
            dealerCode
          };
          this.saveBaseSettings(payload, name);
        }
      );
    }
  };
  onChangePrice = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(this.state[name], value)) {
      this.setState(prevState => {
        const { errors } = prevState;
        return {
          dirty: true, // set dirty flag to avoid server calls when there is no change
          [name]: value,
          errors
        };
      });
    }
  };

  // price = $0 to $9999.99;
  onBlurPrice = (cxEvent, isValid, domEvent) => {
    const { value, id } = cxEvent.target;
    const endIndex = id.indexOf("-") !== -1 ? id.indexOf("-") : id.length;
    const fieldName = id.substring(0, endIndex);
    const valid = this.validate(fieldName);
    const { dirty } = this.state;
    if (dirty && valid) {
      const newVal = formatPrice(value);
      const payload = {
        price: newVal,
        dealerCode: this.context.dealerCode
      };
      this.saveBaseSettings(payload, fieldName);
      this.setState({ price: newVal });
    }
  };

  // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
  onBlurCaption = event => {
    const { value } = event.target;
    const fieldName = "priceCaption";
    const valid = this.validate(fieldName);
    const { dirty } = this.state;
    if (dirty && valid) {
      const payload = {
        priceCaption: doesEmpty(value) ? "" : value,
        dealerCode: this.context.dealerCode
      };
      this.saveBaseSettings(payload, fieldName);
    }
  };

  setErrors(name, errorMsg) {
    const { errors } = this.state;
    errors[name] = !errorMsg ? "" : errorMsg;
    this.setState({
      errors
    });
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { errors } = this.state;
    let valid = false;
    const { price, priceCaption, dealerLaborRateCodeId } = this.state;

    if (doesEmpty(price)) {
      errors["price"] = "";
      valid = true;
    } else if ((parseInt(price, 10) || 0) < 0) {
      errors["price"] = "Minimum value for this field is 0";
    } else if (price && (parseFloat(price) || 0) > 10000) {
      errors["price"] = "Maximum value for this field is 9999.99";
    } else {
      errors["price"] = "";
      valid = true;
    }

    if (doesEmpty(priceCaption)) {
      errors["priceCaption"] = "";
      valid = true;
    } else if (priceCaption) {
      const errorMsg = formvalidator.validateAlphaNumWithSpecialCharacters(
        priceCaption
      );
      errors["priceCaption"] = !errorMsg ? "" : errorMsg;
      valid = !errorMsg ? true : false;
    } else {
      errors["price"] = "";
      valid = true;
    }

    if (doesEmpty(dealerLaborRateCodeId)) {
      valid = true;
      errors["dealerLaborRateCodeId"] = "";
    }
    if (valid) {
      // validate fields any time
      valid = !this.hasErrorStrings(errors);
    }
    this.setState({ valid, errors });
    return valid;
  }

  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(errors) {
    if (Object.keys(errors).length) {
      const array1 = Object.values(errors);
      const iterator = array1.values();
      let errCount = 0;
      for (const value of iterator) {
        if (value === "" || value === null || value.length === 0) {
          // In case of valid error string
        } else if (value && typeof value === "string") {
          errCount++;
        }
      }
      return errCount === 0 ? false : true;
    } else {
      return false;
    }
  }
  /* Handler to save single field edits */
  saveBaseSettings(payload, field) {
    // this.updateStatusBox("Saving", "pending");
    const { loadOperation } = this.context;
    const { make, serviceId } = loadOperation;
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/table/dealerOperation/" +
      serviceId +
      "?_method=put";
    const { makeVariantMap } = this.context.appContext;
    const { baseLocale } = getCatalogFromMakeVariantMap(makeVariantMap, make);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Variant-Base-Locale": baseLocale
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data && data.success) {
          const {
            internalName,
            name,
            description,
            inspectionItem,
            serviceTag
          } = loadOperation;
          const dealerOperation = Object.assign(data.dealerOperation, {
            internalName,
            name,
            description,
            inspectionItem,
            serviceTag
          });
          this.setState({
            dirty: false,
            valid: true
          });
          // update context {loadOperation}
          if (make !== "ANY") {
            if (field === "dealerLaborRateCodeId") {
              this.getDealerLaborRateCode(loadOperation);
            } else {
              Object.assign(loadOperation, dealerOperation);
              if (field === "price") {
                this.props.updatePriceStatus(loadOperation);
              } else {
                this.context.updateGridAfterSave("edit", loadOperation);
              }
            }
          } else {
            loadOperation[field] = payload[field];
            if (field === "price") {
              this.props.updatePriceStatus(loadOperation);
            } else {
              this.context.updateGridAfterSave("edit", loadOperation);
            }
          }
          // this.updateStatusBox("Saved", "success", true);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg);
        // this.updateStatusBox(msg, "error", false, true);
      }
    );
  }

  getDealerLaborRateCode = loadOperation => {
    const { dealerCode } = this.context.appContext;
    const { serviceId } = loadOperation;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationsList",
        method: "get",
        data: {},
        params: { dealerCode, serviceId }
      },
      response => {
        const servicelist = response.data;
        if (servicelist && servicelist.length !== 0) {
          const { dealerLaborRateCodeId } = servicelist[0];
          loadOperation.dealerLaborRateCodeId = dealerLaborRateCodeId;
          this.setState({ dealerLaborRateCodeId }, () => {
            this.context.updateGridAfterSave("edit", loadOperation);
          });
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  updateStatusBox(msg, type, autoClose, errorInTooltip) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose,
        statusType: type,
        errorInTooltip
      });
    });
  }
  renderStatusBox() {
    return this.state.statusMsg ? (
      <div className="xmm-status-override">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
  }

  render() {
    const { errors } = this.state;
    const { loadOperation, appContext } = this.context;
    const { make } = loadOperation;
    const { makeVariantMap, localeStrings } = appContext;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    const clsHide = pricingMethod === 2 || make === "ANY" ? "hidden" : "";
    const clsHideRow = pricingMethod === 0 ? "hidden" : "xmm-fixed-row";
    const basePopover = (
      <div>
        <b>{this.defaultLaborRateLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.labor_rate_tip"]}
        </p>
        <b>{this.defaultTotalPriceLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.total_price_tip"]}
        </p>
        <b>{this.priceCaptionLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.price_caption_tip"]}
        </p>
      </div>
    );
    const statusBox = this.renderStatusBox();
    return (
      <form autoComplete="off">
        <div className="xmm-header-info">
          <h4>{this.baseSettingsLabel}</h4>
          <Popover
            htmlId="popoverBaseSettings"
            popoverContent={basePopover}
            trigger={["click", "outsideClick"]}
          >
            <IconInfoOutline
              htmlId="baseSettingsInfo"
              className="hand-cursor"
            />
          </Popover>
          {statusBox}
        </div>
        <Grid>
          <Row className={clsHideRow}>
            <SelectInput
              htmlId="dealerLaborRateCodeId"
              label={this.defaultLaborRateLabel}
              name="dealerLaborRateCodeId"
              options={this.state.laborRateOptions || []}
              value={this.state.dealerLaborRateCodeId || ""}
              onChange={this.onChangeLaborRate}
              placeholder=""
              displayPlaceholder={true}
              error={errors.dealerLaborRateCodeId}
              className={clsHide}
            />
            <PriceInput
              htmlId="price"
              label={this.defaultTotalPriceLabel}
              name="price"
              autoComplete="off"
              onChange={this.onChangePrice}
              onBlur={this.onBlurPrice}
              maxLength={7}
              error={errors.price}
              value={this.state.price}
            />
            <SelectInput
              htmlId="payCode"
              label="Pay code"
              name="payCode"
              displayPlaceholder={false}
              className="hide"
              onChange={this.onChange}
              options={[
                { value: "1", label: "Item 1" },
                { value: "2", label: "Item 2" },
                { value: "3", label: "Item 3" },
                { value: "4", label: "Item 4" }
              ]}
              value={this.state.payCode || ""}
            />
            <TextInput
              htmlId="priceCaption"
              label={this.priceCaptionLabel}
              name="priceCaption"
              maxLength={64}
              autoComplete="off"
              onChange={this.onChangePrice}
              onBlur={this.onBlurCaption}
              error={errors.priceCaption}
              value={this.state.priceCaption || ""}
            />
          </Row>
        </Grid>
      </form>
    );
  }
}

export default BaseSettings;

BaseSettings.propTypes = {
  operation: PropTypes.object,
  updatePriceStatus: PropTypes.func
};
