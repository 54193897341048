/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import SelectInput from "@cx/ui/SelectInput";
import IconMore from "@cx/ui/Icons/IconMore";
import IconError from "@cx/ui/Icons/IconError";
import IconWarning from "@cx/ui/Icons/IconWarning";
import Tooltip from "@cx/ui/Tooltip";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import CustomNoRowsOverlay from "../../../../commonUtil/components/loadingmask/CustomNoRowsOverlay";
import { DefaultRecord } from "../../../../constants/ModuleConstants";
import {
  applyCustomKeyNavigation,
  applyCustomTextAreaKeyNavigation
} from "../../../../commonUtil/utils/keyNavigation";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import MultiLineLocaleValueEditor from "../../../../commonUtil/editors/MultiLineLocaleValueEditor";
import LocaleValueEditor from "../../../../commonUtil/editors/LocaleValueEditor";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { doesEmpty } from "../../../../commonUtil/utils/object";

class TranslationPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.onGroupByChange = this.onGroupByChange.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    const { localeStrings } = context;
    const { type, name, id } = props;
    this.initializeLocaleValues(props);
    const gridOptions = {
      translationType: type,
      translationId: id,
      translationName: name,
      // other state props
      groupBy: context.translationGrid.groupBy,
      searchKey: context.translationGrid.searchKey,
      translationGrid: context.translationGrid,
      pageTitle: this.translationLabel,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      rowRecord: DefaultRecord,
      sliderWidth: 500,
      flexWidth: true,
      showSlide: false,
      disableAction: false,
      // ag-grid
      viewMode: context.translationGrid.viewMode || "ALL",
      filteredRowData: null,
      rowData: null,
      floatingFilter: true, // true - enable column header filters
      columnDefs: this.getColumnList(),
      defaultColDef: {
        cellClassRules: this.getCellClassRule(),
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: true,
        sortingOrder: ["asc", "desc", null],
        minWidth: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {
        iconCellRenderer,
        iconFilterCellRenderer,
        textCellRenderer
        // categoryAndTypeCellRenderer
      },
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomNoRowsOverlay,
        mleLocaleValueEditor: MultiLineLocaleValueEditor,
        localeValueEditor: LocaleValueEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRowsMessageFunc() {
          return xlate("xmm.portal.common.no_records_msg");
        }
      },
      // Note: Get locale strings from util fun() for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            nullComparator: {
              equals: false,
              lessThan: false,
              greaterThan: false
            },
            clearButton: true
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      rowSelection: "single",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {}
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
  }

  initializeLocaleValues(props) {
    const { name } = props;
    this.translationLabel = xlate("xmm.portal.nav.translations");
    if (name) {
      this.translationLabel = ""; // `${this.translationLabel} - ${name}`;
    }
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.categoryLabel = xlate("xmm.portal.grid.category");
    this.typeLabel = xlate("xmm.portal.grid.type_lbl");
    this.makeLabel = xlate("xmm.portal.grid.make");
    this.baseLanguage = xlate("");
    this.baseValueLabel = xlate(
      "xmm.portal.grid.translation_base_value"
    ).replace("%1", xlate("xmm.portal.common.english"));
    this.translatedValueLabel = xlate(
      "xmm.portal.grid.translation_translated_value"
    ).replace("%1", xlate("xmm.portal.common.french"));
    this.missingTranslationsLabel = xlate(
      "xmm.portal.translations.missing_translations_lbl"
    );
    this.baseValueMatchesTranslation = xlate(
      "xmm.portal.translations.base_value_matches_lbl"
    );
    this.iconStatusLabel = xlate("xmm.portal.grid.status");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
    this.fetchError = xlate("xmm.portal.errors.fetch_dealer_data");
    this.refreshLabel = xlate("xmm.portal.common.refresh_button");
    this.clearFiltersLabel = xlate("xmm.portal.common.clear_filters");
    this.groupByTypeLabel = xlate("xmm.portal.translation.group_by_type");
    this.groupByMakeLabel = xlate("xmm.portal.translation.group_by_make");
    this.groupByBaseValueLabel = xlate(
      "xmm.portal.translation.group_by_base_value"
    );
    this.groupByNoneLabel = xlate("xmm.portal.common.group_by");

    CategoryKeyMap.MENU = xlate("xmm.portal.common.menu_lbl");
    CategoryKeyMap.SERVICE = xlate("xmm.portal.dealermenus.grid.operation"); // : "Operation"

    TypeKeyMap.NAME = xlate("xmm.portal.common.name_lbl"); // : "Name",
    TypeKeyMap.DESC = xlate("xmm.portal.grid.description"); // : "Description",
    TypeKeyMap.EXTNAME = xlate("xmm.portal.operations.grid.external_name"); // : "External Name",
    TypeKeyMap.INTNAME = xlate("xmm.portal.grid.internalname"); // : "Internal Name",
    TypeKeyMap.BENEFITS = xlate("xmm.portal.common.benefits"); // : "Benefits"
  }
  sav;
  saveGridState() {
    const { groupBy, searchKey, viewMode } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const translationGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        sortState: this.gridApi.getSortModel(),
        filterState: this.gridApi.getFilterModel(),
        searchKey,
        viewMode,
        groupBy
      };
      this.setState({
        translationGrid
      });
      this.context.setTranslationGridState(translationGrid);
    }
  }
  restoreGridState() {
    const {
      colState,
      filterState,
      sortState,
      pivotState,
      searchKey,
      viewMode
    } = this.state.translationGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.applyColumnView(viewMode);
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      this.gridApi.setSortModel(sortState);
      this.gridApi.setFilterModel(filterState);
      this.gridApi.setQuickFilter(searchKey);
    }
  }
  getCellClassRule() {
    const cellClassRules = {
      "editable-cell"(params) {
        const { field } = params.colDef;
        if (validationFields.includes(field)) {
          const { data } = params;
          if (data) {
            const { errors } = data;
            if (!errors || (errors && !errors[field])) {
              return true;
            }
          }
        }
      },
      "xmm-grid-cell-error"(params) {
        const { field } = params.colDef;
        if (validationFields.includes(field)) {
          const { data } = params;
          if (data) {
            const { errors } = data;
            if (errors && errors[field]) {
              return true;
            }
          }
        }
      }
    };
    return cellClassRules;
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return getCategoryTypeKey(data); // primary or unique key of record
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    // this.applySortConfig();
  };
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sort: "asc"
      },
      {
        colId: "field",
        sort: "asc"
      },
      {
        colId: "localizedField",
        sort: "asc"
      }
    ];
    this.gridApi && this.gridApi.setSortModel(defaultSortModel);
  }
  updateState(data) {
    if (data) {
      const datalist = data;
      if (datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      datalist.forEach(row => {
        row.iconStatus = getIconStatus(row);
      });
      this.setState(
        {
          filteredRowData: datalist,
          rowData: datalist
        },
        () => {
          this.applySortConfig();
          this.sizeToFit();
        }
      );
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const { translationType, translationId } = this.state;
    const payload = {
      dealerCode
    };
    let restUrl = "/ops/proxyapi/ddsproxy/rest/proc/listAllLocaleValues";
    if (translationType && translationId) {
      if (translationType === "OPERATION") {
        restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getLocaleValuesByService";
        payload.serviceId = translationId;
      } else if (translationType === "MENU_TYPE") {
        restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getLocaleValuesByMenuType";
        payload.menuTypeId = translationId;
      }
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          console.log(data);
          hideBodyMask();
          // this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
          this.restoreGridState();
        }
      },
      error => {
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
        const msg = error["message"] ? error.message : this.fetchError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  // event handler to refresh grid data
  refreshGridData = () => {
    this.loadGridData();
    // if needed update local state
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function() {
      gridApi.refreshCells(params);
    }, 300);
  }
  refreshRow(record) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(getCategoryTypeKey(record));
    if (rowNode) {
      const params = {
        force: true,
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  setFieldValidation(record, field, errorKey) {
    if (!record.errors) {
      record.errors = {};
    }
    record.errors[field] = xlate(errorKey);
    this.refreshRow(record);
    // toast.error(record.errors[field]);
    this.updateStatusBox(record.errors[field], "error", false, true);
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const record = params.data;
    if (record && record.errors && record.errors[field]) {
      record.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      const { category, type } = data;
      switch (field) {
        case "field":
          if (
            doesEmpty(newValue) &&
            !(category === "SERVICE" && type === "DESC") &&
            !(category === "MENU" && type === "BENEFITS")
          ) {
            errorKey = "xmm.portal.errors.empty_base_value";
          }
          break;
        case "localizedField":
          {
            const { errors } = data;
            if (errors && errors["field"]) {
              errorKey = "xmm.portal.errors.empty_base_value";
            }
          }
          break;
        default:
          break;
      }
      data.iconStatus = getIconStatus(data);
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "internalName") {
      // callback logic to open slider, show tooltip, icon click, show modal window etc
      const record = params.data;
      console.log("Callback of cellclickevent for", record);
      // we can pass row record when slider is opened
      this.setState(prevState => ({
        showSlide: !prevState.showSlide,
        editOption: "edit",
        operationName: !record.internalName
          ? "Edit Record"
          : record.internalName,
        rowRecord: record
      }));
    }
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }
  onSaveCellEdit(params) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    this.savePayload(params.data, params.colDef.field, params.value);
  }
  /* celledit handler to save edits */
  savePayload(record, field, value) {
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/saveLocaleValues";
    const payload = this.getCellEditPayload(record, field, value);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload
      },
      data => {
        // ag-grid API to update rowData for edited cell.
        if (this.gridApi) {
          const keyId = getCategoryTypeKey(record);
          const rowNode = this.gridApi.getRowNode(keyId);
          // rowNode.data[field] = dealerOperation[field];
          // Replace the data on the rowNode (all columns in that row). When complete, the grid will refresh the rendered row.
          this.updateStatusBox(this.savedMsg, "success", true);
          rowNode.setData(rowNode.data);
          this.refreshRow(record);
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  getCellEditPayload(record, fieldName, value) {
    const { baseLocale } = this.context;
    const { category, type, id, make, field, localizedField } = record;

    const payload = {
      category,
      type,
      id,
      make,
      field,
      defaultLocale: baseLocale,
      localizedField,
      locale: "fr_CA"
    };

    return payload;
  }
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#page-search-box").value = "";
      this.onSearchBoxChanged();
    }
  }
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#page-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      // this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };
  getColumnList() {
    const baseCols = [
      {
        headerName: this.typeLabel,
        field: "category",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        // cellRenderer: "categoryAndTypeCellRenderer",
        autoHeight: true,
        enableRowGroup: true,
        minWidth: 200,
        // maxWidth: 400,
        sortingOrder: ["asc", "desc"],
        valueGetter: categoryAndTypeGetter,
        filter: "agSetColumnFilter",
        filterParams: {
          applyButton: false,
          clearButton: true
        }
      },
      {
        headerName: this.makeLabel,
        headerClass: "ag-text-header",
        field: "make",
        sortingOrder: ["asc", "desc"],
        suppressSizeToFit: true,
        // maxWidth: 150,
        minWidth: 100,
        editable: false,
        enableRowGroup: true,
        valueFormatter(params) {
          return params.value;
        },
        filter: "agSetColumnFilter",
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.baseValueLabel,
        field: "field",
        editable: true,
        cellEditorSelector: this.valueEditor,
        cellEditorParams: this.valueEditorParams,
        cellClass: "xmm-wrap-cell editable-cell xmm-grid-cell-textarea",
        cellRenderer: textCellRenderer,
        enableRowGroup: true,
        minWidth: 250,
        filterParams: { clearButton: true }
      },
      {
        headerName: this.translatedValueLabel,
        field: "localizedField",
        editable: true,
        cellEditorSelector: this.valueEditor,
        cellEditorParams: this.valueEditorParams,
        cellClass: "xmm-wrap-cell editable-cell xmm-grid-cell-textarea",
        cellRenderer: textCellRenderer,
        enableRowGroup: false,
        minWidth: 250,
        filterParams: { clearButton: true }
      },
      {
        headerName: this.iconStatusLabel,
        field: "iconStatus",
        headerClass: "ag-text-header",
        cellClass: "white-bg",
        enableRowGroup: false,
        enableValue: true,
        cellRendererFramework: iconCellRenderer,
        // cellRenderer: "iconCellRenderer",
        // tooltipField: "translateStatus",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        suppressMenu: true,
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        filter: false,
        filterParams: {
          cellRenderer: "iconFilterCellRenderer",
          newRowsAction: "keep",
          clearButton: true
        }
      }
    ];
    return baseCols;
  }
  applyColumnView = viewMode => {
    const { rowData } = this.state;
    let filteredRowData = [];
    switch (viewMode) {
      case "ALL":
        filteredRowData = rowData;
        break;
      case "MISSING":
        filteredRowData = rowData.filter(data => {
          const { localizedField } = data;
          return doesEmpty(localizedField);
        });
        break;
      case "BASE":
        filteredRowData = rowData.filter(data => {
          const { field, localizedField } = data;
          return field === localizedField;
        });
        break;
      default:
        filteredRowData = rowData;
        break;
    }
    this.setState({ viewMode, filteredRowData });
  };
  onColumnViewChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { viewMode } = this.state;
    if (viewMode !== value) {
      this.applyColumnView(value);
    }
  };
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  valueEditor(params) {
    const { category, type } = params.data;
    if (
      (category === "MENU" && type === "BENEFITS") ||
      (category === "SERVICE" && type === "DESC")
    ) {
      params.colDef.suppressKeyboardEvent = applyCustomTextAreaKeyNavigation;
      return {
        component: "mleLocaleValueEditor"
      };
    }
    params.colDef.suppressKeyboardEvent = applyCustomKeyNavigation;
    return { component: "localeValueEditor" };
  }
  valueEditorParams(params) {
    const { category, type } = params.data;
    if (
      (category === "MENU" && type === "BENEFITS") ||
      (category === "SERVICE" && type === "DESC")
    ) {
      return {
        maxLength: "4000", // override the editor defaults
        getCategoryTypeKey
      };
    }
    return null;
  }
  updateGroupByState = groupBy => {
    this.setState({ groupBy }, () => {
      this.sizeToFit();
    });
  };
  onGroupByChange = changeEvent => {
    const groupBy = changeEvent.target.value;
    const { columnDefs } = this.state;
    columnDefs.forEach((columnDef, index) => {
      const { field } = columnDef;
      columnDef.rowGroup = field === groupBy;
      columnDef.rowGroupIndex = field === groupBy ? 0 : null;
      // columnDef.rowGroup = true;
      // columnDef.rowGroupIndex = index;
    });
    this.setState({ groupBy }, () => {
      this.gridApi.setColumnDefs(columnDefs);
      this.sizeToFit();
    });
    // this.gridApi.setColumnDefs(columnDefs);
    // this.updateGroupByState(groupsBy);
    // this.clearGridSelections();
  };
  render() {
    const { translationType, pageTitle } = this.state;
    const formHeaderClass = translationType ? "hide" : "xmm-form-header";
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            deltaColumnMode={true} // for row grouping
            suppressMenuHide={false}
            suppressContextMenu={true}
            suppressRowClickSelection={true}
            rowData={this.state.filteredRowData}
            getRowNodeId={this.getRowNodeId}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            floatingFilter={this.state.floatingFilter}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={50}
          />
        </div>
      </div>
    );
    const msgSection = null;
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    const pageTitleClass = doesEmpty(pageTitle) ? "hide" : "xmm-main-title";

    const header = (
      <React.Fragment>
        {msgSection}
        <div className="content-header">
          <div className={pageTitleClass}>
            <h3>{pageTitle}</h3>
          </div>
          <div className={formHeaderClass}>
            {statusHtml}
            <div className="xmm-input-search">
              <input
                type="text"
                id="page-search-box"
                className="xmm-input"
                placeholder={this.searchLabel}
                value={this.state.searchKey}
                autoComplete="off"
                onChange={this.onSearchBoxChanged}
                // onInput={this.onSearchBoxChanged}
              />
            </div>
            <SelectInput
              htmlId="GroupBySelector"
              placeholder={this.groupByNoneLabel}
              disabled={false}
              displayLabel={false}
              displayPlaceholder={true}
              maxHeight={100}
              name="groupType"
              onChange={this.onGroupByChange}
              label="Group by"
              value={this.state.groupBy}
              options={[
                {
                  value: "category",
                  label: this.groupByTypeLabel
                },
                {
                  value: "make",
                  label: this.groupByMakeLabel
                },
                {
                  value: "field",
                  label: this.groupByBaseValueLabel
                  // },
                  // {
                  //   value: "localizedField",
                  //   label: xlate("xmm.portal.translations.canada_french_lbl")
                }
              ]}
            />
            <SelectInput
              htmlId="ColumnViewSelect"
              placeholder="Select"
              disabled={false}
              displayLabel={false}
              displayPlaceholder={false}
              maxHeight={100}
              name="viewMode"
              onChange={this.onColumnViewChange}
              label="View"
              value={this.state.viewMode}
              options={[
                {
                  value: "ALL",
                  label: xlate("xmm.portal.translations.all_translations_lbl")
                },
                {
                  value: "MISSING",
                  label: xlate(
                    "xmm.portal.translations.missing_translations_lbl"
                  )
                },
                {
                  value: "BASE",
                  label: xlate("xmm.portal.translations.base_value_matches_lbl")
                }
              ]}
            />
            <DropdownButton
              title={<IconMore />}
              id="settingsActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              pullRight
            >
              <DropdownMenuItem
                htmlId="refreshBtn"
                eventKey={{ eventKey: ["refresh"] }}
                onSelect={this.refreshGridData}
              >
                {this.refreshLabel}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="clearFiltersBtn"
                eventKey={{ eventKey: ["clear-filters"] }}
                onSelect={this.clearFilters}
              >
                {this.clearFiltersLabel}
              </DropdownMenuItem>
            </DropdownButton>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {header}
        {gridWidget}
      </React.Fragment>
    );
  }
}

export default TranslationPage;

TranslationPage.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string
};

const validationFields = ["field", "localizedField"];

const CategoryKeyMap = {
  MENU: "Menu",
  SERVICE: "Service"
};
const TypeKeyMap = {
  NAME: "Name",
  DESC: "Description",
  EXTNAME: "External Name",
  INTNAME: "Internal Name",
  BENEFITS: "Benefits"
};
// function categoryAndTypeCellRenderer(params) {
//   const { category, type } = params.data;
//   const categoryLabel = CategoryKeyMap[category];
//   const typeLabel = TypeKeyMap[type];
//   return `${categoryLabel} = ${typeLabel}`;
// }
function getIconStatus(row) {
  return doesEmpty(row.localizedField)
    ? "MISSING"
    : row.field === row.localizedField
    ? "BASE"
    : "";
}

function getCategoryTypeKey(row) {
  const { id, category, type, locale } = row;
  const keyId = `${category}_${type}_${locale}_${id}`;
  return keyId;
}
function categoryAndTypeGetter(params) {
  const { data } = params;
  if (!data) {
    return "";
  }
  const { category, type } = data;
  const categoryLabel = CategoryKeyMap[category];
  const theType = category === "MENU" && type === "DESC" ? "NAME" : type;
  const typeLabel = TypeKeyMap[theType];
  return `${categoryLabel} - ${typeLabel}`;
}
function iconCellRenderer(params) {
  const { data } = params;
  if (!data) {
    return "";
  }
  const { field, localizedField } = data;
  if (!localizedField) {
    const keyId = getCategoryTypeKey(data); // `${category}_${type}_${id}_icon`;
    const errorSummary = xlate(
      "xmm.portal.translations.missing_translations_lbl"
    );
    return (
      <div className="xmm-override-msg">
        {" "}
        <Tooltip htmlId={keyId} tooltipContent={errorSummary}>
          <IconError className="xmm-override-error" />
        </Tooltip>
      </div>
    );
  } else if (field === localizedField) {
    const keyId = getCategoryTypeKey(data); // `${category}_${type}_${id}_icon`;
    const warningMessage = xlate(
      "xmm.portal.translations.base_value_matches_lbl"
    ); // "Base Value matches Translation";
    return (
      <div className="xmm-override-msg">
        {" "}
        <Tooltip htmlId={keyId} tooltipContent={warningMessage}>
          <IconWarning className="xmm-override-warn" />
        </Tooltip>
      </div>
    );
  }
  return "";
}

function iconFilterCellRenderer(params) {
  const statusValue = params.value;
  let iconHtml = "";
  if (statusValue === "BASE") {
    iconHtml =
      '<i class="fas fa-exclamation-triangle" style="color: #ffaa00"></i>' +
      xlate("xmm.portal.translations.base_value_matches_lbl");
  } else if (statusValue === "MISSING") {
    iconHtml =
      '<i class="fas fa-exclamation-circle"></i>' +
      xlate("xmm.portal.translations.missing_translations_lbl");
  } else {
    iconHtml = `(${xlate("xmm.portal.common.lowercase_empty")})`;
  }
  return iconHtml;
}

function textCellRenderer(params) {
  const rawText = params.value;
  if (rawText) {
    const displayText = rawText.split("\n").join("<br/>");
    // const displayText = rawText.replace("\n", "<br>");
    return `<div>${displayText}</div>`;
  }
  return "";
}
/* eslint-enable no-console */
