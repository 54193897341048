/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from "react";
import { namespace } from "../../api/xmmAxios";

export const DisplayFormikState = props => (
  <div style={{ margin: "1rem 0" }}>
    <pre
      style={{
        background: "#f6f8fa",
        color: "#000",
        fontSize: ".65rem",
        padding: ".5rem"
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export function HelpText() {
  return (
    <div>
      <h1>How to reproduce?</h1>
      <ol>
        <li>Open console</li>
        <li>Fill field and click "Submit". You will see success message</li>
      </ol>
    </div>
  );
}

/**
 * returns consumerURL based on env
 * @returns {*}
 */
export function getConsumerAppUrl(catalog, locale) {
  const { webKey, make, variant } = catalog;
  const hostname = window.location.hostname;
  let launchUrl = "https://xtime.com";

  const dc = new Date().getTime();
  const params = {
    _dc: dc,
    webKey,
    make,
    variant,
    locale
  };
  const queryString = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");

  const env = namespace;
  const isAusDomain = hostname.indexOf(".xtime.net.au") !== -1 ? true : false;

  if (isAusDomain) {
    if (!env) {
      // prod
      launchUrl = "https://consumer.xtime.net.au/menu/?" + queryString;
    } else {
      launchUrl =
        window.location.protocol +
        "//consumer-" +
        env +
        ".xtime.net.au/menu/?" +
        queryString;
    }
  } else {
    // US Domain
    if (!env) {
      // prod
      launchUrl = "https://consumer.xtime.com/menu/?" + queryString;
    } else {
      launchUrl =
        window.location.protocol +
        "//consumer-" +
        env +
        ".xtime.com/menu/?" +
        queryString;
    }
  }

  return launchUrl;
}

// Async Validation
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// How to use sleep?
// sleep(300).then(() => {
// console.log("Timeout started");
// });
