/* eslint-disable no-console */
export const KEY_ENTER = 13;
export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;

export function applyCustomKeyNavigation(params) {
  const { colDef, event, api, node, column } = params;
  const { shiftKey } = event;
  const keyPress = event.which;
  const suppress = keyPress === KEY_ENTER;
  // console.log("key navigation: ", params);
  // console.log("cell is editing: " + params.editing);
  // console.log("keyboard event:", params.event);
  if (suppress) {
    event.preventDefault();
    event.stopPropagation();
    // Enter key is pressed - key down or up based on Enter/Shirt-Enter
    const currentCell = { column, rowIndex: node.rowIndex };
    const arrowKey = shiftKey ? KEY_UP : KEY_DOWN;
    if (api.rowRenderer && api.rowRenderer.navigateToNextCell) {
      api.rowRenderer.navigateToNextCell(event, arrowKey, currentCell);
    }
    // Make the next cell editable if possible
    let rowIndex = node.rowIndex + (shiftKey ? -1 : 1);
    if (rowIndex < 0) {
      rowIndex = 0;
    }
    const colKey = colDef.field;
    setTimeout(() => {
      api.startEditingCell({
        rowIndex,
        colKey,
        keyPress
      });
    }, 0);
  }
  return suppress;
}

export function applyCustomTextAreaKeyNavigation(params) {
  const keyPress = params.event.which;
  return keyPress === KEY_ENTER;
}

// export function navigateToNextCell(params) {
//   const previousCell = params.previousCellPosition;
//   const suggestedNextCell = params.nextCellPosition;
//   switch (params.key) {
//     case KEY_ENTER: {
//       const nextRowIndex = previousCell.rowIndex + 1;
//       if (nextRowIndex < 0) {
//         return null;
//       } else {
//         return {
//           rowIndex: nextRowIndex,
//           column: previousCell.column,
//           floating: previousCell.floating
//         };
//       }
//     }
//     default:
//       break;
//   }
//   return suggestedNextCell;
// }
