/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { toast } from "@cx/ui/Toast";
import { bool, func, number, object, string } from "prop-types";
import { FormattedMessage } from "react-intl";
import { AgGridReact } from "ag-grid-react";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import WeekDaysEditor from "../editors/WeekDaysEditor";
import {
  DaysOfWeekOptions,
  initialStoreHours
} from "../constants/StoreHourConstants";
import CustomTooltip from "../../../commonUtil/components/reusable/CustomToolTip";
import WeekDaysToolTip from "../../../commonUtil/editors/WeekDaysToolTip";
import IconMore from "@cx/ui/Icons/IconMore";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import {
  getDaysOfWeekString,
  toEmptyStringIfUndefined
} from "../../../commonUtil/utils/string";
import { isArrayExist } from "../../../commonUtil/utils/object";
import CustomLoadingOverlay from "../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { toNumber } from "../../../commonUtil/utils/value";
import { applyCustomKeyNavigation } from "../../../commonUtil/utils/keyNavigation";
import { xlate, locale } from "../../../commonUtil/i18n/locales";

export default class StoreHoursDialog extends React.Component {
  constructor(props) {
    super(props);

    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    const { localeStrings } = this.props;
    initialStoreHours();
    const gridOptions = {
      columnDefs: this.getColumnList(props.localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        // make every column editable
        editable: true,
        width: 120,
        // cellClass: "cell-wrap-text", // make all columns wraptext
        // make every column use 'text' filter by default
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false,
        filter: "agTextColumnFilter"
      },
      isRowSelectable(rowNode) {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      rowData: null,
      storeHours: [],
      selectionlist: [],
      // define a column type (you can define as many as you like)
      columnTypes: {
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        nonEditableColumn: { editable: false }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        customTooltip: CustomTooltip,
        weekdaysTooltip: WeekDaysToolTip,
        weekDaysEditor: WeekDaysEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // serviceHoursCount: props.serviceHoursCount,
      isDirty: false,
      dirtyStoreHours: []
    };
    this.state = gridOptions;
  }

  isDirty = () => {
    return this.state.isDirty;
  };

  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        field: "checked",
        type: "actionColumn",
        suppressSizeToFit: true,
        maxWidth: 40,
        minWidth: 40,
        width: 40,
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: localeStrings["xmm.portal.common.name_lbl"],
        field: "name",
        tooltipField: "name",
        tooltipComponent: "customTooltip",
        tooltipComponentParams: { field: "name" },
        autoHeight: true,
        sort: "asc",
        filter: "agSetColumnFilter",
        width: 210,
        // suppressSizeToFit: true,
        enableRowGroup: false,
        cellEditor: "agTextCellEditor",
        cellStyle(params) {
          // console.log("name", params.value);
          if (
            toEmptyStringIfUndefined(params.value) === "" ||
            params.value === null
          ) {
            // mark empty cells as red
            return { border: "1px solid #dd2b2b !important" };
          } else {
            return { border: "none" };
          }
        },
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: localeStrings["xmm.portal.operations.form.daysOfWeek"],
        field: "daysOfWeek",
        tooltipField: "daysOfWeek",
        tooltipComponent: "weekdaysTooltip",
        tooltipComponentParams: { field: "daysOfWeek" },
        cellEditor: "weekDaysEditor",
        valueFormatter: daysOfWeekValueFormatter,
        sortingOrder: ["asc", "desc"],
        enableRowGroup: false,
        width: 220,
        // autoHeight: true,
        cellStyle(params) {
          // console.log("days", params.value);
          if (params.value === "" || params.value === null) {
            // mark empty cells as red
            return { border: "1px solid #dd2b2b !important" };
          } else if (isArrayExist(params.value) && params.value.length === 0) {
            return { border: "1px solid #dd2b2b !important" };
          } else {
            return { border: "none" };
          }
        },
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: localeStrings["xmm.portal.operations.form.startTime"],
        field: "startTime",
        enableRowGroup: false,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: Object.keys(getStoreHoursRefData())
            }
          };
        },
        refData: getStoreHoursRefData(),
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        width: 95
      },
      {
        headerName: localeStrings["xmm.portal.operations.form.endTime"],
        field: "endTime",
        enableRowGroup: false,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: Object.keys(getStoreHoursRefData())
            }
          };
        },
        refData: getStoreHoursRefData(),
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        width: 95
      }
    ];
    return baseCols;
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // if (!this.state.storeHours || this.state.storeHours.length === 0) {
    const { bulkEdit } = this.props;
    if (!bulkEdit) {
      this.loadStoreHours();
    }
    // }

    // this.gridApi.setRowData(this.state.storeHours);
    // this.gridApi.setSortModel(this.state.sortConfig);
    this.gridApi.closeToolPanel();
  };

  checkErrors = storeHours => {
    const nameMap = {};
    const weekDaysMap = {};
    const errors = {};
    storeHours.forEach(o => {
      if (o.name.trim() === "") {
        errors.name = "Please enter Name.";
      } else if (nameMap[o.name] !== undefined) {
        errors.duplicateName = "Name is duplicated.";
      } else {
        nameMap[o.name] = true;
      }
      if (o.daysOfWeek.length === 0) {
        errors.daysOfWeek = "Please select Week Day(s).";
      } else {
        o.daysOfWeek.forEach(day => {
          if (weekDaysMap[day.value] !== undefined) {
            errors[day.value] = `${day.label} is overlapped.`;
          } else {
            weekDaysMap[day.value] = true;
          }
        });
      }
      if (toNumber(o.endTime) <= toNumber(o.startTime)) {
        errors.hours = "Please select valid From and To Time.";
      }
    });
    return errors;
  };

  validateStoreHours = storeHours => {
    const errors = this.checkErrors(storeHours);
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      keys.forEach(key => {
        toast.error(errors[key]);
      });
      return false;
    }
    return true;
  };

  saveStoreHours = storeHours => {
    let serviceHoursCount = storeHours.length;

    window.dispatchEvent(
      new CustomEvent("updateStoreHoursCount", {
        detail: { serviceHoursCount },
        bubbles: true,
        cancelable: true
      })
    );

    storeHours.forEach(v => {
      makeSecureRestApi(
        {
          url: "/ops/proxyapi/ddsproxy/rest/proc/editServiceHoursSingle",
          method: "get",
          data: {},
          // serviceHoursId=&serviceId=6989170&name=Store%20Hours%201&daysOfWeek=1010101&startTime=420&endTime=1020
          params: {
            serviceHoursId: v.serviceHoursId,
            serviceId: this.props.serviceId,
            name: v.name,
            daysOfWeek: getDaysOfWeekString(v.daysOfWeek),
            startTime: v.startTime,
            endTime: v.endTime
          }
        },
        data => {
          serviceHoursCount--;
          if (v.serviceHoursId === undefined || v.serviceHoursId === "") {
            v.serviceHoursId = data[0].serviceHoursId;
          }
          // this.setState({ storeHours: data });
          // this.gridApi.setRowData(data);
          this.setState({
            serviceHoursCount: this.state.storeHours.length === 0 ? "0" : "1"
          });
          if (serviceHoursCount === 0) {
            toast.success("Changes are saved.");
            this.setState({ isDirty: false });
          }
        },
        error => {
          toast.error(error.message);
        }
      );
    });
  };

  loadStoreHours = () => {
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/serviceHours",
        method: "get",
        data: {},
        params: { serviceId: this.props.serviceId }
      },
      data => {
        const storeHours = this.convertDaysOfWeek(data);
        this.setState({ storeHours, isDirty: false });
        // this.gridApi.setRowData(storeHours);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  convertDaysOfWeek(list) {
    if (list && list.length > 0) {
      list.forEach(v => {
        v.daysOfWeek = this.convertToWeekDaysOption(v.daysOfWeek);
      });
    }
    return list;
  }

  convertToWeekDaysOption(daysOfWeek) {
    const daysOfWeekOption = [];
    for (let i = 0; i < daysOfWeek.length; i++) {
      const flag = daysOfWeek.charAt(i);
      if (flag === "1") {
        daysOfWeekOption.push(DaysOfWeekOptions[i]);
      }
    }
    return daysOfWeekOption;
  }

  getRowNodeId(data) {
    return data.serviceHoursId;
  }
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  deleteStoreHours(selectedStoreHours) {
    const { storeHours } = this.state;
    const serviceHoursCount = storeHours.length - selectedStoreHours.length;
    window.dispatchEvent(
      new CustomEvent("updateStoreHoursCount", {
        detail: { serviceHoursCount },
        bubbles: true,
        cancelable: true
      })
    );

    selectedStoreHours.forEach(o => {
      //
      if (o.serviceHoursId === "") {
        return;
      }
      makeSecureRestApi(
        {
          url: "/ops/proxyapi/ddsproxy/rest/proc/deleteServiceHoursSingle",
          method: "get",
          data: {},
          params: { serviceHoursId: o.serviceHoursId }
        },
        data => {
          toast.success(xlate("xmm.portal.msg.deleted_custom_hours"), {
            autoClose: 2000,
            closeOnClick: true
          });
        },
        error => {
          toast.error(error.message);
        }
      );
    });
    // const res = this.gridApi.updateRowData({
    //   remove: this.state.selectionlist
    // });
    // console.log("deleteStoreHours", res);
    // const currentStoreHours = this.state.storeHours;
    const newStoreHours = storeHours.filter(
      o => !selectedStoreHours.includes(o)
    );
    this.setState({
      storeHours: newStoreHours
      // serviceHoursCount: newStoreHours.length
    });
  }

  onCellValueChanged = params => {
    // console.log("onCellValueChanged", params, params.oldValue, params.newValue);
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // const record = params.data;
      // console.log("onCellValueChanged: ", params.colDef.field, record);
      this.setState({ isDirty: true });
    }
  };

  render() {
    return (
      <div>
        <React.Fragment>
          <ModalDialog
            // width="600"
            htmlId={this.props.dialogHtmlId}
            // className="ag-grid-storehours-container"
            show={this.props.show}
            backdrop={"static"}
            className="xmm-hours-modal"
            header={
              <ModalDialog.Title>
                <FormattedMessage
                  defaultMessage={this.props.title}
                  id={this.props.titleId}
                />
              </ModalDialog.Title>
            }
            footer={
              <div>
                <Button
                  htmlId={this.props.closeHtmlId}
                  onClick={this.props.doClose}
                  text={this.props.closeButtonLabel}
                  hidden={this.props.closeButtonLabel === null}
                >
                  <FormattedMessage
                    defaultMessage={this.props.closeButtonLabel}
                    id={this.props.closeButtonLabelId}
                  />
                </Button>
              </div>
            }
            onHide={this.props.doClose}
          >
            <div className="content-header">
              <div className="xmm-form-header">
                <Button
                  htmlId="addHours"
                  // buttonStyle="primary"
                  disabled={this.state.storeHours.length >= 7}
                  onClick={event => {
                    const blankStoreHours = {
                      serviceHoursId: "",
                      serviceId: this.props.serviceId,
                      name: "",
                      daysOfWeek: [],
                      startTime: 420,
                      endTime: 1020
                    };
                    // this.state.storeHours.push(blankStoreHours);
                    // const res = this.gridApi.updateRowData({
                    //   add: [blankStoreHours]
                    // });
                    // console.log("add hours", res);
                    this.setState({
                      storeHours: [...this.state.storeHours, blankStoreHours]
                    });
                    // this.gridApi.setRowData(this.state.storeHours);
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Add Hours"
                    id="xmm.portal.operations.form.addHours"
                  />
                </Button>
                <Button
                  htmlId="saveHours"
                  buttonStyle="primary"
                  disabled={!this.state.isDirty}
                  onClick={event => {
                    const { storeHours } = this.state;
                    if (this.validateStoreHours(storeHours)) {
                      if (!this.props.bulkEdit) {
                        this.saveStoreHours(storeHours);
                      } else {
                        this.props.bulkEditServiceHoursCallback(storeHours);
                        this.setState({ isDirty: false }, () => {
                          this.props.doClose();
                        });
                      }
                    }
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Save Hours"
                    id="xmm.portal.operations.form.saveHours"
                  />
                </Button>
                <DropdownButton
                  title={<IconMore />}
                  id="operationsActionBtn"
                  className="xmm-dotted-dropdown btn--icon"
                  pullRight
                >
                  <DropdownMenuItem
                    htmlId="deleteLStoreHoursMenuItem"
                    eventKey={{ eventKey: ["store-hours-delete"] }}
                    disabled={this.state.selectionlist.length === 0}
                    onSelect={() => {
                      const selectedStoreHours = this.state.selectionlist;
                      this.deleteStoreHours(selectedStoreHours);
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Delete"
                      id="xmm.portal.common.delete_button"
                    />
                  </DropdownMenuItem>
                </DropdownButton>
              </div>
            </div>
            <div
              id="storeHoursDialog"
              className="ag-grid-storehours-container ag-theme-balham"
            >
              <AgGridReact
                localeText={this.state.localeText}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                rowSelection={"multiple"}
                rowDeselection={true}
                isRowSelectable={this.state.isRowSelectable}
                suppressRowClickSelection={true}
                suppressMenuHide={false}
                suppressContextMenu={true}
                rowData={this.state.storeHours}
                // components={this.state.components}
                columnTypes={this.state.columnTypes}
                onGridReady={this.onGridReady}
                // onColumnResized={this.handleColumnResized}
                // onGridSizeChanged={this.handleGridSizeChanged}
                // onRowSelected={this.handleRowSelected}
                onSelectionChanged={this.handleSelectionChanged}
                frameworkComponents={this.state.frameworkComponents}
                loadingOverlayComponent={this.state.loadingOverlayComponent}
                loadingOverlayComponentParams={
                  this.state.loadingOverlayComponentParams
                }
                noRowsOverlayComponent={this.state.noRowsOverlayComponent}
                noRowsOverlayComponentParams={
                  this.state.noRowsOverlayComponentParams
                }
                singleClickEdit={true}
                stopEditingWhenGridLosesFocus={true}
                animateRows={true}
                rowStyle={this.state.rowStyle}
                rowHeight={50}
                statusBar={this.state.statusBar}
                // onCellEditingStarted={this.onCellEditingStarted}
                onCellValueChanged={this.onCellValueChanged}
                onCellClicked={this.onCellClickedEvent}
                enableRangeSelection={false}
                enableCharts={false}
                enableCellTextSelection={true}
                enableBrowserTooltips={false}
              />
            </div>
          </ModalDialog>
        </React.Fragment>
      </div>
    );
  }
}
function getStoreHoursRefData() {
  const is24Hour = locale.indexOf("_CA") !== -1;
  if (is24Hour) {
    return getStoreHoursRef24HrData();
  } else {
    return getStoreHoursRef12HrData();
  }
}
function getStoreHoursRef12HrData() {
  const storeHourOptions = {};
  for (let minutes = 0; minutes < 1440; minutes += 5) {
    const ampm = minutes < 720 ? "AM" : "PM";
    let min = minutes % 60;
    const hour = (minutes - min) / 60;
    let hr = hour === 0 ? "12" : ampm === "PM" ? hour % 12 : hour;
    if (ampm === "PM" && hr === 0) {
      hr = "12";
    }
    if (hr < 10) {
      hr = "0" + hr;
    }
    if (min < 10) {
      min = "0" + min;
    }
    storeHourOptions[minutes] = `${hr}:${min} ${ampm}`;
  }
  return storeHourOptions;
}
function getStoreHoursRef24HrData() {
  const storeHourOptions = {};
  for (let minutes = 0; minutes < 1440; minutes += 5) {
    let min = minutes % 60;
    let hr = (minutes - min) / 60;
    if (hr < 10) {
      hr = "0" + hr;
    }
    if (min < 10) {
      min = "0" + min;
    }
    storeHourOptions[minutes] = `${hr}:${min}`;
  }
  return storeHourOptions;
}

function daysOfWeekValueFormatter(params) {
  const value = params.value;
  if (value && Array.isArray(value)) {
    const newValues = value.map(v => {
      return v.abbr;
    });
    return newValues;
  }
  return value;
}

// function storeHourValueGetter(params) {
//   return params.data[params.colDef.field] + "";
// }

StoreHoursDialog.propTypes = {
  show: bool,
  // default labels
  title: string,
  message: string,
  // actionButtonLabel: string,
  closeButtonLabel: string,
  // cancelButtonLabel: string,
  // html ids
  dialogHtmlId: string,
  actionHtmlId: string,
  closeHtmlId: string,
  // cancelHtmlId: string,
  // localization ids
  titleId: string,
  // messageId: string,
  // actionButtonLabelId: string,
  closeButtonLabelId: string,
  // cancelButtonLabelId: string,
  // action functions
  doAction: func,
  doClose: func,
  // doCancel: func
  localeStrings: object,
  serviceId: string,
  serviceHoursCount: number,
  bulkEdit: bool,
  bulkEditServiceHoursCallback: func
};
