/* eslint-disable no-console */

import Button from "@cx/ui/Button";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import IconMore from "@cx/ui/Icons/IconMore";
import SelectInput from "@cx/ui/SelectInput";
import Tooltip from "@cx/ui/Tooltip";
import { toast } from "@cx/ui/Toast";
import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import AlertBox from "../../../../../commonUtil/components/templates/AlertBox";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { FormattedMessage } from "react-intl";
import {
  InspectionItemCategory,
  getInspectionItemDisplay,
  updateOperationStatuses
} from "../../../../../components/reusable/operation";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../../api/xmmAxios";
import GenericSlider from "../../../../../commonUtil/components/GenericSlider";
import CustomLoadingOverlay from "../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import FindOpCodesDialog from "../../../../../commonUtil/dialog/FindOpCodesDialog";
import NumericEditor from "../../../../../commonUtil/editors/NumericEditor";
import OpcodeEditor from "../../../../../commonUtil/editors/OpcodeEditor";
import PriceEditor from "../../../../../commonUtil/editors/PriceEditor";
import TextEditor from "../../../../../commonUtil/editors/TextEditor";
import SimpleCellEditor from "../../../../../commonUtil/prototypes/SimpleCellEditor";
import CheckboxCell from "../../../../../commonUtil/renders/CheckboxCell";
import { sortByMake } from "../../../../../commonUtil/utils/filter";
import {
  boolToStringFormatter,
  extractLaborRates,
  extractLaborRateValues,
  priceFormatter
} from "../../../../../commonUtil/utils/formatter";
import {
  // priceValueSetter,
  // shopDurationValueSetter,
  serviceKindSetter
} from "../../../../../commonUtil/utils/valuesetter";
import { isTrue } from "../../../../../commonUtil/utils/value";
import {
  convertBooleanToBinary,
  isArrayExist,
  isEmpty,
  deepCopyFromTemplate,
  doesEmpty
} from "../../../../../commonUtil/utils/object";
import {
  toEmptyStringIfUndefined,
  isSameValue,
  isDifferentValue
} from "../../../../../commonUtil/utils/string";
import { getCellClassRule } from "../../../../../commonUtil/utils/validation";
import { AppContext } from "../../../../../components/app-context";
import {
  EditAnyMakePayload,
  MetaVehicleScopeAnyCatalogMap,
  MetaVehicleScopeDealerCatalogMap,
  MetaVehicleScopeMap,
  MetaVehicleScopeVariantCatalogMap,
  OperationState,
  ServiceKindMap,
  CreatedByMap,
  PriceStatusMap,
  getCatalogFromMakeVariantMap
} from "../../../../../constants/ModuleConstants";
import { loadAgGridLocale } from "../../../../../i18n/LocaleSender";
import { OperationContext } from "../../operation-context";
import CustomTooltip from "../../../../../commonUtil/components/reusable/CustomToolTip";
import BulkEditPane from "../content/bulkedit/BulkEditPane";
import OperationTabsNew from "../content/tabs/OperationTabsNew";
import Confirmation from "../../../../../commonUtil/dialog/Confirmation";
import ExportDealerOperationsModal from "./ExportDealerOperationsModal";
import ImportDealerOperationsModal from "./ImportDealerOperationsModal";
// eslint-disable-next-line import/no-named-as-default
import DeleteFactoryOperationsWarning from "./DeleteFactoryOperationsWarning";
import * as gtmEvents from "../../../../utils/gtag-eventlist";

import "ag-grid-enterprise/chartsModule";
import StoreHoursDialog from "../../../../reusable/modals/StoreHoursDialog";
import {
  applyCustomKeyNavigation
  // navigateToNextCell (tbd)
} from "../../../../../commonUtil/utils/keyNavigation";
import { xlate } from "../../../../../commonUtil/i18n/locales";

// Add argu "context" to Constructor if this component wrapped with AppContext.Provider {global state}
// Make sure you have static "contextType" in class based
let tellUsMoreServiceId = "";

export class SummaryView extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    tellUsMoreServiceId = context.tellUsMoreServiceId;
    // console.log("AppContext in [SummaryView.js]", context);
    // Bind functions - define them in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.addOperationSlider = this.addOperationSlider.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleDeleteOperations = this.handleDeleteOperations.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.handleRowSelected = this.handleRowSelected.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.laborRateCellClass = this.laborRateCellClass.bind(this);
    this.laborRateCellRenderer = this.laborRateCellRenderer.bind(this);
    this.laborRateCellEditorParams = this.laborRateCellEditorParams.bind(this);
    // this.laborRateFormatter = this.laborRateFormatter.bind(this);
    this.laborTimingRangeCellClass = this.laborTimingRangeCellClass.bind(this);
    this.laborTimingRangeCellRenderer = this.laborTimingRangeCellRenderer.bind(
      this
    );
    this.priceCellClass = this.priceCellClass.bind(this);
    this.priceCellRender = this.priceCellRender.bind(this);
    this.priceCellEditorParams = this.priceCellEditorParams.bind(this);
    this.priceStatusGetter = this.priceStatusGetter.bind(this);
    this.priceStatusFilterGetter = this.priceStatusFilterGetter.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onCellEditingStarted = this.onCellEditingStarted.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.updateGridAfterSave = this.updateGridAfterSave.bind(this);
    this.updateGridAfterBulkEdit = this.updateGridAfterBulkEdit.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.openDeleteOperationsModal = this.openDeleteOperationsModal.bind(this);
    this.closeDeleteOperationsModal = this.closeDeleteOperationsModal.bind(
      this
    );
    this.openImportOperationsModal = this.openImportOperationsModal.bind(this);
    this.closeImportOperationsModal = this.closeImportOperationsModal.bind(
      this
    );
    this.openExportOperationsModal = this.openExportOperationsModal.bind(this);
    this.closeExportOperationsModal = this.closeExportOperationsModal.bind(
      this
    );

    this.closeFactoryDeleteOperationsWarning = this.closeFactoryDeleteOperationsWarning.bind(
      this
    );
    // CustomEvent handlers
    this.handleSaveCellEdit = this.handleSaveCellEdit.bind(this);
    this.handleDeleteSingleOperation = this.handleDeleteSingleOperation.bind(
      this
    );
    this.openBulkEditSlider = this.openBulkEditSlider.bind(this);
    this.closeBulkEditSlider = this.closeBulkEditSlider.bind(this);
    this.saveGridState = this.saveGridState.bind(this);
    this.restoreGridState = this.restoreGridState.bind(this);
    this.closeOpcodeModal = this.closeOpcodeModal.bind(this);
    this.handleBulkEditServiceHours = this.handleBulkEditServiceHours.bind(
      this
    );
    this.onVariantTypeChange = this.onVariantTypeChange.bind(this);
    const { localeStrings } = context;
    const defaultSortModel = [
      {
        colId: "make",
        sort: "asc"
      },
      {
        colId: "internalName",
        sort: "asc"
      }
    ];
    // define component state properties here
    const gridOptions = {
      // Other props here
      operationsGrid: context.operationsGrid,
      viewMode: context.operationsGrid.viewMode,
      variantType: context.operationsGrid.variantType,
      searchKey: context.operationsGrid.searchKey,
      serviceKinds: ["maintenance", "repair", "autorecall", "declinedservice"],
      toggleBtn: false,
      dealerCode: "",
      statusMsg: null,
      statusType: "info",
      autoClose: false,
      sampleMsg:
        "You are working in Staging Mode.  Once these catalogs are fully configured, please contact your account manager to go Live.",
      editOption: null, // set values as edit, add, delete
      operationName: "",
      loadOperation: OperationState,
      sliderWidth: 300,
      showSlide: false,
      bulkEditSlide: false,
      bulkEditSliderWidth: 700,
      flexWidth: true,
      expandWidth: true,
      operations: context.operationlist,
      selectionlist: [],
      filterConfig: {},
      // filterMode: false,
      sortConfig: defaultSortModel,
      // update columnDef when operations data is ready
      columnDefs: [],
      // a default column definition with properties that get applied to every column
      defaultColDef: {
        sortable: true,
        resizable: true,
        // make every column editable
        editable: true,
        // set every column width
        width: 150,
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        // cellClass: "cell-wrap-text", // make all column wraptext
        // make every column use 'text' filter by default
        rowGroup: false,
        cellClassRules: getCellClassRule(
          validationFields,
          editableFields,
          checkboxFields
        ),
        filter: "agTextColumnFilter"
      },
      multiSortKey: "ctrl",
      sortingOrder: ["desc", "asc", null],
      // if we had column groups, we could provide default group items here
      // defaultColGroupDef: {},
      components: {
        simpleCellEditor: SimpleCellEditor
      },
      frameworkComponents: {
        customTooltip: CustomTooltip,
        numericEditor: NumericEditor,
        priceEditor: PriceEditor,
        opcodeEditor: OpcodeEditor,
        textEditor: TextEditor,
        customLoadingOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      cacheBlockSize: 100,
      maxBlocksInCache: 10,
      // define a column type (you can define as many as you like)
      columnTypes: {
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        nonEditableColumn: { editable: false }
      },
      enableBrowserTooltips: true, // true - use browser default tooltip instead of ag-grid tooltip
      // enableCellChangeFlash: true,
      rowSelection: "multiple", // allows multiple row selections
      // set rowData to null or undefined to show loading panel by default
      rowData: null,
      isRowSelectable(rowNode) {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      localeTextFunc(key, defaultValue) {
        console.log(`${key}: ${defaultValue}`);
        return defaultValue;
      },
      // sidebar detail config{}
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" }
        ]
      },
      /* confirm popup */
      // showConfirmPopup: false,
      showDeleteOperationsModal: false,
      showDeleteOperationsWarning: false,
      showExportOperationsModal: false,
      showImportOperationsModal: false,
      deleteConfirmationMessage: "",
      showOpCodeModal: false,
      setOpcodeValueFunc: null,
      findOpcodeServiceId: "",
      findOpcodeInternalName: "",
      findOpcodeDmsDescription: "",
      findOpcodeOpcode: "",
      serviceId: "",
      serviceHoursCount: 0,
      showStoreHoursModal: false,
      bulkEditServiceHours: false,
      dealerLaborRateCodes: context.dealerLaborRateCodes,
      makeRateCodesMap: context.makeRateCodesMap
    };

    // set background color on odd rows
    // again, this looks bad, should be using CSS classes
    gridOptions.getRowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
        return { background: "green" };
      }
    };
    // set true for Quick filter
    // gridOptions.cacheQuickFilter = false;
    this.state = gridOptions;
  }

  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
    window.addEventListener(
      "deleteSingleOperation",
      this.handleDeleteSingleOperation,
      false
    );
    window.addEventListener(
      "showFindOpcodeEvent",
      this.handleShowFindOpcodeEvent,
      false
    );
    window.addEventListener(
      "bulkEditServiceHours",
      this.handleBulkEditServiceHours,
      false
    );
  }
  /* An update can be caused by changes to props or state
           This called in order getDerivedStateFromProps(), shouldComponentUpdate(), render(), componentDidUpdate()
         */
  componentDidUpdate() {
    // write conditions if any
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
    window.removeEventListener(
      "deleteSingleOperation",
      this.handleDeleteSingleOperation,
      false
    );
    window.removeEventListener(
      "showFindOpcodeEvent",
      this.handleShowFindOpcodeEvent,
      false
    );
    window.removeEventListener(
      "bulkEditServiceHours",
      this.handleBulkEditServiceHours,
      false
    );
  }

  // bind to add operation click event
  addOperationSlider = event => {
    event.preventDefault();
    // toast.info(<span>Open Add slider</span>, {
    //   autoClose: 2000
    // });
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      loadOperation: OperationState,
      operationName: "Add Operation"
    }));
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.operations.add_operation_click");
  };
  /* handler for open bulkdedit slider */
  openBulkEditSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
  };

  /* Call this API to return content status, price status, laborrateDesc for given operation */
  loadContentAndPriceStatuses = serviceId => {
    // const { contentPriceStatuses } = this.context;
    // if (contentPriceStatuses && !serviceId) {
    //   this.setContentPriceStatusesInGrid(contentPriceStatuses);
    //   return;
    // }
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationsList",
        method: "get",
        data: {},
        params: { dealerCode, serviceId }
      },
      response => {
        // console.log(response);
        const servicelist = response.data;
        let datalist = [];
        // check if response has single object or array of objects
        if (!isArrayExist(servicelist) && typeof servicelist === "object") {
          datalist.push(servicelist);
        } else if (isArrayExist(servicelist) && servicelist.length > 0) {
          datalist = servicelist;
        }
        const serviceIdMap = [];
        datalist.forEach(obj => {
          serviceIdMap[obj.serviceId] = obj;
        });

        this.context.setContentPriceStatuses(serviceIdMap);
        this.setContentPriceStatusesInGrid(serviceIdMap);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  setContentPriceStatusesInGrid(serviceidMap) {
    this.state.operations.forEach(op => {
      const rowNode = this.gridApi.getRowNode(op.serviceId);
      const s = serviceidMap[op.serviceId];
      if (s) {
        updateOperationStatuses(op, s);
        rowNode.setData(op);
      }
    });
  }

  /* handler for bulkdedit slider close  */
  closeBulkEditSlider = event => {
    if (event) {
      event.preventDefault();
    }
    const { bulkEditSlide } = this.state;
    if (bulkEditSlide) {
      this.setState({ bulkEditSlide: false });
    }
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Operation Form is dirty
  */
  closeSlider = event => {
    // status returned from App-context; close slider when form is not dirty
    const status = this.context.discardUnsavedChanges(
      event,
      this.discardSlider
    );
    if (status) {
      this.setState(prevState => ({
        showSlide: !prevState.showSlide,
        loadOperation: OperationState,
        editOption: "cancel",
        operationName: ""
      }));
    }
  };
  // operation slider close event called from "Discard" action of speed bump
  discardSlider = event => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      loadOperation: OperationState,
      editOption: "cancel",
      operationName: ""
    }));
  };
  openDeleteOperationsModal = event => {
    // test
    // event.preventDefault();
    const fromSingleOp = (event && event.detail) || false;
    const { singleOperation, selectionlist } = this.state;
    const deletelist = fromSingleOp ? [singleOperation] : selectionlist;
    const factoryOps = deletelist.filter(op => !doesEmpty(op.parentId));
    if (factoryOps && factoryOps.length > 0) {
      // Some of the selected services are from the Factory and cannot be deleted. Please select only Dealer-created services while bulk deleting. You can filter by service Type to show only Dealer-created services.
      this.setState({ showDeleteOperationsWarning: true });
    } else {
      // check if dealer operations are linked to any menu
      const opsHasDealerMenu = deletelist.filter(op =>
        isTrue(op.hasDealerMenu)
      );
      let message = this.context.localeStrings[
        "xmm.portal.common.confirm_delete_operations"
      ];
      if (opsHasDealerMenu && opsHasDealerMenu.length > 0) {
        // test
        let opNames = "";
        opsHasDealerMenu.forEach(op => {
          if (opNames === "") {
            opNames = opNames.concat(op.name);
          } else {
            opNames = opNames.concat(", " + op.name);
          }
        });
        if (fromSingleOp) {
          message = `This operation is used in one or more Dealer Menus.
          If deleted, it will be removed from those menus as well. Are you sure you want to delete this operation?`;
        } else {
          message = `These ${
            opsHasDealerMenu.length
          } services(s) "${opNames}" are currently scheduled in Menus.
          Are you sure you want to delete?`;
        }
      }
      this.setState({
        showDeleteOperationsModal: true,
        deleteConfirmationMessage: message
      });
    }
  };

  closeDeleteOperationsModal = event => {
    this.setState({ showDeleteOperationsModal: false, singleOperation: null });
  };

  closeFactoryDeleteOperationsWarning = () => {
    this.setState({ showDeleteOperationsWarning: false });
  };

  /* call rest api to get all operations
   @param {checked} callback boolean passed from "Setting> Save" event
  */
  loadOperations(checked) {
    const { dealerCode, localeStrings } = this.context;
    showBodyMask();
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getAllDealerOperations";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: { dealerCode }
      },
      datalist => {
        const {
          makeRateCodesMap,
          localeStrings,
          dealerLaborRateCodes
        } = this.context;
        const nextState = {
          status: "",
          makeRateCodesMap,
          dealerLaborRateCodes,
          operations: datalist
        };
        let { columnDefs } = this.state;
        if (columnDefs.length === 0) {
          columnDefs = this.getColumnList(
            localeStrings,
            makeRateCodesMap,
            dealerLaborRateCodes
          );
          nextState.columnDefs = columnDefs;
        }
        this.setState(nextState, (prevState, props) => {
          this.gridApi.setRowData(datalist);
          this.enableActions(false);
          this.showAllColumns("basic");
          this.gridApi.setSortModel(this.state.sortConfig);
          this.gridApi.closeToolPanel();
          this.gridApi.hideOverlay();
          // update AppContext {operationlist}
          this.context.updateOperationList(datalist);
          this.filterByVariantChanges();
          // this.updateStatusBox("Loading", "pending", false);
          this.loadContentAndPriceStatuses();
        });
        if (this.context.engageEnabled) {
          this.loadInspectionItems();
        } else {
          hideBodyMask();
        }
      },
      error => {
        const errorKey = "xmm.portal.operations.load_data_error";
        const msg = error["message"] ? error.message : localeStrings[errorKey];
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  loadInspectionItems() {
    const { dealerCode, locale, localeStrings } = this.context;
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getDealerInspectionItems";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: { dealerCode, locale }
      },
      datalist => {
        const inspectionItemMap = {};
        datalist.forEach(inspectionItem => {
          const { serviceId } = inspectionItem;
          inspectionItemMap[serviceId.toString()] = inspectionItem;
        });
        const { operations } = this.state;
        operations.forEach(op => {
          const { serviceId } = op;
          const inspectionItem = inspectionItemMap[serviceId.toString()];
          if (inspectionItem) {
            // const { category, itemTypeName, matchType, serviceTag } = inspectionItem;
            const { matchType, serviceTag } = inspectionItem;
            op.inspectionItem = getInspectionItemDisplay(inspectionItem); // category + " - " + itemTypeName;
            if (matchType === "servicetag") {
              op.serviceTag = serviceTag;
            } else {
              op.serviceTag = InspectionItemCategory;
            }
          } else {
            op.inspectionItem = "";
            op.serviceTag = "";
          }
        });
        this.setState({ operations });
        this.context.updateOperationList(operations);
        this.context.updateInspectionItemMap(inspectionItemMap);
        hideBodyMask();
      },
      error => {
        const errorKey = "xmm.portal.operations.load_data_error";
        const msg = error["message"] ? error.message : localeStrings[errorKey];
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  /* update ColumnDef from AppContext when page re-visited */
  generateColumnList() {
    const {
      makeRateCodesMap,
      localeStrings,
      dealerLaborRateCodes
    } = this.context;

    const columnDefs = this.getColumnList(
      localeStrings,
      makeRateCodesMap,
      dealerLaborRateCodes
    );
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    sleep(0).then(() => {
      this.setState(
        {
          columnDefs,
          makeRateCodesMap,
          dealerLaborRateCodes
        },
        () => {
          this.showAllColumns("basic");
          this.restoreGridState(); // This call will retain filters,sorters from App context
        }
      );
    });
  }

  // call this method if we want to autosize all columns
  autoSizeAll() {
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  // This method will fit columns with specified widths
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  /**
   * This event listener internally calls componentDidMount()
   */
  onGridReady = params => {
    // console.log("grid ready", this, params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.enableActions(true);
    // Fetch operations from AppContext if available; call backend only after the render is committed to the screen.
    if (this.context.operationlist.length === 0) {
      this.loadOperations(true);
    } else {
      // Restore grid state when operation page is re-visited.
      this.generateColumnList();
      this.gridApi.setRowData(this.context.operationlist);
      this.enableActions(false);
      this.gridApi.setSortModel(this.state.sortConfig);
      this.gridApi.closeToolPanel();
      this.filterByVariantChanges();
    }
  };
  filterByVariantChanges = () => {
    let variantType = "ALL";
    // Read variant type if passed from Dashbaord
    const { variantFilters } = this.context;
    if (variantFilters && Object.keys(variantFilters).length > 0) {
      variantType = variantFilters.variantType;
    }
    if (variantFilters && Object.keys(variantFilters).length > 0) {
      this.setMakeModel(variantFilters.make);
      this.applyVariantFilter(null, variantFilters.variantType);
      // reset variantFilter in App context
      this.context.updateVariantFilters(null);
    }
    this.setState({ variantType });
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.serviceId;
  }

  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };

  /* Demo - TODO ajax http methods
  hideSidebar() {
      if (this.gridApi) {
        this.gridApi.setSideBarVisible(!this.gridApi.isSideBarVisible());
      }
  }
  */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }

  /* callback when Save() triggerd to refresh parent grid */
  refreshOperations = checked => {
    this.updateStatusBox("", "text", false);
    this.setState(
      {
        status: ""
      },
      prevState => {
        this.loadOperations(checked);
      }
    );
  };
  handleGridSizeChanged = event => {
    const gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    // console.log("handleGridSizeChanged", event);
    // console.log("gridWidth", gridWidth);
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = event.columnApi.getAllColumns();
    for (let i = 0; i < allColumns.length; i++) {
      const column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
  };

  handleRowSelected = event => {
    // console.log(`node ${event.node.data.name} is selected=${event.node.selected}`, event.node);
  };

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  convertToCSV(objArray) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line !== "") line += ",";
        let someText = "" + array[i][index];
        someText = someText.replace(/(\r\n|\n|\r)/gm, " ");
        // someText = someText.replace(",", ";");
        someText = someText.replace("null", " ");
        if (isNaN(someText)) {
          someText = JSON.stringify(someText);
        }
        line += someText;
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportCSVFile(csv, fileTitle) {
    const exportedFilename = fileTitle + ".csv" || "export.csv";

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  openExportOperationsModal = event => {
    this.setState({
      showExportOperationsModal: true
    });
  };

  closeExportOperationsModal = event => {
    this.setState({ showExportOperationsModal: false });
  };

  openImportOperationsModal = event => {
    this.setState({
      showImportOperationsModal: true
    });
  };

  closeImportOperationsModal = event => {
    this.setState({ showImportOperationsModal: false });
    if (this.refs.importDealerOpsRef) {
      this.refs.importDealerOpsRef.initializeStates();
    }
  };

  handleCsvExport = make => {
    this.setState({ showExportOperationsModal: false });
    this.gridApi.showLoadingOverlay();
    // this.gridApi.exportDataAsExcel({});
    const { dealerCode, makeVariantMap } = this.context;
    const dealerCatalog = makeVariantMap[make];
    const variant = dealerCatalog ? dealerCatalog.variant : null;
    // alert('selected ' + make);
    const headers = {
      Accept: "text/csv",
      "Content-Type": "text/csv"
    };

    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxycsv/rest/proc/exportServices",
        method: "get",
        data: {},
        params: { dealerCode, make, variant },
        headers,
        dataType: "csv"
      },
      response => {
        // console.log(response);
        this.gridApi.hideOverlay();
        const today = new Date();
        const date =
          today.getMonth() +
          1 +
          "-" +
          today.getDate() +
          "-" +
          today.getFullYear();
        this.exportCSVFile(
          response,
          "Operations_Export_" + make + "_" + dealerCode + "_" + date
        );
      },
      error => {
        toast.error(error.message);
        this.gridApi.hideOverlay();
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.operations.proceed_export_click");
  };

  handleCsvImport = (make, file) => {
    this.setState({ showImportOperationsModal: false });
    this.gridApi.showLoadingOverlay();
    const { dealerCode, makeVariantMap } = this.context;
    const { variant } = makeVariantMap[make];
    const formData = new FormData();
    formData.append("file", file);
    // TO DO
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    makeSecureRestApi(
      {
        url: "/ops/upload/uploadServicesFile",
        method: "post",
        data: formData,
        params: { dealerCode, make, variant },
        headers
      },
      response => {
        // console.log(response);
        this.gridApi.hideOverlay();
        if (response.success === false) {
          toast.error(response.data.message, {
            autoClose: 6000
          });
        } else {
          toast.success(response.data.message, {
            autoClose: 4000
          });
          this.refreshOperations();
        }
      },
      error => {
        toast.error(error.message);
        this.gridApi.hideOverlay();
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.operations.proceed_import_click");
  };
  /* Testing : how to update data on the fly */
  openBulkEditSliderTest = event => {
    this.state.operations.forEach(op => {
      const rowNode = this.gridApi.getRowNode(op.serviceId);
      op.priceStatus = Math.random()
        .toString(36)
        .substring(7);
      rowNode.setData(op);
    });
  };

  handleShowFindOpcodeEvent = event => {
    const { data, colDef, parentHandle } = event.detail;
    const { dmsOpcode } = data;
    this.openOpcodeModal(
      data.serviceId.toString(),
      data.internalName,
      data.dmsOpcode,
      data.currentOpcodeDescription,
      value => {
        //
        data.dmsOpcode = value;
        const params = {
          newValue: value,
          oldValue: dmsOpcode,
          value,
          data,
          colDef
        };
        if (parentHandle) {
          parentHandle.onCellValueChanged(params);
        } else {
          this.onCellValueChanged(params);
        }
      }
    );
  };

  handleDeleteSingleOperation = event => {
    const { singleOperation } = event.detail;
    // const deleteConfirmationMessage =
    //   "You are about to delete the selected operations. Are you sure you want to proceed?";
    this.setState(
      {
        singleOperation
      },
      () => {
        this.openDeleteOperationsModal(event);
      }
    );
  };

  // delete operations
  handleDeleteOperations = event => {
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.deleting"],
      "pending",
      false
    );
    const { singleOperation, selectionlist } = this.state;
    const deletionList = !singleOperation
      ? selectionlist.filter(op =>
          isDifferentValue(op.serviceId, this.context.tellUsMoreServiceId)
        )
      : [singleOperation];
    if (singleOperation) {
      this.setState({ singleOperation: null });
    }
    const opIds = deletionList.map(op => op.serviceId.toString());
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/deleteOperations";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: { authId: null, serviceIds: opIds, deleteChildren: 0 },
        params: {},
        headers
      },
      data => {
        // const selectedData = this.gridApi.getSelectedRows();
        const res = this.gridApi.updateRowData({
          remove: deletionList
        });
        printResult(res);
        // toast.success("Selected operations are deleted.");
        // this.setState({ status: "Selected operations are deleted." });
        if (this.state.showSlide) {
          this.closeSlider();
        }
        this.gridApi.deselectAll();
        this.context.updateOperationAfterSave("delete", deletionList);
        this.updateStatusBox(
          this.context.localeStrings["xmm.portal.common.deleted"],
          "success",
          true
        );
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while deleting records.";
        // toast.error(msg);
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    this.closeDeleteOperationsModal();
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.operations.proceed_delete_operations_click");
  };
  showError(operation, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(operation.serviceId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      // this.gridApi.flashCells(params);
      this.gridApi.refreshCells(params);
    }
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.showError(operation, field);
    // toast.error(operation.errors[field]);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "dealerLaborRateCodeId":
          params.value = doesEmpty(newValue)
            ? ""
            : parseInt(params.newValue, 10);
          break;
        case "price":
          if (newValue && newValue > 9999.99) {
            errorKey = "xmm.portal.validation.price_exceeds_max";
          }
          break;
        case "shopDuration":
          if (newValue && newValue > 1440) {
            errorKey = "xmm.portal.validation.duration_max_value_exceeded";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }
  /* "cellClicked" event handler  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "internalName") {
      const record = params.data;
      console.log("Callback of cellclickevent for", record);
      // toast.info("Edit Operation from Slider");
      // call slider to pass record
      this.setState(prevState => ({
        showSlide: !prevState.showSlide,
        editOption: "edit",
        serviceId: record.serviceId,
        operationName: record.name,
        loadOperation: record
      }));
    }
  }
  /* This method returns request payload for single celleditor changes to save
    eg: {"make":"AUDI","variant":"AUDIUSA_ENH2","dealerCode":"XTIMEMOTORS14","serviceId":8960698,"rank":"2"}
  */
  getCellEditPayload(record, field, value) {
    const { dealerCode } = this.context;
    const payload = {
      make: record.make,
      variant: record.variant,
      dealerCode,
      serviceId: record.serviceId,
      modUser: this.context.user.userName
    };

    // console.log("getCellEditPayload", field, record);
    if (field) {
      if (field === "appsInDisplay") {
        payload.enabled = record.enabled;
        payload.reservableByCustomers = record.reservableByCustomers;
      } else {
        payload[field] = value;
      }
    }
    // console.log(payload);
    return payload;
  }
  // TODO- delete this later
  saveLaborRate(record, field, value) {
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/setLaborRateCodeId";
    const payload = {
      serviceId: record.serviceId,
      laborRateCodeId: value
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (!data) {
          // ag-grid API to update rowData for edited cell.
          if (this.gridApi) {
            if (!value) {
              console.log("reverting to variant labor rate");
              this.loadContentAndPriceStatuses(payload.serviceId);
            }
          }
          // this.setContentAndPriceStatuses(dealerOperation);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";

        toast.error(msg);
      }
    );
  }
  /* This method to save celleditor changes only, since payload will vary
     restAPI - https://opsmanager.devxmm.nonprod.west2.xtimeappsnp.com/ops/proxyapi/ddsproxy/rest/table/dealerOperation/9010780?_method=put
  */
  savePayload(record, field, value) {
    const { localeStrings } = this.context;
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/table/dealerOperation/" +
      record.serviceId +
      "?_method=put";
    const payload = this.getCellEditPayload(record, field, value);
    const { makeVariantMap } = this.context;
    const { baseLocale } = makeVariantMap[record.make];
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Variant-Base-Locale": baseLocale
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data && data.success) {
          const dealerOperation = data.dealerOperation;
          // ag-grid API to update rowData for edited cell.
          if (this.gridApi) {
            const rowNode = this.gridApi.getRowNode(dealerOperation.serviceId);
            // copy values from response and preserve existing values that are not in the response like priceStatus, contentEnabledAlacarte, and contentEnabledMenus
            // dealerOperation = Object.assign(rowNode.data, dealerOperation);
            rowNode.data[field] = dealerOperation[field];
            // Replace the data on the rowNode (all columns in that row). When complete, the grid will refresh the rendered row.
            this.updateStatusBox(
              localeStrings["xmm.portal.common.saved"],
              "success",
              true
            );
            rowNode.setData(rowNode.data);
            // When LaborRate is empty, revert to variant level labor rate
            if (
              field === "dealerLaborRateCodeId" ||
              field === "mandatoryInAppt" ||
              field === "price"
            ) {
              this.loadContentAndPriceStatuses(dealerOperation.serviceId);
            }
          }
        }
      },
      error => {
        const errorKey = "xmm.portal.errors.save_data_error";
        const msg = error["message"] ? error.message : localeStrings[errorKey];
        toast.error(msg);
      }
    );
  }
  saveAnyMakeOperation(record, field, value) {
    if (field === "metaVehicleScope") {
      this.editMetaVehiclesScopeForAnyMake(record, field, value);
      return;
    }
    const copyTemplate = Object.assign({}, EditAnyMakePayload);
    // copyTemplate[field] = value;
    const postObj = convertBooleanToBinary(
      deepCopyFromTemplate(record, copyTemplate, true)
    );
    const { engageEnabled } = this.context;
    if (engageEnabled) {
      postObj.tags = [];
      if (record.serviceTag && record.serviceTag !== InspectionItemCategory) {
        postObj.tags.push(record.serviceTag);
      }
    }

    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/editService";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: postObj,
        headers
      },
      data => {
        const { response } = data;
        if (response && response.statusCode === 0) {
          const { service } = response;
          if (service) {
            const fields = Object.keys(record);
            if (fields.includes(field)) {
              record[field] = service[field];
            }
            const rowNode = this.gridApi.getRowNode(record.serviceId);
            rowNode.setData(record);
            if (field === "mandatoryInAppt" || field === "price") {
              this.loadContentAndPriceStatuses(record.serviceId);
            }
            this.updateStatusBox(
              this.context.localeStrings["xmm.portal.common.saved"],
              "success",
              true
            );
          }
        } else {
          const status = "There was an error saving your changes.";
          toast.error(status);
        }
      },
      error => {
        const status = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(status);
      }
    );
  }

  editMetaVehiclesScopeForAnyMake(record, field, value) {
    const { serviceId, metaVehicleScope, metaVehicleFilterId } = record;
    const data = {
      requestType: "",
      authId: "",
      serviceId,
      metaVehicleScope: value,
      metaVehicleFilterId
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/editMetaVehiclesScope",
        method: "post",
        data
      },
      result => {
        if (result.response && result.response.statusCode === 0) {
          console.log("old metaVehicleScope", metaVehicleScope);
          record[field] = value;
          const rowNode = this.gridApi.getRowNode(record.serviceId);
          rowNode.setData(record);
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );
        } else {
          const status = "There was an error saving a la carte vehicles.";
          toast.error(status);
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  saveCellEdit(operation, field, value) {
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    if (operation.make === "ANY") {
      this.saveAnyMakeOperation(operation, field, value);
    } else {
      this.savePayload(operation, field, value);
    }
  }
  onSaveCellEdit(params) {
    const { colDef, data, value } = params;
    const { field } = colDef;
    const operation = data;
    this.saveCellEdit(operation, field, value);
  }
  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    const operation = data;
    this.saveCellEdit(operation, field, value);
  };

  // once row data is saved, sorting & filter values will not refresh. hence we need to call explicitly.
  // refer - https://www.ag-grid.com/javascript-grid-data-update
  updateSort() {
    if (this.gridApi) {
      this.gridApi.refreshClientSideRowModel("sort");
    }
  }
  updateFilter() {
    if (this.gridApi) {
      this.gridApi.refreshClientSideRowModel("filter");
    }
  }

  // When single operation saved from slider, callback to update record of selected row in grid
  updateGridAfterSave = (editMode, responseData) => {
    console.log(
      "[updateGridAfterSave] response from slider save",
      editMode,
      responseData
    );
    if (editMode) {
      // ag-grid API to update rowData for recently save operation from selection.
      if (this.gridApi && !isEmpty(responseData)) {
        this.setState(prevState => ({
          loadOperation: responseData,
          serviceId: responseData.serviceId,
          operationName: responseData.name,
          editOption: "edit"
        }));
        if (editMode === "edit") {
          const rowNode = this.gridApi.getRowNode(responseData.serviceId);
          rowNode.setData(responseData);
          if (rowNode) {
            const params = {
              rowNodes: [rowNode],
              force: true
            };
            // this.gridApi.flashCells(params);
            this.gridApi.refreshCells(params);
          }
        } else if (editMode === "add") {
          const res = this.gridApi.updateRowData({
            add: [responseData]
          });
          const rowNode = this.gridApi.getRowNode(responseData.serviceId);
          rowNode.setSelected(false);
          // console.log(this.gridApi, res.add);
          // this.gridApi.selectIndex(res.add[0].rowIndex);
          this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
          printResult(res);
        }
        // callback AppContext to update State {operationlist}
        this.context.updateOperationAfterSave(editMode, responseData);
      }
    }
  };
  // when bulkedit saved saved from slider, callback to update records of selected rows in operation grid
  updateGridAfterBulkEdit = (results, columns, statusUpdatable) => {
    if (this.gridApi && !isEmpty(results)) {
      this.gridApi.updateRowData({ update: results });
      // printResult(res);
      // callback AppContext to update State {operationlist}
      this.context.updateOperationAfterSave("bulk", results);
      if (statusUpdatable) {
        for (let indx = 0; indx < results.length; indx++) {
          this.loadContentAndPriceStatuses(results[indx].serviceId);
        }
      }
      const rowNodes = results.map(data =>
        this.gridApi.getRowNode(data.serviceId)
      );
      this.gridApi.flashCells({ rowNodes, columns });
    }
  };

  /* TODO - used in future, Editing a cell has started */
  onCellEditingStarted = params => {
    const { rowIndex, column } = params.api.getFocusedCell();
    const focusedCellEditorParams = {
      rowNodes: [params.api.getRowNode(rowIndex)],
      columns: [column]
    };
    const focusedCellEditor = params.api.getCellEditorInstances(
      focusedCellEditorParams
    )[0];
    focusedCellEditor.focusIn();
  };
  // Testing
  resetColFilter() {
    if (this.gridApi) {
      const ageFilterComponent = this.gridApi.getFilterInstance("shopDuration");
      ageFilterComponent.setModel(null);
    }
  }
  // Demo purpose- we can explicitly set filters from local state
  restoreFilterModel(filterConfig) {
    if (this.gridApi) {
      this.gridApi.setFilterModel(filterConfig);
      this.gridApi.onFilterChanged();
    }
  }
  // Demo purpose - we can save filters explicitly
  saveFilterModel() {
    let savedFilters = "[]";
    window.savedModel = this.gridApi.getFilterModel();
    if (window.savedModel) {
      savedFilters = Object.keys(window.savedModel);
    } else {
      savedFilters = "-none-";
    }
    console.log(JSON.stringify(savedFilters));
  }

  clearFilters() {
    if (this.gridApi) {
      // const filterModel = this.gridApi.getFilterModel();
      // const sortModel = this.gridApi.getSortModel();
      // console.log("before clear> sorter, filter", sortModel, filterModel);
      this.gridApi.setFilterModel(null);
      // this.gridApi.onFilterChanged();
    }
    document.querySelector("#operation-search-box").value = "";
    this.onSearchBoxChanged();
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey, variantType, viewMode } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const operationsGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        sortState: this.gridApi.getSortModel(),
        filterState: this.gridApi.getFilterModel(),
        searchKey,
        variantType,
        viewMode
      };
      this.setState({
        operationsGrid
      });

      // console.log("save state", this.state);
      this.context.setOperationGridState(operationsGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const {
      colState,
      filterState,
      sortState,
      pivotState,
      searchKey,
      variantType,
      viewMode
    } = this.state.operationsGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      this.gridApi.setSortModel(sortState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey,
          variantType,
          viewMode
        },
        prevState => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }

  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#operation-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };

  /* This handler to apply variant type filter on grid rows */
  onVariantTypeChange = (cxEvent, isValid, domEvent) => {
    const { value, name } = cxEvent.target;
    this.setState(
      {
        [name]: value
      },
      prevState => {
        this.applyVariantFilter(cxEvent, value);
      }
    );
  };

  applyVariantFilter = (event, filterVal) => {
    if (this.gridApi) {
      const statusAddedFilter = this.gridApi.getFilterInstance("variantAdded");
      const statusChangedFilter = this.gridApi.getFilterInstance(
        "variantChanged"
      );

      if (filterVal && filterVal === "ADDED") {
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
      }

      if (filterVal && filterVal === "CHANGED") {
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
      }
      if (filterVal === "ALL") {
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
      }
      // gridApi to run filter on operations
      this.gridApi.onFilterChanged();
      this.clearGridSelections();
    }
  };
  // This Make Filter used when Parts page launched from Dashboard
  setMakeModel(filterVal) {
    if (this.gridApi) {
      const makeFilter = this.gridApi.getFilterInstance("make");
      if (filterVal && filterVal === "all") {
        makeFilter.setModel(null);
      } else {
        const data = [];
        data.push(filterVal);
        const model = {
          type: "set",
          values: data
        };
        makeFilter.setModel(model);
      }
    }
  }
  /* Handler to show/hide base vs All columns in grid  */
  onColumnViewChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    if (this.gridApi) {
      this.setState(
        {
          viewMode: value
        },
        (prevState, props) => {
          this.showAllColumns(value);
          this.clearGridSelections();
          // this.updateStatusBox(`Changing to ${value}`, "pending", true);
        }
      );
    }
  };
  showAllColumns(value) {
    if (this.gridColumnApi) {
      // set all to false first
      this.gridColumnApi.setColumnsVisible(
        [
          "checked",
          "make",
          "internalName",
          "parentId",
          "serviceKind",
          "metaVehicleScope",
          "appsInDisplay",
          "dmsOpcode",
          "shopDuration",
          "rank",
          "serviceCategoryName",
          "name",
          "description",
          "waiterAllowed",
          "loanerAllowed",
          "mandatoryInAppt",
          "commentsRequired",
          "unscheduledDefault",
          "contentEnabledMenus",
          "price",
          "priceCaption",
          "priceStatus",
          "serviceHoursCount",
          "inspectionItem",
          "laborTimeRange",
          "variantAdded",
          "variantChanged",
          "dealerLaborRateCodeId"
        ],
        false
      );
      const showBasic = value && value === "basic" ? true : false;
      const showPricing = value && value === "pricing" ? true : false;
      const showAll = value && value === "all" ? true : false;
      if (showBasic || showAll) {
        this.gridColumnApi.setColumnsVisible(
          [
            "checked",
            "make",
            "internalName",
            "parentId",
            "serviceKind",
            "metaVehicleScope",
            "appsInDisplay",
            "dmsOpcode",
            "shopDuration",
            "rank"
          ],
          true
        );
      }
      if (showPricing) {
        this.gridColumnApi.setColumnsVisible(
          [
            "checked",
            "make",
            "internalName",
            "contentEnabledMenus",
            "price",
            "priceCaption",
            "priceStatus",
            "laborTimeRange",
            "dealerLaborRateCodeId"
          ],
          true
        );
      }
      if (showAll) {
        this.gridColumnApi.setColumnsVisible(
          [
            "serviceCategoryName",
            "name",
            "description",
            "waiterAllowed",
            "loanerAllowed",
            "mandatoryInAppt",
            "commentsRequired",
            "unscheduledDefault",
            "contentEnabledMenus",
            "price",
            "priceCaption",
            "priceStatus",
            "serviceHoursCount",
            "inspectionItem",
            "laborTimeRange",
            "dealerLaborRateCodeId"
          ],
          true
        );
      }
      this.autoSizeAll();
    }
  }
  // Method to enable action buttons when data is ready
  enableActions(hide) {
    document.querySelector("#operation-search-box").disabled = hide;
    document.querySelector("#ColumnViewSelect").disabled = hide;
  }
  /* TODO - we can pin specific cols if wanted */
  pinBaseCols(pin) {
    this.gridColumnApi.setColumnPinned(["name", "make"], pin);
  }

  openOpcodeModal = (
    findOpcodeServiceId,
    findOpcodeInternalName,
    findOpcodeOpcode,
    findOpcodeDmsDescription,
    setOpcodeValueFunc
  ) => {
    this.setState({
      showOpCodeModal: true,
      findOpcodeServiceId,
      findOpcodeInternalName,
      findOpcodeOpcode,
      findOpcodeDmsDescription,
      setOpcodeValueFunc
    });
  };

  closeOpcodeModal = () => {
    this.setState({ showOpCodeModal: false });
  };

  handleBulkEditServiceHours = event => {
    const { bulkEditServiceHoursCallback } = event.detail;
    this.setState({
      showStoreHoursModal: true,
      bulkEditServiceHours: true,
      serviceId: "",
      serviceHoursCount: 0,
      bulkEditServiceHoursCallback
    });
  };

  closeStoreHoursDialog = () => {
    this.setState({ showStoreHoursModal: false });
  };

  priceCellClass(params) {
    const { data } = params;
    if (data) {
      const { makeVariantMap } = this.context;
      const { make } = data;
      const { pricingMethod } = getCatalogFromMakeVariantMap(
        makeVariantMap,
        make
      );
      if (pricingMethod === 0) {
        return "editable-disabled-cell";
      }
    }
    return "editable-cell";
  }
  priceCellRender(params) {
    const { makeVariantMap } = this.context;
    const { make, serviceId } = params.data;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    const keyId = serviceId.toString() + "-warningTip";
    if (pricingMethod !== 0) {
      return <span>{priceFormatter(params)}</span>;
    }
    const warningMessage = "Field disabled due to Pricing Workflow";
    const warningTip = (
      <Tooltip htmlId={keyId} tooltipContent={warningMessage}>
        <div style={{ width: "100%" }}>&nbsp;</div>
      </Tooltip>
    );
    return warningTip;
  }
  priceCellEditorParams(params) {
    const { makeVariantMap } = this.context;
    const { make } = params.data;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    // labor rate only editable if it is not ANY catalog and calculated pricing
    if (
      pricingMethod !== 0 &&
      isDifferentValue(params.data.serviceId, tellUsMoreServiceId)
    ) {
      return { keepInvalidValue: true };
    }
    params.api.stopEditing();
    return null;
  }
  priceStatusGetter(params) {
    const { makeVariantMap } = this.context;
    const { make, priceStatus } = params.data;

    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    if (pricingMethod !== 0) {
      return PriceStatusMap[priceStatus]
        ? PriceStatusMap[priceStatus]
        : priceStatus;
    }
    return PriceStatusMap["No Pricing"];
  }

  priceStatusFilterGetter(params) {
    const { makeVariantMap } = this.context;
    const { make, priceStatus } = params.data;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    if (pricingMethod !== 0) {
      return PriceStatusMap[priceStatus]
        ? PriceStatusMap[priceStatus]
        : priceStatus;
    }
    return PriceStatusMap["No Pricing"];
  }

  laborTimingRangeCellClass(params) {
    const { data } = params;
    if (data) {
      const { makeVariantMap } = this.context;
      const { make } = data;
      const { pricingMethod } = getCatalogFromMakeVariantMap(
        makeVariantMap,
        make
      );
      if (make === "ANY" || pricingMethod !== 1) {
        return "editable-disabled-cell";
      }
    }
    return "";
  }
  laborRateCellClass(params) {
    const { data } = params;
    if (data) {
      const { makeVariantMap } = this.context;
      const { make } = data;
      const { pricingMethod } = getCatalogFromMakeVariantMap(
        makeVariantMap,
        make
      );
      if (make === "ANY" || pricingMethod !== 1) {
        return "editable-disabled-cell";
      }
    }
    return "editable-caret-cell";
  }
  laborTimingRangeCellRenderer(params) {
    const { makeVariantMap } = this.context;
    const { make, serviceId } = params.data;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    const keyId = serviceId.toString() + "-warningTip";
    if (make !== "ANY" && pricingMethod === 1) {
      return <span>{params.value}</span>;
    }
    const warningMessage = "Field disabled due to Pricing Workflow";
    const warningTip = (
      <Tooltip htmlId={keyId} tooltipContent={warningMessage}>
        <div style={{ width: "100%" }}>&nbsp;</div>
      </Tooltip>
    );
    return warningTip;
  }
  laborRateCellRenderer(params) {
    const { makeVariantMap } = this.context;
    const { make, serviceId } = params.data;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    const keyId = serviceId.toString() + "-warningTip";
    if (make !== "ANY" && pricingMethod === 1) {
      const { colDef } = params;
      const { refData } = colDef;
      if (refData) {
        return <span>{refData[params.value]}</span>;
      }
    }
    const warningMessage = "Field disabled due to Pricing Workflow";
    const warningTip = (
      <Tooltip htmlId={keyId} tooltipContent={warningMessage}>
        <div style={{ width: "100%" }}>&nbsp;</div>
      </Tooltip>
    );
    return warningTip;
  }
  // laborRateFormatter(params) {
  //   const { makeVariantMap } = this.context;
  //   const { make } = params.data;
  //   const { pricingMethod } = getCatalogFromMakeVariantMap(makeVariantMap, make);
  //   if (make !== "ANY" && pricingMethod === 1) {
  //     const { colDef } = params;
  //     const { refData } = colDef;
  //     return refData[params.value];
  //   }
  //   return "";
  // }
  laborRateCellEditorParams(params) {
    const { makeRateCodesMap, makeVariantMap } = this.context;
    const { make } = params.data;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    // labor rate only editable if it is not ANY catalog and calculated pricing
    if (make !== "ANY" && pricingMethod === 1) {
      return {
        component: "agRichSelectCellEditor",
        params: {
          values: extractLaborRateValues(makeRateCodesMap, make)
        }
      };
    }
    params.api.stopEditing();
    return null;
  }
  renderStoreHoursModal = show => {
    const {
      serviceId,
      serviceHoursCount,
      showStoreHoursModal,
      bulkEditServiceHours,
      bulkEditServiceHoursCallback
    } = this.state;
    return show ? (
      <StoreHoursDialog
        ref="storeHoursDlgRef"
        serviceId={serviceId}
        serviceHoursCount={serviceHoursCount}
        title="Service Availability"
        titleId={"xmm.portal.operations.form.service.availability"}
        dialogHtmlId={"storeHoursDialog"}
        closeButtonLabelId={"xmm.portal.common.close_button"}
        show={showStoreHoursModal}
        bulkEdit={bulkEditServiceHours}
        bulkEditServiceHoursCallback={bulkEditServiceHoursCallback}
        localeStrings={this.context.localeStrings}
        doClose={event => {
          if (
            this.context.discardUnsavedChanges(
              event,
              this.closeStoreHoursDialog,
              this.refs.storeHoursDlgRef.isDirty
            )
          ) {
            this.closeStoreHoursDialog();
          }
        }}
      />
    ) : null;
  };
  getColumnList(localeStrings, makeRateCodesMap, dealerLaborRateCodes) {
    console.log("has tellusmore", this.context.tellUsMoreServiceId);
    return getBaseCols(localeStrings).concat(
      this.getCustomCols(localeStrings, makeRateCodesMap, dealerLaborRateCodes)
    );
  }
  getCustomCols(localeStrings, makeRateCodesMap, dealerLaborRateCodes) {
    // console.log("Custom cols > laborRatesmap", makeRateCodesMap);
    const { tellUsMoreServiceId } = this.context;
    const customCols = [
      {
        headerName:
          localeStrings["xmm.portal.operations.grid.service_category"],
        field: "serviceCategoryName",
        enableRowGroup: false,
        editable: false,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        width: 180,
        minWidth: 180
      },
      {
        headerName: localeStrings["xmm.portal.operations.grid.external_name"],
        field: "name",
        editable: false,
        enableRowGroup: false,
        minWidth: 200,
        filterParams: { clearButton: true }
      },
      {
        headerName: localeStrings["xmm.portal.grid.description"],
        field: "description",
        tooltipField: "description",
        tooltipComponentParams: { field: "description" },
        tooltipComponent: "customTooltip",
        editable: false,
        enableRowGroup: false,
        minWidth: 150,
        filterParams: { clearButton: true }
      },
      {
        headerName: localeStrings["xmm.portal.operations.grid.allow_waiters"],
        field: "waiterAllowed",
        valueFormatter: boolToStringFormatter,
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "waiterAllowed"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        editable: false,
        enableRowGroup: false,
        minWidth: 100
      },
      {
        headerName: localeStrings["xmm.portal.operations.grid.allow_loaners"],
        field: "loanerAllowed",
        valueFormatter: boolToStringFormatter,
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "loanerAllowed"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        editable: false,
        enableRowGroup: false,
        minWidth: 110
      },
      {
        headerName: localeStrings["xmm.portal.grid.mandatory_apt"],
        field: "mandatoryInAppt",
        valueFormatter: boolToStringFormatter,
        enableRowGroup: false,
        // cellEditor: "simpleCellEditor",
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "mandatoryInAppt",
          tellUsMoreServiceId
        },
        editable: false,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        minWidth: 120
      },
      {
        headerName: localeStrings["xmm.portal.operations.grid.comments_req"],
        field: "commentsRequired",
        valueFormatter: boolToStringFormatter,
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "commentsRequired"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        editable: false,
        enableRowGroup: false,
        minWidth: 150
      },
      {
        headerName:
          localeStrings["xmm.portal.operations.grid.alacarte_unscheduled"],
        field: "unscheduledDefault",
        valueFormatter: boolToStringFormatter,
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "unscheduledDefault"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        editable: false,
        enableRowGroup: false,
        width: 170,
        minWidth: 170
      },
      {
        headerName: localeStrings["xmm.portal.grid.content_status"],
        field: "contentEnabledMenus",
        editable: false,
        valueGetter: contentStatusValueGetter,
        enableRowGroup: false,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          selectAllOnMiniFilter: true, // since version 22.x, the Set Filter param selectAllOnMiniFilter is no longer used
          newRowsAction: "keep",
          clearButton: true
        },
        width: 200,
        minWidth: 200
      },
      {
        headerName: localeStrings["xmm.portal.grid.price"],
        field: "price",
        colId: "price",
        headerClass: "ag-numeric-header",
        width: 100,
        maxWidth: 150,
        minWidth: 100,
        type: "numberColumn",
        // cellClassRules: getCellClassRule(validationFields),
        cellClass: this.priceCellClass,
        cellEditor: "priceEditor",
        cellEditorParams: this.priceCellEditorParams,
        cellStyle: {
          color: "black",
          textAlign: "right"
        },
        // valueGetter(params) {
        //   return params.data.price;
        // },
        // valueFormatter: priceFormatter,
        // valueSetter: priceValueSetter,
        cellRendererFramework: this.priceCellRender,
        filter: "agNumberColumnFilter",
        filterParams: {
          nullComparator: {
            equals: false,
            lessThan: false,
            greaterThan: false
          },
          clearButton: true
        },
        enableRowGroup: false
      },
      {
        headerName: localeStrings["xmm.portal.grid.price_caption"],
        field: "priceCaption",
        editable: true,
        enableRowGroup: false,
        cellClass: "editable-cell",
        minWidth: 100,
        maxWidth: 200,
        cellEditorSelector(params) {
          if (isSameValue(params.data.serviceId, tellUsMoreServiceId)) {
            params.api.stopEditing();
            return null;
          } else {
            return {
              component: "textEditor"
            };
          }
        },
        filterParams: { clearButton: true }
      },
      {
        headerName: localeStrings["xmm.portal.grid.price_status"],
        field: "priceStatus",
        enableRowGroup: false,
        editable: false,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        valueGetter: this.priceStatusGetter,
        filterValueGetter: this.priceStatusFilterGetter,
        cellRendererFramework: cellRenderPriceStatus,
        width: 170,
        minWidth: 170
      },
      {
        headerName: localeStrings["xmm.portal.operations.grid.service_hours"],
        field: "serviceHoursCount",
        valueGetter: availableHoursValueGetter,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        editable: false,
        enableRowGroup: false,
        minWidth: 170
      },
      {
        headerName: localeStrings["xmm.portal.grid.inspection_item"],
        field: "inspectionItem",
        // valueGetter: availableHoursValueGetter,
        hide: !this.context.engageEnabled,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, clearButton: true },
        editable: false,
        enableRowGroup: false,
        minWidth: 250
      },
      {
        headerName:
          localeStrings["xmm.portal.operations.grid.labor_time_range"],
        field: "laborTimeRange",
        editable: false,
        enableRowGroup: false,
        minWidth: 50,
        cellStyle: {
          textAlign: "right"
        },
        cellClass: this.laborTimingRangeCellClass,
        cellRendererFramework: this.laborTimingRangeCellRenderer,
        filterParams: { clearButton: true }
      },
      {
        headerName:
          localeStrings["xmm.portal.operations.grid.default_labor_rate_code"],
        field: "dealerLaborRateCodeId",
        // cellClass: "editable-caret-cell",
        editable: true,
        enableRowGroup: false,
        cellClass: this.laborRateCellClass,
        cellEditorSelector: this.laborRateCellEditorParams,
        refData: extractLaborRates(dealerLaborRateCodes),
        // valueFormatter: this.laborRateFormatter,
        cellRendererFramework: this.laborRateCellRenderer,
        minWidth: 50,
        cellStyle: {
          textAlign: "left"
        },
        filter: "agSetColumnFilter",
        filterParams: { clearButton: true }
      }
    ];
    return customCols;
  }

  hasAnyMake(selectionlist) {
    for (let index = 0; index < selectionlist.length; index++) {
      const { make } = selectionlist[index];
      if (make === "ANY") {
        return true;
      }
    }
    return false;
  }
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  isDeleteDisabled(selectionlist) {
    const { tellUsMoreServiceId } = this.context;
    return (
      selectionlist.length === 0 ||
      (selectionlist.length === 1 &&
        isSameValue(selectionlist[0].serviceId, tellUsMoreServiceId))
    );
  }
  render() {
    const { baseLocale, locale, localeStrings } = this.context;
    const isBaseLocale = baseLocale === locale;
    // set component state to operation-context
    const contextValue = {
      appContext: this.context, // send AppContext{} as props
      localeStrings: this.context.localeStrings,
      discardUnsavedChanges: this.context.discardUnsavedChanges,
      webKey: this.context.webKey,
      dealerCode: this.context.dealerCode,
      userName: this.context.user.userName,
      editOption: this.state.editOption,
      loadOperation: this.state.loadOperation,
      selectionlist: this.state.selectionlist,
      operations: this.state.operations,
      serviceKinds: this.state.serviceKinds,
      testName: this.context.testName,
      makeRateCodesMap: this.context.makeRateCodesMap,
      updateGridAfterBulkEdit: this.updateGridAfterBulkEdit,
      updateGridAfterSave: this.updateGridAfterSave
    };
    const deleteDisabled = this.isDeleteDisabled(this.state.selectionlist);
    const alertMsg = (
      <div className="hide">
        <AlertBox
          htmlId="operationsBanner"
          type="info"
          closeButton={true}
          message={this.state.sampleMsg}
        />
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    const deleteFactoryOperationsWarning = (
      <DeleteFactoryOperationsWarning
        showDeleteOperationsWarning={this.state.showDeleteOperationsWarning}
        closeWarning={this.closeFactoryDeleteOperationsWarning}
      />
    );

    const deleteConfirmationDialog = (
      <Confirmation
        htmlId="deleteOperations"
        message={this.state.deleteConfirmationMessage}
        proceedButtonStyle="danger"
        show={this.state.showDeleteOperationsModal}
        actionFunction={this.handleDeleteOperations}
        closeDialog={this.closeDeleteOperationsModal}
      />
    );

    const exportDealerOperationsModal = (
      <ExportDealerOperationsModal
        show={this.state.showExportOperationsModal}
        exportOperations={this.handleCsvExport}
        closeDialog={this.closeExportOperationsModal}
        makesList={this.context.dealerCatalogs}
      />
    );

    const importDealerOperationsModal = (
      <ImportDealerOperationsModal
        ref="importDealerOpsRef"
        show={this.state.showImportOperationsModal}
        importOperations={this.handleCsvImport}
        closeDialog={this.closeImportOperationsModal}
        makesList={this.context.dealerCatalogs}
        title="Import Operations"
      />
    );

    const gridWidget = (
      <div id="grid-wrapper">
        <div id="summaryView" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            // localeTextFunc={this.state.localeTextFunc}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            multiSortKey={this.state.multiSortKey}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            isRowSelectable={this.state.isRowSelectable}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.rowData}
            components={this.state.components}
            floatingFilter={true}
            columnTypes={this.state.columnTypes}
            sideBar={this.state.sideBar}
            onGridReady={this.onGridReady}
            onColumnResized={this.handleColumnResized}
            onGridSizeChanged={this.handleGridSizeChanged}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            rowStyle={this.state.rowStyle}
            rowHeight={50}
            statusBar={this.state.statusBar}
            // onCellEditingStarted={this.onCellEditingStarted}
            onCellValueChanged={this.onCellValueChanged}
            onCellClicked={this.onCellClickedEvent}
            getRowNodeId={this.getRowNodeId}
            enableRangeSelection={false}
            enableCharts={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enableCellChangeFlash={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            // navigateToNextCell={navigateToNextCell}
            // rowMultiSelectWithClick={true}
            onFilterChanged={this.onFilterChanged}
          />
        </div>
      </div>
    );

    const allowEdit = this.state.editOption === "edit";
    const modalTitle = allowEdit ? (
      <span>{this.state.operationName}</span>
    ) : (
      <span>
        <FormattedMessage
          defaultMessage="Add Operation"
          id="xmm.portal.operations.form.add_button"
        />
      </span>
    );
    const findOpCodesModal = (
      <FindOpCodesDialog
        showValidateCatalog={true}
        dealerCode={contextValue.dealerCode}
        serviceId={this.state.findOpcodeServiceId}
        internalName={this.state.findOpcodeInternalName}
        dmsOpcode={this.state.findOpcodeOpcode}
        dmsDescription={this.state.findOpcodeDmsDescription}
        localeStrings={contextValue.localeStrings}
        manualOpcodes={this.context.manualOpcodes}
        show={this.state.showOpCodeModal}
        closeDialog={this.closeOpcodeModal}
        setManualOpcodes={this.context.setManualOpcodes}
        setOpcodeValue={this.state.setOpcodeValueFunc}
      />
    );
    const storeHoursDialog = this.renderStoreHoursModal(
      this.state.showStoreHoursModal
    );
    const bulkModalTitle = (
      <span>
        <FormattedMessage
          defaultMessage="Bulk Edit"
          id="xmm.portal.operations.bulkedit.title"
        />
        {": "} {this.state.selectionlist.length}{" "}
        {localeStrings["xmm.portal.operations.title"]}
      </span>
    );
    const bulkeditSlider = (
      <GenericSlider
        title={bulkModalTitle}
        htmlId="bulkEditSlider"
        showSlide={this.state.bulkEditSlide}
        toggleSlider={this.closeBulkEditSlider}
        sliderWidth={this.state.bulkEditSliderWidth}
        flexWidth={!this.state.flexWidth}
      >
        <BulkEditPane
          key={"bulkOperations"}
          showFindOpcodeModal={this.openOpcodeModal}
          closeSlider={this.closeBulkEditSlider}
          updateStatusBox={this.updateStatusBox}
        />
      </GenericSlider>
    );

    const disableBulkEdit = false; // this.hasAnyMake(this.state.selectionlist);

    // Pass Pricing workflow to show badge in slider expect or ANY make
    const { makeVariantMap } = this.context;
    const { make } = this.state.loadOperation;
    const dealerCatalog = makeVariantMap[make];
    let pricingMethod = null;
    if (!isEmpty(dealerCatalog)) {
      pricingMethod = make !== "ANY" ? dealerCatalog.pricingMethod : ""; // 0,1,2
    }
    const operationSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editOperationSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        pricingMethod={pricingMethod}
        expandSlider={this.state.expandWidth}
      >
        <OperationTabsNew
          key={"editOperation"}
          loadOperation={this.state.loadOperation}
          editOption={this.state.editOption}
          updateGridAfterSave={this.updateGridAfterSave}
        />
      </GenericSlider>
    );

    const header = (
      <React.Fragment>
        {alertMsg}
        {deleteFactoryOperationsWarning}
        {deleteConfirmationDialog}
        {exportDealerOperationsModal}
        {importDealerOperationsModal}

        <div className="content-header">
          <h3 className="xmm-main-title">
            <FormattedMessage
              defaultMessage="Operations"
              id="xmm.portal.operations.title"
            />
          </h3>
          <div className="xmm-form-header">
            {statusHtml}
            <Button
              htmlId="openBulkeditBtn"
              buttonStyle="primary"
              onClick={this.openBulkEditSlider}
              className="xmm-bulkedit-btn"
              disabled={disableBulkEdit}
              hidden={this.state.selectionlist.length < 2}
            >
              <FormattedMessage
                defaultMessage="Bulk Edit"
                id="xmm.portal.operations.bulkedit.title"
              />
              {" ("}
              {this.state.selectionlist.length}
              {")"}
            </Button>
            <Button htmlId="addOperationBtn" onClick={this.addOperationSlider}>
              <FormattedMessage
                defaultMessage="Add Operation"
                id="xmm.portal.operations.form.add_button"
              />
            </Button>
            <div className="xmm-input-search">
              <input
                type="text"
                id="operation-search-box"
                className="xmm-input"
                placeholder={
                  this.context.localeStrings["xmm.portal.common.search_label"]
                }
                onChange={this.onSearchBoxChanged}
                value={this.state.searchKey}
                autoComplete="off"
              />
            </div>
            <SelectInput
              htmlId="VariantTypeSelect"
              placeholder="Select"
              disabled={false}
              displayLabel={false}
              displayPlaceholder={false}
              maxHeight={100}
              name="variantType"
              onChange={this.onVariantTypeChange}
              label="Variant"
              value={this.state.variantType}
              options={[
                {
                  value: "ALL",
                  label: this.context.localeStrings[
                    "xmm.portal.operations.status_any"
                  ]
                },
                {
                  value: "ADDED",
                  label: this.context.localeStrings[
                    "xmm.portal.operations.new_ops_only"
                  ]
                },
                {
                  value: "CHANGED",
                  label: this.context.localeStrings[
                    "xmm.portal.operations.changed_ops_only"
                  ]
                }
              ]}
            />
            <SelectInput
              htmlId="ColumnViewSelect"
              placeholder="Select"
              disabled={false}
              displayLabel={false}
              displayPlaceholder={false}
              maxHeight={100}
              name="viewMode"
              onChange={this.onColumnViewChange}
              label="View"
              value={this.state.viewMode}
              options={[
                {
                  value: "basic",
                  label: this.context.localeStrings[
                    "xmm.portal.operations.basic_view_label"
                  ]
                },
                {
                  value: "pricing",
                  label: this.context.localeStrings[
                    "xmm.portal.operations.pricing_view_label"
                  ]
                },
                {
                  value: "all",
                  label: this.context.localeStrings[
                    "xmm.portal.operations.all_columns_label"
                  ]
                }
              ]}
            />
            <DropdownButton
              title={<IconMore />}
              id="operationsActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              pullRight
            >
              <DropdownMenuItem
                htmlId="importBtn"
                eventKey={{ eventKey: ["import-ops"] }}
                disabled={!isBaseLocale}
                onSelect={this.openImportOperationsModal}
              >
                {localeStrings["xmm.portal.operations.import_operations_label"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="exportBtn"
                eventKey={{ eventKey: ["export-ops"] }}
                disabled={!isBaseLocale}
                onSelect={this.openExportOperationsModal}
              >
                {localeStrings["xmm.portal.operations.export_operations_label"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="deleteOpsBtn"
                disabled={deleteDisabled}
                eventKey={{ eventKey: ["delete-ops"] }}
                onSelect={this.openDeleteOperationsModal}
              >
                {localeStrings["xmm.portal.operations.delete_operations_label"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="refreshBtn"
                divider
                dividerPlacement="top"
                eventKey={{ eventKey: ["refresh"] }}
                onSelect={this.refreshOperations}
              >
                {localeStrings["xmm.portal.common.refresh_button"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="clearFiltersBtn"
                eventKey={{ eventKey: ["clear-filters"] }}
                // disabled={!this.state.filterMode}
                onSelect={this.clearFilters}
              >
                {localeStrings["xmm.portal.common.clear_filters"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="saveGridStateBtn"
                className="hide"
                eventKey={{ eventKey: ["save-state"] }}
                onSelect={this.saveGridState}
              >
                Save State
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="restoreGridStateBtn"
                className="hide"
                eventKey={{ eventKey: ["restore-state"] }}
                onSelect={this.restoreGridState}
              >
                Restore State
              </DropdownMenuItem>
            </DropdownButton>
          </div>
        </div>
        {operationSlider}
        {bulkeditSlider}
        {findOpCodesModal}
        {storeHoursDialog}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <OperationContext.Provider value={contextValue}>
          <span id="selectedRows" />
          {header}
          {gridWidget}
        </OperationContext.Provider>
      </React.Fragment>
    );
  }
}
const checkboxFields = [
  "waiterAllowed",
  "loanerAllowed",
  "mandatoryInAppt",
  "commentsRequired",
  "unscheduledDefault"
];
const validationFields = ["price", "shopDuration"];
const editableFields = [
  "checked",
  // "make",
  "internalName",
  // "parentId",
  // "serviceKind",
  "metaVehicleScope",
  "appsInDisplay",
  "dmsOpcode",
  "shopDuration",
  "rank",
  // "serviceCategoryName",
  // "name",
  // "description",
  "waiterAllowed",
  "loanerAllowed",
  "mandatoryInAppt",
  // "commentsRequired",
  "unscheduledDefault",
  // "contentEnabledMenus",
  "price",
  "priceCaption",
  // "priceStatus",
  // "serviceHoursCount",
  // "laborTimeRange",
  "variantAdded",
  "variantChanged",
  "dealerLaborRateCodeId"
];
function getBaseCols(localeStrings) {
  const baseCols = [
    {
      headerName: "",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: "left",
      field: "checked",
      type: "actionColumn",
      suppressSizeToFit: true,
      suppressColumnsToolPanel: true, // hide item in sidebar.columns
      maxWidth: 40,
      minWidth: 40,
      width: 40
    },
    {
      headerName: localeStrings["xmm.portal.grid.make"],
      field: "make",
      colId: "make",
      pinned: "left",
      editable: false,
      autoHeight: true,
      sort: "asc",
      filter: "agSetColumnFilter",
      maxWidth: 150,
      minWidth: 100,
      suppressSizeToFit: true,
      enableRowGroup: false,
      filterParams: {
        clearButton: true,
        comparator: sortByMake
      }
    },
    {
      headerName: localeStrings["xmm.portal.grid.internalname"],
      editable: false,
      pinned: "left",
      field: "internalName",
      colId: "internalName",
      cellClass: "xmm-link-cell",
      sortingOrder: ["asc", "desc"],
      enableRowGroup: false,
      minWidth: 250,
      maxWidth: 400,
      autoHeight: true,
      filterParams: {
        clearButton: true
      }
    },
    {
      headerName: localeStrings["xmm.portal.grid.opcode"],
      field: "dmsOpcode",
      enableRowGroup: false,
      cellRendererFramework: cellRenderOpcode,
      cellEditorSelector(params) {
        if (
          params.data.isOpcodeDealerUpdateable &&
          params.data.isOpcodeDealerUpdateable === 1
        ) {
          return {
            component: "opcodeEditor"
          };
        } else {
          params.api.stopEditing();
          return null;
        }
      },
      filterParams: {
        clearButton: true
      },
      // cellClassRules: getCellClassRule(validationFields),
      cellClass: "editable-cell",
      minWidth: 110
      // maxWidth: 200
    },
    {
      headerName: localeStrings["xmm.portal.grid.createdby"],
      field: "parentId",
      colId: "parentId",
      editable: false,
      enableRowGroup: false,
      filter: "agSetColumnFilter",
      valueGetter: createdByValueGetter,
      filterParams: { suppressMiniFilter: true, clearButton: true },
      maxWidth: 150,
      minWidth: 100
    },
    {
      headerName: localeStrings["xmm.portal.grid.servicekind"],
      field: "serviceKind",
      valueSetter: serviceKindSetter,
      valueFormatter: serviceKindFormatter,
      enableRowGroup: false,
      cellEditorSelector(params) {
        if (isSameValue(params.data.serviceId, tellUsMoreServiceId)) {
          params.api.stopEditing();
          return null;
        } else if (params.data.parentId === 0 || params.data.parentId === null)
          return {
            component: "agRichSelectCellEditor"
          };
        else if (params.data.parentId && params.data.parentId > 0) {
          params.api.stopEditing();
          return null;
        } else {
          params.api.stopEditing();
          return null;
        }
      },
      cellEditorParams: {
        cellHeight: 25,
        values: [{ repair: "Repair" }, { maintenance: "Maintenance" }]
      },
      cellClass: serviceKindCellClass,
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        selectAllOnMiniFilter: true,
        newRowsAction: "keep",
        clearButton: true
      },
      /* TODO - place holder if we want to style text in other columns
      cellRenderer(params) {
        return (
          '<span class="capitalized-text rag-element">' +
          params.value +
          "</span>"
        );
      },
      */
      width: 150,
      minWidth: 100
    },
    {
      headerName: localeStrings["xmm.portal.grid.alacarte_vehicles"],
      headerTooltip: "ENABLED VEHICLES",
      field: "metaVehicleScope",
      enableRowGroup: false,
      filter: "agSetColumnFilter",
      // cellEditor: "agRichSelectCellEditor",
      // cellEditorParams: {
      //   values: extractValues(MetaVehicleScopeMap)
      // },
      cellEditorSelector(params) {
        // "-1": "None",
        // "3": "Factory Assigned",
        // "1": "All Vehicles",
        // "0": "Choose Vehicles",
        // "4": "Unscheduled Only",
        // "2": "All except Supported Makes"
        if (isSameValue(params.data.serviceId, tellUsMoreServiceId)) {
          params.api.stopEditing();
          return null;
        }
        if (params.data.make === "ANY") {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: extractValues(MetaVehicleScopeAnyCatalogMap)
            }
          };
        }
        if (params.data.parentId === 0 || params.data.parentId === null) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: extractValues(MetaVehicleScopeDealerCatalogMap)
            }
          };
        } else {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: extractValues(MetaVehicleScopeVariantCatalogMap)
            }
          };
        }
      },
      cellClass: "editable-caret-cell",
      refData: MetaVehicleScopeMap,
      width: 180,
      minWidth: 180,
      filterParams: {
        clearButton: true
      }
    },
    {
      headerName: localeStrings["xmm.portal.grid.display_apps"],
      field: "appsInDisplay",
      colId: "appsInDisplay",
      cellClass: "editable-caret-cell",
      editable: true,
      valueGetter: params => {
        if (!params || !params.data) {
          return "";
        }
        const { enabled, reservableByCustomers } = params.data;
        if (enabled && reservableByCustomers) {
          return "0";
        } else if (enabled) {
          return "1";
        }
        return "-1";
      },
      valueSetter: displayInAppsValueSetter,
      enableRowGroup: false,
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        selectAllOnMiniFilter: true, // since version 22.x, the Set Filter param is no longer used
        newRowsAction: "keep",
        clearButton: true
      },
      cellEditorSelector(params) {
        // "None": "None",
        // "All (Internal and Consumer)",
        // "Internal only",
        return {
          component: "agRichSelectCellEditor",
          params: {
            values: ["-1", "0", "1"]
          }
        };
      },
      refData: {
        "-1": localeStrings["xmm.portal.common.none"],
        "0": localeStrings["xmm.portal.common.all_lbl"],
        "1": localeStrings["xmm.portal.common.internal_only_lbl"]
      },
      width: 110,
      minWidth: 190
    },
    {
      headerName: localeStrings["xmm.portal.grid.schedule_duration"],
      field: "shopDuration",
      enableRowGroup: false,
      // unSortIcon: true,
      maxWidth: 120,
      minWidth: 120,
      type: "numberColumn",
      cellEditorSelector(params) {
        if (
          params.data.isDurationDealerUpdateable &&
          params.data.isDurationDealerUpdateable === 1
        ) {
          return {
            component: "numericEditor"
          };
        } else {
          params.api.stopEditing();
          return null;
        }
      },
      cellClass: "editable-cell",
      // valueGetter(params) {
      //   return params.data.shopDuration;
      // },
      // valueSetter: shopDurationValueSetter,
      filter: "agNumberColumnFilter",
      filterParams: {
        nullComparator: {
          equals: false,
          lessThan: false,
          greaterThan: false
        },
        clearButton: true
      }
    },
    {
      headerName: localeStrings["xmm.portal.grid.rank"],
      field: "rank",
      // headerClass: "ag-centered-header",
      type: "numberColumn",
      width: 120,
      maxWidth: 120,
      minWidth: 100,
      cellEditor: "numericEditor",
      cellClass: "editable-cell",
      // valueGetter: params => Number(params.data.rank),
      filter: "agNumberColumnFilter",
      filterParams: {
        nullComparator: {
          equals: false,
          lessThan: false,
          greaterThan: false
        },
        clearButton: true
      },
      enableRowGroup: false
    },
    /* TODO - This col used for testing only
    {
      headerName: "ServiceId",
      field: "serviceId",
      editable: false,
      enableRowGroup: false,
      filter: false,
      tooltipField: "internalName",
      tooltipComponentParams: { field: "internalName" },
      tooltipComponent: "customTooltip",
      minWidth: 100
    },
    */
    /* These columns are required in columnDef to support external filter (Any Status) */
    {
      headerName: "New",
      field: "variantAdded",
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      filterParams: {
        cellHeight: 0
      }
    },
    {
      headerName: "Changed",
      field: "variantChanged",
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      filterParams: {
        cellHeight: 0
      }
    }
  ];
  return baseCols;
}

function extractValues(mappings) {
  return Object.keys(mappings);
}

function displayInAppsValueSetter(params) {
  if (!params.newValue || !params.data) {
    return false;
  }
  // “None”, “All (Internal and Consumer)”, “Internal only”
  if (params.newValue === "-1") {
    params.data.enabled = 0;
    params.data.reservableByCustomers = 0;
  } else if (params.newValue === "1") {
    params.data.enabled = 1;
    params.data.reservableByCustomers = 0;
  } else if (params.newValue === "0") {
    params.data.enabled = 1;
    params.data.reservableByCustomers = 1;
  }
  return true;
}

// This formatter used in more columns in this grid
// function booleanValueFormatter(params) {
//   const val = params.value;
//   return val ? (val === "1" ? "Enabled" : val === "0" ? "Disabled" : val) : val;
// }

function contentStatusValueGetter(params) {
  if (!params || !params.data) {
    return "";
  }
  const { contentEnabledAlacarte, contentEnabledMenus } = params.data;
  let result = "";
  if (contentEnabledMenus) {
    result = xlate("xmm.portal.common.active_menu");
  }
  if (contentEnabledAlacarte) {
    if (result !== "") {
      result = xlate("xmm.portal.common.active_menu_and_alacarte");
    } else {
      result = xlate("xmm.portal.common.active_alacarte");
    }
  }
  if (contentEnabledMenus === 0 && contentEnabledAlacarte === 0) {
    result = xlate("xmm.portal.common.inactive_content");
  }
  return result;
}

function availableHoursValueGetter(params) {
  const val = params.data && params.data.serviceHoursCount | 0;
  return val === 0
    ? xlate("xmm.portal.common.all_hours")
    : xlate("xmm.portal.common.custom_hours");
}

function createdByValueGetter(params) {
  const val = params.data ? params.data.parentId | 0 : null;
  return val === 0 || val === null ? CreatedByMap.dealer : CreatedByMap.factory;
}

function serviceKindCellClass(params) {
  return params.data &&
    (params.data.parentId === 0 || params.data.parentId === null)
    ? "editable-cell"
    : "";
}

function serviceKindFormatter(params) {
  const value = params.value;
  // when cellEditor touched - params.value reads cellEditorParams.values
  if (value && typeof value !== "string") {
    const keys = Object.keys(value || {});
    const key = keys[0];
    // console.log("editor case", value, key, ServiceKindMap[key]);
    return ServiceKindMap[key];
  } else {
    // console.log("regular case", params.value, value, ServiceKindMap[value]);
    return ServiceKindMap[value];
  }
}

function printResult(res) {
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log("Added Row Node", rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {
      console.log("Removed Row Node", rowNode);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log("Updated Row Node", rowNode);
    });
  }
}
function cellRenderOpcode(params) {
  if (!params || !params.data) {
    return "";
  }
  const value = params.value;
  const { numOpcodeOverrides } = params.data;
  if (
    numOpcodeOverrides !== undefined &&
    numOpcodeOverrides !== null &&
    numOpcodeOverrides > 0
  ) {
    const keyId = params.data.serviceId.toString() + "-opcodeToolTip";
    const tooltipText = xlate("xmm.portal.common.override_count").replace(
      "%1",
      numOpcodeOverrides
    );
    return (
      <div className="xmm-override-cell">
        {toEmptyStringIfUndefined(value)} &nbsp;&nbsp;
        <Tooltip htmlId={keyId} tooltipContent={tooltipText}>
          <span className="badge-count">{numOpcodeOverrides}</span>
        </Tooltip>
      </div>
    );
  }
  return toEmptyStringIfUndefined(value);
}
function cellRenderPriceStatus(params) {
  if (!params || !params.data) {
    return "";
  }
  const { numPricingOverrides } = params.data;
  if (doesEmpty(numPricingOverrides)) {
    return toEmptyStringIfUndefined(params.value);
  } else {
    const keyId = params.data.serviceId.toString() + "-priceStatusToolTip";
    const tooltipText = xlate("xmm.portal.common.override_count").replace(
      "%1",
      numPricingOverrides
    );
    return (
      <div className="xmm-override-cell">
        {toEmptyStringIfUndefined(params.value)} &nbsp;&nbsp;
        <Tooltip htmlId={keyId} tooltipContent={tooltipText}>
          <span className="badge-count">{numPricingOverrides}</span>
        </Tooltip>
      </div>
    );
  }
}

/* eslint-enable no-console */
