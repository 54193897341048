/* eslint-disable no-console */
import React, { Component } from "react";
import { bool, PropTypes, string } from "prop-types";
import Badge from "@cx/ui/Badge";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Button from "@cx/ui/Button";
import Popover from "@cx/ui/Popover";
import TextInput from "@cx/ui/TextInput";
import TextArea from "@cx/ui/TextArea";
import Tooltip from "@cx/ui/Tooltip";
import NumericInput from "@cx/ui/NumericInput";
import AccordionGroup from "@cx/ui/AccordionGroup";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import IconMore from "@cx/ui/Icons/IconMore";
import IconSearch from "@cx/ui/Icons/IconSearch";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import FullPageModal from "@cx/ui/FullPageModal";
import { OperationContext } from "../../../operation-context";
import {
  ApplicationVisibilityOptions,
  MVC_CHOOSE_VEHICLES,
  MetaVehicleScopeAnyCatalogMap,
  MetaVehicleScopeVariantCatalogMap,
  MetaVehicleScopeDealerCatalogMap,
  NO_PRICING_ANY_CATALOG
} from "./../../../../../../constants/ModuleConstants";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import FindOpCodesDialog from "../../../../../../commonUtil/dialog/FindOpCodesDialog";
import {
  isArrayExist,
  objectToArrayOfLabelValue,
  doesEmpty
} from "../../../../../../commonUtil/utils/object";
import { Field } from "formik";
import StyledSelect from "../../../../../formik/StyledSelect";
import StyledSearchableSelect from "../../../../../formik/StyledSearchableSelect";
import * as formvalidator from "../../../../../formik/formvalidator";
import FieldError from "../../../../../formik/FieldError";
import { FormattedMessage } from "react-intl";
import StoreHoursDialog from "../../../../../reusable/modals/StoreHoursDialog";
// import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../../../utils/gtag-eventlist";
import {
  toEmptyStringIfUndefined,
  isDifferentValue,
  isSameValue,
  sanitizeMle
} from "../../../../../../commonUtil/utils/string";
import {
  getInspectionItemDisplay,
  InspectionItemCategory
} from "../../../../../../components/reusable/operation";
import VehicleGroupSelector from "../../../../../reusable/VehicleGroups/VehicleGroupSelector";
import StatusBox from "../../../../../../commonUtil/components/templates/StatusBox";
import TranslationPage from "../../../../Settings/Translations/TranslationPage";
import { toast } from "@cx/ui/Toast";
import { xlate } from "../../../../../../commonUtil/i18n/locales";
class OperationFormNew extends Component {
  static propTypes = {};
  static contextType = OperationContext;

  constructor(props, context) {
    super(props, context);
    // console.log("operation Form props", props);
    this.handleSearchableChange = this.handleSearchableChange.bind(this);
    this.onAppVisibilityChange = this.onAppVisibilityChange.bind(this);
    this.onUpdateStoreHoursCount = this.onUpdateStoreHoursCount.bind(this);
    this.onSetNamedVehicleFilter = this.onSetNamedVehicleFilter.bind(this);
    const appVisibility = this.getAppVisibility(
      props.loadOperation.enabled,
      props.loadOperation.reservableByCustomers
    );
    const metaVehicleScopeOptions = this.getMetaVehicleScopeChoices(
      props.loadOperation,
      props.loadOperation.make
    );
    this.onBlurVehicleGroup = this.onBlurVehicleGroup.bind(this);
    this.onChangeVehicleGroup = this.onChangeVehicleGroup.bind(this);
    this.onChange = this.onChange.bind(this);
    const showOpCodeModal = false;

    // check if shopDuration is in error
    const { make, errors } = props.loadOperation;
    if (errors && errors.shopDuration && errors.shopDuration !== "") {
      props.formikProps.errors.shopDuration = true;
      props.formikProps.touched["shopDuration-maskedInput"] = true;
    }
    const {
      engageEnabled,
      dealerMakeCategoryInspectionItemMap,
      dealerMakeInspectionItemMap
    } = context.appContext;
    let dealerServiceCategoryInspectionItemMap = {};
    let dealerMakeInspectionItems = [];
    if (engageEnabled && make) {
      if (dealerMakeCategoryInspectionItemMap[make]) {
        dealerServiceCategoryInspectionItemMap =
          dealerMakeCategoryInspectionItemMap[make];
      }
      if (dealerMakeInspectionItemMap[make]) {
        dealerMakeInspectionItems = dealerMakeInspectionItemMap[make];
      }
    }

    this.initializeLocaleValues();

    this.state = {
      appVisibility,
      showOpCodeModal,
      metaVehicleScopeOptions,
      contentEnabledMenus: null,
      isFactoryOperation: formvalidator.isFactoryOperation(
        props.loadOperation.parentId
      ),
      serviceHoursCount: props.loadOperation.serviceHoursCount || 0,
      storeHoursData: {
        serviceHoursCount: props.loadOperation.serviceHoursCount,
        show: false
      },
      dealerMakeInspectionItems,
      dealerServiceCategoryInspectionItemMap
    };
  }
  componentDidMount() {
    gtmEvents.trackGtagPageview("/operations/operations/settings");
    if (this.props.editOption === "edit") {
      const { make } = this.props.loadOperation;
      if (make !== "ANY") {
        this.findOperationSummary();
      }
      const { engageEnabled } = this.context.appContext;
      if (engageEnabled && make) {
        const {
          dealerMakeInspectionItemMap,
          dealerMakeCategoryInspectionItemMap
        } = this.context.appContext;
        if (!dealerMakeCategoryInspectionItemMap[make]) {
          this.getServiceCategoryInspectionItemMap(make);
        }
        if (!dealerMakeInspectionItemMap[make]) {
          this.getDealerInspectionTags(make);
        }
      }
    }
    // this.getGroupNameList();
    window.dispatchEvent(
      new CustomEvent("formHandleEvent", {
        detail: this, // send operationForm reference
        // detail: { type: "operationFormHandle", handle: this },
        bubbles: true,
        cancelable: true
      })
    );
    window.addEventListener(
      "updateStoreHoursCount",
      this.onUpdateStoreHoursCount,
      false
    );
    window.addEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "updateStoreHoursCount",
      this.onUpdateStoreHoursCount,
      false
    );
    window.removeEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }

  initializeLocaleValues() {
    this.translationLabel = xlate("xmm.portal.nav.translations");
  }

  // implement isDirty if speed bump is being used
  isDirty = () => {
    return this.props.formikProps.dirty;
  };

  getAppVisibility = (dealerVisible, consumerVisible) => {
    if (dealerVisible && consumerVisible) {
      return "0";
    } else if (dealerVisible) {
      return "1";
    }
    return "-1";
  };

  findOperationSummary = () => {
    const { dealerCode, localeStrings } = this.context;
    const includeWithLaborOnly = 0;
    const serviceKind = "";
    // console.log("Operation-Context", this.context);

    // get all operations like summary view
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationSummary",
        method: "get",
        data: {},
        params: {
          make: this.props.loadOperation.make,
          variant: this.props.loadOperation.variant,
          dealerCode,
          includeWithLaborOnly,
          serviceKind,
          serviceId: this.props.loadOperation.serviceId
        }
      },
      data => {
        const servicelist = data;
        let datalist = [];
        // check if response has single object or array of objects
        if (!isArrayExist(servicelist) && typeof servicelist === "object") {
          datalist.push(servicelist);
        } else if (isArrayExist(servicelist) && servicelist.length > 0) {
          datalist = servicelist;
        }
        const contentEnabledMenus =
          datalist.length > 0 && datalist[0].contentEnabledMenus !== null
            ? datalist[0].contentEnabledMenus === 1
              ? localeStrings["xmm.portal.common.yes_label"]
              : localeStrings["xmm.portal.common.no_label"]
            : "";
        this.setState({
          contentEnabledMenus
        });
      }
    );
  };
  getServiceCategoryInspectionItemMap = make => {
    const {
      dealerCode,
      makeVariantMap,
      locale,
      localeStrings
    } = this.context.appContext;
    const { variant } = makeVariantMap[make];
    const restUrl =
      "/ops/proxyapi/ddsproxy/rest/proc/getServiceCategoryInspectionItemMap";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: { dealerCode, variant, locale }
      },
      datalist => {
        const dealerServiceCategoryInspectionItemMap = {};
        datalist.forEach(item => {
          const { serviceCategoryId } = item;
          // const { categoryName, inspectionItemName } = item;
          item.label = getInspectionItemDisplay(item); // `${categoryName} - ${inspectionItemName}`;
          item.value = InspectionItemCategory;
          dealerServiceCategoryInspectionItemMap[
            serviceCategoryId.toString()
          ] = item;
          return item;
        });
        const {
          dealerMakeCategoryInspectionItemMap
          // updateDealerServiceCategoryInspectionItemMap
        } = this.context.appContext;
        dealerMakeCategoryInspectionItemMap[
          make
        ] = dealerServiceCategoryInspectionItemMap;
        // updateDealerServiceCategoryInspectionItemMap(dealerMakeCategoryInspectionItemMap);
        this.setState({ dealerServiceCategoryInspectionItemMap });
      },
      error => {
        const errorKey = "xmm.portal.operations.load_data_error";
        const msg = error["message"] ? error.message : localeStrings[errorKey];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  getDealerInspectionTags = make => {
    const {
      dealerCode,
      makeVariantMap,
      locale,
      localeStrings
    } = this.context.appContext;
    const { variant } = makeVariantMap[make];
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getDealerInspectionTags";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: { dealerCode, variant, locale }
      },
      datalist => {
        const dealerMakeInspectionItems = datalist.map(item => {
          // const { categoryName, inspectionItemName, serviceTag } = item;
          item.label = getInspectionItemDisplay(item); // `${categoryName} - ${inspectionItemName}`;
          item.value = toEmptyStringIfUndefined(item.serviceTag);
          return item;
        });
        dealerMakeInspectionItems.sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        });
        const { dealerMakeInspectionItemMap } = this.context.appContext;
        dealerMakeInspectionItemMap[make] = dealerMakeInspectionItems;
        this.setState({ dealerMakeInspectionItems });
      },
      error => {
        const errorKey = "xmm.portal.operations.load_data_error";
        const msg = error["message"] ? error.message : localeStrings[errorKey];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  onAppVisibilityChange = (cxEvent, field, form) => {
    const { value } = cxEvent.target;
    if (value) {
      const options = {
        enabled: 0,
        reservableByCustomers: 0
      };
      // console.log("onAppVisibilityChange", value);
      switch (value) {
        case "0":
          options.enabled = 1;
          options.reservableByCustomers = 1;
          break;
        case "1":
          options.enabled = 1;
          options.reservableByCustomers = 0;
          break;
        default:
          options.enabled = 0;
          options.reservableByCustomers = 0;
          break;
      }
      const appVisibility = value;
      this.setState({ appVisibility, options });
      form.setFieldValue(field.name, options.enabled);
      form.setFieldValue(
        "reservableByCustomers",
        options.reservableByCustomers
      );
    }
  };

  onSetNamedVehicleFilter = event => {
    const { metaVehicleFilterId } = event.detail;
    const { formikProps } = this.props;
    // console.log("onSetNamedVehicleFilter", event.detail, formikProps);
    formikProps.setFieldValue(
      "metaVehicleFilterId",
      metaVehicleFilterId.toString()
    );
    formikProps.setFieldTouched("metaVehicleFilterId", true);
  };

  onUpdateStoreHoursCount = event => {
    const { serviceHoursCount } = event.detail;
    this.props.loadOperation.serviceHoursCount = serviceHoursCount;
    this.setState({ serviceHoursCount });
  };

  /* handler for selectInput  */
  handleSelectChange = (event, field, form) => {
    // console.log("Select change in parent", event.target);
    if (event.target.name === "metaVehicleScope") {
      if (event.target.value !== MVC_CHOOSE_VEHICLES) {
        form.setFieldValue("metaVehicleFilterId", "");
      }
    }
    form.setFieldValue(field.name, event.target.value);
    // console.log("select field auth", this.context.appContext);
  };

  /* handler for searchableSelect  */
  handleSearchableChange = (cxEvent, field, form, domEvent) => {
    const { value } = cxEvent.target;
    let optionValue = value;
    if (optionValue instanceof Array) {
      optionValue = optionValue.length === 0 ? "" : optionValue[0].value;
    }
    if (optionValue instanceof Number) {
      optionValue = optionValue.toString();
    }
    // console.log("Searchable change event", optionValue, cxEvent, field);
    if (field.name === "metaVehicleFilterId") {
      if (isNaN(optionValue)) {
        return null;
      }
    } else if (field.name === "make") {
      const metaVehicleScopeOptions = this.getMetaVehicleScopeChoices(
        form,
        optionValue
      );
      this.setState({ metaVehicleScopeOptions });
    }

    if (toEmptyStringIfUndefined(form.values[field.name]) !== optionValue) {
      form.setFieldValue(field.name, optionValue);
      if (field.name === "make") {
        if (form.values.serviceId === "") {
          // for new operation, need to set make and variant
          const { dealerCatalogs } = this.context.appContext;
          const makes = dealerCatalogs.filter(m => {
            return m.make === optionValue;
          });
          if (makes.length !== 0) {
            form.setFieldValue("variant", makes[0].variant);
          }
        }
        const {
          engageEnabled,
          dealerMakeCategoryInspectionItemMap,
          dealerMakeInspectionItemMap
        } = this.context.appContext;
        if (engageEnabled && optionValue) {
          if (dealerMakeCategoryInspectionItemMap[optionValue]) {
            const dealerServiceCategoryInspectionItemMap =
              dealerMakeCategoryInspectionItemMap[optionValue];
            this.setState({ dealerServiceCategoryInspectionItemMap });
          } else {
            this.getServiceCategoryInspectionItemMap(optionValue);
          }
          if (dealerMakeInspectionItemMap[optionValue]) {
            const dealerMakeInspectionItems =
              dealerMakeInspectionItemMap[optionValue];
            this.setState({ dealerMakeInspectionItems });
          } else {
            this.getDealerInspectionTags(optionValue);
          }
        }
      } else if (field.name === "serviceCategoryId") {
        const {
          engageEnabled,
          dealerMakeCategoryInspectionItemMap
        } = this.context.appContext;
        const { make } = form.values;
        if (engageEnabled && make) {
          if (dealerMakeCategoryInspectionItemMap[make]) {
            const dealerServiceCategoryInspectionItemMap =
              dealerMakeCategoryInspectionItemMap[make];
            const categoryInspectionItem =
              dealerServiceCategoryInspectionItemMap[optionValue.toString()];
            if (categoryInspectionItem) {
              const inspectionItem = getInspectionItemDisplay(
                categoryInspectionItem
              );
              form.setFieldValue("serviceTag", InspectionItemCategory);
              form.setFieldValue("inspectionItem", inspectionItem);
            } else {
              if (form.values.serviceTag === InspectionItemCategory) {
                form.setFieldValue("serviceTag", "");
                form.setFieldValue("inspectionItem", "");
              }
            }
            this.setState({ dealerServiceCategoryInspectionItemMap });
          } else {
            this.getServiceCategoryInspectionItemMap(make);
          }
        }
      } else if (field.name === "serviceTag") {
        if (cxEvent.target.value && Array.isArray(cxEvent.target.value)) {
          // update "inspectionItem" in form.values
          const inspectionItemOption = cxEvent.target.value[0];
          const inspectionItemDisplay = getInspectionItemDisplay(
            inspectionItemOption
          );
          form.setFieldValue("inspectionItem", inspectionItemDisplay);
        }
      }
    }
    if (domEvent && domEvent.type === "blur") {
      form.setFieldTouched(field.name, true);
    }
    return optionValue;
  };

  handleOpenOpcodeModal = event => {
    this.setState({ showOpCodeModal: true });
    const tagObj = {
      eventResult: "Select an Op Code from DMS Search tool",
      eventFeature: "Operations> Operations Page",
      eventFeatures: "operations page> Find Opcode",
      trackPageUrl: "operations/operations/find-opcode"
    };
    gtmEvents.gtmTrackEventWithParams("xmm.common.findopcode_click", tagObj);
  };

  trackEditHours = () => {
    gtmEvents.gtmTrackEvent("xmm.operations.edit_hours_click");
  };

  closeOpcodeModal = event => {
    this.setState({ showOpCodeModal: false });
  };

  getAvailabilityHoursOption(serviceHoursCount) {
    return serviceHoursCount > 0 ? "1" : "0";
  }

  closeStoreHoursDialog = event => {
    // update grid when store hours is changed.
    const { editOption, formikProps, loadOperation } = this.props;
    if (
      formikProps.values.serviceHoursCount !== loadOperation.serviceHoursCount
    ) {
      this.context.updateGridAfterSave(editOption, loadOperation);
    }

    // console.log("Closing StoreHoursDialog", event);
    this.setState({
      storeHoursData: { ...this.state.storeHoursData, show: false }
    });
  };

  getMetaVehicleScopeChoices(operation, make) {
    // need to use same logic as in SummaryView's cellEditorSelector for MetaVehicleScopeMap
    let choices = [];
    if (make === "ANY") {
      choices = objectToArrayOfLabelValue(MetaVehicleScopeAnyCatalogMap);
    } else if (operation.parentId === 0 || operation.parentId === null) {
      choices = objectToArrayOfLabelValue(MetaVehicleScopeDealerCatalogMap);
    } else {
      choices = objectToArrayOfLabelValue(MetaVehicleScopeVariantCatalogMap);
    }
    return choices;
  }
  onBlurVehicleGroup = (cxEvent, domEvent) => {
    const { formikProps } = this.props;
    formikProps.setFieldTouched("metaVehicleFilterId", true);
  };
  onChangeVehicleGroup = event => {
    const { data } = event.detail;
    const { metaVehicleFilterId, metaVehicleScope } = data;
    const { formikProps } = this.props;
    formikProps.setFieldValue("metaVehicleScope", metaVehicleScope);
    formikProps.setFieldValue(
      "metaVehicleFilterId",
      metaVehicleScope === "1" ? "" : metaVehicleFilterId.toString()
    );
    formikProps.setFieldTouched("metaVehicleFilterId", true);
  };
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const { formikProps } = this.props;
    if (domEvent && domEvent.type === "blur") {
      formikProps.setFieldTouched(name, true);
      return;
    }
    if (isDifferentValue(formikProps.values[name], value)) {
      const sanitizedValue = sanitizeMle(value);
      formikProps.setFieldValue(name, sanitizedValue);
    }
  };

  getStoreHoursDisplay(serviceHoursCount) {
    const { localeStrings } = this.context;
    if (serviceHoursCount) {
      return serviceHoursCount
        .toString()
        .concat(
          " ",
          localeStrings["xmm.portal.operations.form.custom_hours_set"]
        );
    }
    return localeStrings["xmm.portal.operations.form.any_store_hours"];
  }

  hasDuplicateInternalName = operation => {
    const { operationlist } = this.context.appContext;
    const duplicates = operationlist.filter(op => {
      return (
        operation.serviceId.toString() !== op.serviceId.toString() &&
        operation.make === op.make &&
        operation.internalName === op.internalName
      );
    });
    return duplicates.length > 0;
  };

  /* formik submit callback - triggers formik handleSubmit handler */
  handleSave = e => {
    e.preventDefault();
    const { setFieldValue, submitForm, values } = this.props.formikProps;
    let errorKey = null;
    if (this.hasDuplicateInternalName(values)) {
      // toast.error("Duplicate Internal Name");
      errorKey = "xmm.portal.operations.form.duplicate_operation_name";
    } else if (!this.hasValidOpcode(values)) {
      // toast.error(
      //   "A Valid Op Code must be entered when 'Mandatory in Appt' is enabled. Please modify one of these settings."
      // );
      errorKey =
        "xmm.portal.operations.form.valid_opcode_required_for_mandatory_in_appt";
    }
    const { localeStrings } = this.context.appContext;
    if (errorKey) {
      this.updateStatusBox(localeStrings[errorKey], "error", false);
      return;
    }
    this.updateStatusBox(
      localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    gtmEvents.gtmTrackEvent("xmm.operations.save_operation_click");
    if (this.props.editOption === "add" && values.name === "") {
      setFieldValue("name", values.internalName);
      setTimeout(() => {
        submitForm();
      }, 0);
      return true;
    }
    return submitForm();
  };
  /* Validation rule between fields MandatoryInApp and opcode
   * @returns {true} if opcode is not empty or != "DONOTSEND" or != "OCM" text; when mandatory field is selected;
   */
  hasValidOpcode = operation => {
    const { dmsOpcode, mandatoryInAppt } = operation;
    let isVaild = true;
    if (formvalidator.isSelected(mandatoryInAppt)) {
      if (doesEmpty(dmsOpcode)) {
        isVaild = false;
      } else if (
        !doesEmpty(dmsOpcode) &&
        (dmsOpcode.toUpperCase() === "DONOTSEND" ||
          dmsOpcode.toUpperCase() === "OCM" ||
          dmsOpcode.toUpperCase() === "*OCM")
      ) {
        isVaild = false;
      }
    }
    return isVaild;
  };
  updateStatusBox(msg, type, autoClose) {
    this.props.formikProps.setStatus({ msg, type, autoClose });
  }
  getMakeOptions() {
    const { dealerCatalogs, makeVariantMap } = this.context.appContext;
    const { editOption, loadOperation } = this.props;
    if (editOption === "edit" && loadOperation.make === "ANY") {
      const dealerCatalog = makeVariantMap[loadOperation.make];
      if (!dealerCatalog) {
        return [NO_PRICING_ANY_CATALOG, ...dealerCatalogs];
      }
    }
    return dealerCatalogs;
  }
  closeTranslationsModal = () => {
    this.setState({ showTranslationModal: false });
  };
  isDeleteDisabled() {
    return (
      formvalidator.isFactoryOperation(this.props.loadOperation.parentId) ||
      this.props.editOption === "add" ||
      isSameValue(
        this.context.appContext.tellUsMoreServiceId,
        this.props.loadOperation.serviceId
      )
    );
  }
  renderInspectionItemsPopover = () => {
    const { localeStrings } = this.context.appContext;
    return (
      <div>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.common.inspection_items_tooltip"]}
        </p>
      </div>
    );
  };
  getInspectionItemProperties() {
    const { engageEnabled } = this.context.appContext;
    let inspectionItemOptions = [];
    let inspectionItemsEnabled = false;
    const { make, serviceCategoryId } = this.props.formikProps.values;
    if (make) {
      const {
        dealerMakeInspectionItems,
        dealerServiceCategoryInspectionItemMap
      } = this.state;
      const categoryInspectionItem =
        dealerServiceCategoryInspectionItemMap[serviceCategoryId.toString()];
      if (categoryInspectionItem) {
        inspectionItemsEnabled = false;
        inspectionItemOptions = [categoryInspectionItem];
      } else {
        inspectionItemsEnabled = true;
        inspectionItemOptions = dealerMakeInspectionItems;
      }
    }
    return { inspectionItemOptions, inspectionItemsEnabled, engageEnabled };
  }
  renderInspectionItemList(
    localeStrings,
    inspectionItemOptions,
    inspectionItemsEnabled,
    handleChange
  ) {
    return inspectionItemsEnabled ? (
      <Field
        name="serviceTag"
        placeholder={localeStrings["xmm.portal.common.select_inspection_item"]}
        component={StyledSearchableSelect}
        id="serviceTag"
        options={inspectionItemOptions}
        customevent={this.handleSearchableChange}
        onChange={handleChange}
        maxHeight={120}
      />
    ) : (
      <Tooltip
        tooltipContent={
          localeStrings["xmm.portal.common.category_inspection_item_tooltip"]
        }
        position="top"
      >
        <div>
          <Field
            name="serviceTag"
            placeholder={
              localeStrings["xmm.portal.common.select_inspection_item"]
            }
            component={StyledSearchableSelect}
            id="serviceTag"
            options={inspectionItemOptions}
            customevent={this.handleSearchableChange}
            onChange={handleChange}
            maxHeight={120}
            disabled={!inspectionItemsEnabled}
          />
        </div>
      </Tooltip>
    );
  }
  render() {
    const {
      values,
      errors,
      status,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      dirty,
      isSubmitting
    } = this.props.formikProps;

    const {
      engageEnabled,
      inspectionItemOptions,
      inspectionItemsEnabled
    } = this.getInspectionItemProperties();
    const inspectionItemsClassName = engageEnabled ? "" : "hide";
    const inspectionItemList = this.renderInspectionItemList(
      this.context.appContext.localeStrings,
      inspectionItemOptions,
      inspectionItemsEnabled,
      handleChange
    );

    const vehicleGroupClass =
      values.metaVehicleScope.toString() !== "0"
        ? //  ||
          // this.state.vehicleGroupOptions.length === 0
          "hide"
        : "";

    const vehicleGroup = {
      make: values.make, // in
      variant: values.variant, // in
      dealerCode: this.context.dealerCode, // in
      metaVehicleScope: values.metaVehicleScope, // in
      metaVehicleFilterId: toEmptyStringIfUndefined(values.metaVehicleFilterId) // in
    };

    const vehicleGroupSelector =
      values.metaVehicleScope.toString() === "0" ? (
        <VehicleGroupSelector
          ref="intervalSelectorRef"
          data={vehicleGroup}
          error={errors.metaVehicleFilterId}
          context={this.context.appContext}
          hideAllVehicles={true}
          autoLoad={true}
          onBlur={this.onBlurVehicleGroup}
          onChange={this.onChangeVehicleGroup}
        />
      ) : (
        ""
      );

    const storeHoursDialog = (
      <StoreHoursDialog
        ref="storeHoursDlgRef"
        serviceId={values.serviceId.toString()}
        serviceHoursCount={this.state.serviceHoursCount}
        title="Service Availability"
        titleId={"xmm.portal.operations.form.service.availability"}
        dialogHtmlId={"storeHoursDialog"}
        closeButtonLabelId={"xmm.portal.common.close_button"}
        show={this.state.storeHoursData.show}
        localeStrings={this.context.localeStrings}
        doClose={event => {
          if (
            this.context.discardUnsavedChanges(
              event,
              this.closeStoreHoursDialog,
              this.refs.storeHoursDlgRef.isDirty
            )
          ) {
            this.closeStoreHoursDialog();
          }
        }}
      />
    );

    const findOpCodesModal = (
      <FindOpCodesDialog
        showValidateCatalog={true}
        dealerCode={this.context.dealerCode}
        serviceId={this.props.formikProps.values.serviceId.toString()}
        internalName={this.props.formikProps.values.internalName}
        dmsOpcode={this.props.formikProps.values.dmsOpcode}
        dmsDescription={this.props.formikProps.values.currentOpcodeDescription}
        localeStrings={this.context.localeStrings}
        manualOpcodes={this.context.appContext.manualOpcodes}
        show={this.state.showOpCodeModal}
        closeDialog={this.closeOpcodeModal}
        setManualOpcodes={this.context.appContext.setManualOpcodes}
        setOpcodeValue={fieldValue => {
          this.props.formikProps.setFieldValue("dmsOpcode", fieldValue);
        }}
      />
    );

    const modalTitle = `< ${this.translationLabel} - ${values.internalName}`;
    const translationModal = (
      <FullPageModal
        htmlId="translationFullModal"
        header={
          <div className="xmm-main-title">
            <h3>
              <Button buttonStyle="link" onClick={this.closeTranslationsModal}>
                {modalTitle}
              </Button>
            </h3>
          </div>
        }
        show={this.state.showTranslationModal}
        onHide={this.closeTranslationsModal}
        closeButton={true}
      >
        <div>
          <TranslationPage
            type="OPERATION"
            name={toEmptyStringIfUndefined(values.internalName)}
            id={toEmptyStringIfUndefined(values.serviceId)}
          />
        </div>
      </FullPageModal>
    );

    const { serviceKinds, localeStrings } = this.context;
    const {
      hasNonbaseLocale,
      serviceCategories,
      tellUsMoreServiceId
    } = this.context.appContext;
    const makeOptions = this.getMakeOptions();
    const { isFactoryOperation, serviceHoursCount } = this.state;
    const storeHoursDislay = this.getStoreHoursDisplay(serviceHoursCount);
    const {
      isNameDealerUpdateable,
      isDescDealerUpdateable,
      isDurationDealerUpdateable
    } = this.props.loadOperation;
    /* These fields {serviceKind, seriveCategory, Make, Name}are editable for dealer-created-operation */
    const getKinds = () => {
      const filteredOptions = serviceKinds.filter(
        value =>
          (this.props.editOption === "edit" &&
            (isFactoryOperation ||
              (value === "repair" || value === "maintenance"))) ||
          (this.props.editOption === "add" &&
            (value === "repair" || value === "maintenance"))
      );
      const options = filteredOptions.map(value => {
        return {
          value,
          label: value.charAt(0).toUpperCase() + value.slice(1)
        };
      });
      return options;
    };

    let post = <p style={{ textAlign: "center" }}> default msg!</p>;
    let editSection = null;

    // Name, desc, duration, opcode are editable for factory-created ops based on these flags
    const nameDisabled = !isNameDealerUpdateable;
    const descriptionDisabled = !isDescDealerUpdateable;
    const durationDisabled = !isDurationDealerUpdateable;
    const namePlaceholder =
      this.props.editOption === "add" ? values.internalName : "";
    const translatable =
      this.props.editOption === "edit" &&
      (!nameDisabled ||
        !isFactoryOperation ||
        !(
          descriptionDisabled || doesEmpty(this.props.loadOperation.description)
        ));
    const translationButtonClass =
      hasNonbaseLocale && translatable ? "" : "hide";

    // Check if operation related to tellUsMore
    const hasTellUsMore =
      tellUsMoreServiceId &&
      isSameValue(this.props.loadOperation.serviceId, tellUsMoreServiceId);
    const badgeTellUsMore = hasTellUsMore ? (
      <Badge htmlId="tellusBadge" color="blue" className="">
        {xlate("xmm.portal.common.tellusmore_lbl")}
      </Badge>
    ) : null;
    const clsFieldMask = hasTellUsMore ? "xmm-mask" : "";

    const deleteDisabled = this.isDeleteDisabled();
    if (this.props.editForm) {
      let saveDisable = true;
      if (
        isValid &&
        Object.keys(errors).length === 0 &&
        dirty &&
        !isSubmitting
      ) {
        saveDisable = false;
      }
      // set up status box
      const statusBox =
        status && status.msg ? (
          <div className="pull-left">
            <StatusBox
              htmlId="statusBox"
              type={status.type}
              autoClose={status.autoClose}
              linkHtml={null}
              message={status.msg}
              autoCloseTime={2500}
              animated={false}
            />
          </div>
        ) : (
          ""
        );

      const inspectionItemsPopover = this.renderInspectionItemsPopover();

      // show form when edit clicked or add clicked
      editSection = (
        <React.Fragment>
          {storeHoursDialog}
          {findOpCodesModal}
          {translationModal}
          <div className="xmm-tab-bar">
            {/* status && status.msg && (
              <span className="xmm-msg">{status.msg}</span>
            ) */}
            {statusBox}
            <Button
              htmlId="editTranslations"
              className={translationButtonClass}
              size="small"
              form="opsForm"
              // buttonStyle="primary"
              disabled={!translatable}
              onClick={() => {
                this.setState({ showTranslationModal: true });
              }}
            >
              {
                this.context.localeStrings[
                  "xmm.portal.translation.edit_translations"
                ]
              }
            </Button>{" "}
            <Button
              htmlId="saveOperationAction"
              className=""
              size="small"
              form="opsForm"
              buttonStyle="primary"
              disabled={saveDisable}
              onClick={this.handleSave}
            >
              {this.context.localeStrings["xmm.portal.common.save_button"]}
            </Button>
            <DropdownButton
              title={<IconMore />}
              id="operationsActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              pullRight
            >
              <DropdownMenuItem
                htmlId="singleDeleteBtn"
                eventKey={{ eventKey: ["single-delete"] }}
                disabled={deleteDisabled}
                onSelect={() => {
                  window.dispatchEvent(
                    new CustomEvent("deleteSingleOperation", {
                      detail: { singleOperation: this.props.loadOperation },
                      bubbles: true,
                      cancelable: true
                    })
                  );
                }}
              >
                <FormattedMessage
                  defaultMessage="Delete"
                  id="xmm.portal.common.delete_button"
                />
              </DropdownMenuItem>
            </DropdownButton>
          </div>
          <form id="opsForm" autoComplete="off" onSubmit={handleSubmit}>
            <div className="xmm-slider-tab-scroll comment-form">
              <Card
                header={
                  localeStrings["xmm.portal.operations.form.details_label"]
                }
                htmlId="detailsGroup"
              >
                <Grid htmlId="settingGrid">
                  <Row className="show-grid">
                    <Col xs={3} md={4}>
                      <span className="float-right">
                        {localeStrings["xmm.portal.common.internal_name"]}{" "}
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={9} md={8}>
                      <TextInput
                        htmlId="internalName"
                        label="Internal Name"
                        name="internalName"
                        maxLength="512"
                        onBlur={handleBlur}
                        onChange={this.onChange}
                        disabled={
                          (isFactoryOperation &&
                            this.props.editOption === "edit") ||
                          hasTellUsMore
                        }
                        value={values.internalName || ""}
                        error={
                          touched.internalName && errors.internalName
                            ? errors.internalName
                            : ""
                        }
                        displayLabel={false}
                      />
                    </Col>
                    <Col xs={3} md={4}>
                      <span className="float-right">
                        {localeStrings["xmm.portal.common.name_lbl"]}{" "}
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={9} md={8}>
                      <TextInput
                        htmlId="name"
                        label="Name"
                        name="name"
                        maxLength="512"
                        className={
                          errors.name && touched.name ? "xmm-error-input" : ""
                        }
                        onBlur={handleBlur}
                        onChange={this.onChange}
                        disabled={
                          (nameDisabled && this.props.editOption === "edit") ||
                          hasTellUsMore
                        }
                        value={values.name || ""}
                        placeholder={namePlaceholder}
                        displayLabel={false}
                        error={errors.name && touched.name ? errors.name : ""}
                      />
                    </Col>
                    <Col xs={3} md={4}>
                      <span className="float-right">
                        {localeStrings["xmm.portal.common.description_lbl"]}
                      </span>
                    </Col>
                    <Col xs={9} md={8}>
                      <TextArea
                        htmlId="description"
                        label="Summary"
                        maxLength={4000}
                        name="description"
                        className={
                          errors.description && touched.description
                            ? "xmm-error-input"
                            : ""
                        }
                        onBlur={handleBlur}
                        onChange={this.onChange}
                        disabled={
                          descriptionDisabled &&
                          this.props.editOption === "edit"
                        }
                        value={values.description || ""}
                        displayLabel={false}
                        layout="horizontal"
                        error={
                          errors.description && touched.description
                            ? errors.description
                            : ""
                        }
                      />
                    </Col>
                    <Col xs={6} md={8} className="full-col">
                      <Col xs={6} md={6}>
                        <span className="float-right">
                          {localeStrings["xmm.portal.common.make_lbl"]}{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <Field
                          name="make"
                          placeholder={
                            localeStrings["xmm.portal.common.select_a_make_lbl"]
                          }
                          // validate={formvalidator.validateNullValue}
                          component={StyledSearchableSelect}
                          id="make"
                          disabled={
                            // isFactoryOperation &&
                            this.props.editOption === "edit" || hasTellUsMore
                          }
                          options={makeOptions}
                          customevent={this.handleSearchableChange}
                          onChange={handleChange}
                        />
                        {errors.make && touched.make && (
                          <FieldError error={errors.make} />
                        )}
                      </Col>
                      <Col xs={6} md={6}>
                        <span className="float-right">
                          {localeStrings["xmm.portal.common.service_kind"]}{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <Field
                          name="serviceKind"
                          placeholder="Select"
                          component={StyledSelect}
                          id="serviceKind"
                          label="Service Kind"
                          disabled={
                            (isFactoryOperation &&
                              this.props.editOption === "edit") ||
                            hasTellUsMore
                          }
                          options={getKinds()}
                          customevent={this.handleSelectChange}
                          onChange={value =>
                            console.log("Kind Field level change event", value)
                          }
                        />
                        {errors.serviceKind && touched.serviceKind && (
                          <FieldError error={errors.serviceKind} />
                        )}
                      </Col>
                      <Col xs={6} md={6}>
                        <span className="float-right">
                          {localeStrings["xmm.portal.common.category_lbl"]}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <Field
                          name="serviceCategoryId"
                          placeholder={
                            localeStrings[
                              "xmm.portal.common.select_service_category_lbl"
                            ]
                          }
                          component={StyledSearchableSelect}
                          id="serviceCategoryId"
                          disabled={
                            isFactoryOperation &&
                            this.props.editOption === "edit"
                          }
                          options={serviceCategories}
                          customevent={this.handleSearchableChange}
                          onChange={handleChange}
                        />
                        {errors.serviceCategoryId &&
                          touched.serviceCategoryId && (
                            <FieldError error={errors.serviceCategoryId} />
                          )}
                      </Col>

                      <Col xs={6} md={6}>
                        <span className="float-right">
                          {
                            localeStrings[
                              "xmm.portal.common.schedule_duration_lbl"
                            ]
                          }
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <Field name="shopDuration" id="shopDuration">
                          {({ field }) => (
                            <NumericInput
                              htmlId="shopDuration"
                              label="Scheduling Duration"
                              name="shopDuration"
                              value={toEmptyStringIfUndefined(
                                values.shopDuration
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputSuffix={
                                localeStrings["xmm.portal.common.minutes"]
                              }
                              className={
                                errors.shopDuration &&
                                touched["shopDuration-maskedInput"]
                                  ? "xmm-error-input"
                                  : ""
                              }
                              disabled={
                                durationDisabled &&
                                this.props.editOption === "edit"
                              }
                              displayLabel={false}
                              maxLength={4}
                              minLength={0}
                              {...field}
                            />
                          )}
                        </Field>
                        {errors.shopDuration &&
                          touched["shopDuration-maskedInput"] && (
                            <FieldError error={errors.shopDuration} />
                          )}
                      </Col>
                      <Col xs={6} md={6}>
                        <span className="float-right">
                          {localeStrings["xmm.portal.common.rank"]}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <Field name="rank">
                          {({ field }) => (
                            <NumericInput
                              htmlId="rank"
                              label="Rank"
                              name="rank"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              displayLabel={false}
                              value={toEmptyStringIfUndefined(values.rank)}
                              className={
                                errors.rank && touched["rank-maskedInput"]
                                  ? "xmm-error-input"
                                  : ""
                              }
                              maxLength={5}
                              minLength={0}
                              {...field}
                            />
                          )}
                        </Field>
                        {errors.rank && touched["rank-maskedInput"] && (
                          <FieldError error={errors.rank} />
                        )}
                      </Col>

                      <Col xs={6} md={6}>
                        <span className="float-right">
                          {localeStrings["xmm.portal.grid.opcode"]}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <TextInput
                          htmlId="dmsOpcode"
                          label="Op Code"
                          name="dmsOpcode"
                          maxLength="50"
                          onBlur={handleBlur}
                          onChange={this.onChange}
                          value={values.dmsOpcode || ""}
                          displayLabel={false}
                          error={
                            errors.dmsOpcode && touched["dmsOpcode"]
                              ? errors.dmsOpcode
                              : ""
                          }
                        >
                          <TextInput.Addon
                            addonType="button"
                            htmlId="dmsOpcodeAppendButton"
                            onClick={this.handleOpenOpcodeModal}
                            className="btn--icon"
                          >
                            <IconSearch htmlId="iconSearchForm" />
                          </TextInput.Addon>
                        </TextInput>
                      </Col>
                    </Col>
                    <Col xs={6} md={4}>
                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <Field
                          type="checkbox"
                          name="loanerAllowed"
                          id="loanerAllowed"
                          className="form-checkbox"
                          checked={formvalidator.isSelected(
                            values.loanerAllowed
                          )}
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            localeStrings[
                              "xmm.portal.operations.form.loaners_label"
                            ]
                          }
                        </span>
                      </div>

                      {errors.loanerAllowed && touched.loanerAllowed && (
                        <FieldError error={errors.loanerAllowed} />
                      )}

                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <Field
                          type="checkbox"
                          name="waiterAllowed"
                          id="waiterAllowed"
                          className="form-checkbox"
                          checked={formvalidator.isSelected(
                            values.waiterAllowed
                          )}
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            localeStrings[
                              "xmm.portal.operations.form.waiters_label"
                            ]
                          }
                        </span>
                      </div>

                      {errors.waiterAllowed && touched.waiterAllowed && (
                        <FieldError error={errors.waiterAllowed} />
                      )}
                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <Field
                          type="checkbox"
                          className={"form-checkbox"}
                          name="mandatoryInAppt"
                          id="mandatoryInAppt"
                          disabled={hasTellUsMore}
                          checked={formvalidator.isSelected(
                            values.mandatoryInAppt
                          )}
                        />
                        <span className="xmm-checkmark" />
                        <span className={"label-checkbox " + clsFieldMask}>
                          {
                            localeStrings[
                              "xmm.portal.operations.form.mandatory_label"
                            ]
                          }
                        </span>
                      </div>

                      {errors.mandatoryInAppt && touched.mandatoryInAppt && (
                        <FieldError error={errors.mandatoryInAppt} />
                      )}
                      <div
                        className={
                          values.serviceKind === "repair"
                            ? "xmm-checkbox-container xmm-horizontal-form"
                            : "xmm-checkbox-container-hidden"
                        }
                      >
                        <Field
                          type="checkbox"
                          name="commentsRequired"
                          id="commentsRequired"
                          className="form-checkbox"
                          checked={formvalidator.isSelected(
                            values.commentsRequired
                          )}
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            localeStrings[
                              "xmm.portal.operations.form.comments_req_label"
                            ]
                          }
                        </span>
                      </div>

                      {errors.commentsRequired && touched.commentsRequired && (
                        <FieldError error={errors.commentsRequired} />
                      )}
                    </Col>
                  </Row>
                </Grid>
              </Card>
              <AccordionGroup.Container htmlId="settingsAccordion" independent>
                <AccordionGroup.Item
                  header={
                    localeStrings[
                      "xmm.portal.operations.form.availability_label"
                    ]
                  }
                  eventKey="2"
                  htmlId="availabilityGroup"
                  defaultExpanded
                  className="xmm-vehiclegroup-accordion"
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                >
                  <Grid htmlId="availableGrid">
                    <Row>
                      <Col xs={3} md={4}>
                        <span className="float-right">
                          {
                            localeStrings[
                              "xmm.portal.operations.form.vehicles_see_label"
                            ]
                          }
                        </span>
                      </Col>
                      <Col xs={3} md={4}>
                        <Field
                          name="metaVehicleScope"
                          placeholder="Select"
                          component={StyledSelect}
                          id="metaVehicleScope"
                          disabled={values.make === ""}
                          options={this.state.metaVehicleScopeOptions}
                          customevent={this.handleSelectChange}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} md={4}>
                        <div className={vehicleGroupClass}>
                          {vehicleGroupSelector}
                        </div>
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={3} md={4}>
                        <span className="float-right">
                          {
                            localeStrings[
                              "xmm.portal.operations.scheduled_menus_label"
                            ]
                          }
                        </span>
                      </Col>
                      <Col xs={3} md={4}>
                        <label className="xmm-form-label">
                          {this.state.contentEnabledMenus}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={4}>
                        <span className="float-right">
                          {
                            localeStrings[
                              "xmm.portal.operations.apps_display_label"
                            ]
                          }
                          <br />
                        </span>
                      </Col>
                      <Col xs={3} md={4}>
                        <Field
                          name="enabled" // bind with valid datamodel key for formik field
                          placeholder="Select"
                          component={StyledSelect}
                          id="appVisibility"
                          customValue={this.state.appVisibility} // use this props in StyledSelect to set value
                          disabled={false}
                          options={ApplicationVisibilityOptions}
                          customevent={this.onAppVisibilityChange}
                          onChange={
                            // value => formProps.field.onChange(formProps.field.name)(value)
                            value =>
                              console.log(
                                "Visibility Field level change event",
                                value
                              )
                          }
                        />
                      </Col>
                      <Col xs={3} md={4}>
                        {badgeTellUsMore}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={4}>
                        <span className="float-right">
                          {
                            localeStrings[
                              "xmm.portal.operations.form.scheduled_hours_label"
                            ]
                          }
                        </span>
                      </Col>
                      <Col
                        htmlId="editServiceHours"
                        xs={3}
                        md={4}
                        onClick={event => {
                          if (this.props.editOption !== "add") {
                            this.trackEditHours();
                            this.setState({
                              storeHoursData: {
                                ...this.state.storeHoursData,
                                show: true
                              }
                            });
                          }
                        }}
                        // disabled={this.props.editOption === "add"}
                      >
                        <TextInput
                          // addonPosition="append"
                          htmlId="serviceHoursCount"
                          className="selector-edit-hours"
                          displayLabel={false}
                          name="serviceHoursCount"
                          onChange={event => {
                            // noop - making the field readonly
                          }}
                          value={storeHoursDislay}
                          disabled={this.props.editOption === "add"}
                        >
                          <Button
                            buttonStyle="primary"
                            htmlId="editStoreHours"
                            // disabled={this.props.editOption === "add"}
                          >
                            <FormattedMessage
                              defaultMessage="Edit"
                              id="xmm.portal.common.edit_button"
                            />
                          </Button>
                        </TextInput>
                      </Col>
                    </Row>
                    <Row className={inspectionItemsClassName}>
                      <Col xs={3} md={4}>
                        <span className="float-right">
                          {localeStrings["xmm.portal.common.inspection_items"]}
                        </span>
                      </Col>
                      <Col xs={3} md={5}>
                        {inspectionItemList}
                      </Col>
                      <Col xs={2} md={1}>
                        <Popover
                          htmlId="popoverOverrides"
                          popoverContent={inspectionItemsPopover}
                          trigger={["click", "outsideClick"]}
                          position="top"
                        >
                          <IconInfoOutline
                            htmlId="inspectionItemtooltip"
                            className="hand-cursor"
                          />
                        </Popover>
                      </Col>
                    </Row>
                  </Grid>
                </AccordionGroup.Item>
                <AccordionGroup.Item
                  header={
                    localeStrings[
                      "xmm.portal.operations.form.advanced_settings"
                    ]
                  }
                  eventKey="3"
                  htmlId="advancedGroup"
                  hidden={true}
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                >
                  {/* <Grid htmlId="advanceGrid">
                    <Row className="show-grid">
                      <Col xs={3} md={4}>
                        <span className="float-right">
                          Manage Vehicle groups
                        </span>
                      </Col>
                      <Col xs={4} md={4}>
                        <SelectInput
                          htmlId="metaVehicleScopeTest"
                          placeholder="Select"
                          className=""
                          displayLabel={false}
                          displayPlaceholder={false}
                          maxHeight={100}
                          name="metaVehicleScopeTest"
                          label=""
                          required
                          disabled={false}
                          value={"1"}
                          options={objectToArrayOfLabelValue(
                            MetaVehicleScopeMap
                          )}
                        />
                        <Button
                          htmlId="editRuleBtn"
                          onClick={this.openManageVGroupsModal}
                          className="float-right"
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                  </Grid> */}
                </AccordionGroup.Item>
                {/* <AccordionGroup.Item
                  header="Form Props"
                  eventKey="4"
                  htmlId="formikGroup"
                  onToggle={() => {}}
                >
                  <DisplayFormikState {...this.props.formikProps} />
                </AccordionGroup.Item> */}
              </AccordionGroup.Container>
            </div>
          </form>
        </React.Fragment>
      );
    }
    if (this.props.loadOperation) {
      post = <div className="">{editSection}</div>;
    }
    return post;
  }
}

export default OperationFormNew;

OperationFormNew.propTypes = {
  loadOperation: PropTypes.object,
  editForm: bool,
  editOption: string,
  formikProps: PropTypes.object,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  status: PropTypes.object,
  handleBlur: PropTypes.func,
  setTouched: PropTypes.func,
  dirty: bool,
  isValid: bool,
  isSubmitting: bool
};

// const MVC_CHOOSE_VEHICLES = "0";

/* eslint-enable no-console */
