/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Button from "@cx/ui/Button";
import Col from "@cx/ui/Col";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import IconMore from "@cx/ui/Icons/IconMore";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { isArrayExist, doesEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import {
  blankValueFormatter,
  priceFormatter
} from "../../../../commonUtil/utils/formatter";
// import { priceValueSetter } from "../../../../commonUtil/utils/valuesetter";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import TextEditor from "../../../../commonUtil/editors/TextEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { LaborRateRecord } from "../../../../constants/ModuleConstants";
import AddLaborRateFormPage from "./content/AddLaborRateFormPage";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import LaborRateCodeEditor from "./content/LaborRateCodeEditor";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import { sortByMake } from "../../../../commonUtil/utils/filter";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import * as formvalidator from "../../../formik/formvalidator";
class LaborRatesPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    // bind other external actions
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.confirmDeleteLaborRate = this.confirmDeleteLaborRate.bind(this);
    this.deleteLaborRate = this.deleteLaborRate.bind(this);
    this.renderMoreButton = this.renderMoreButton.bind(this);
    this.updateLaborRateAfterSave = this.updateLaborRateAfterSave.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.textCellEditorParams = this.textCellEditorParams.bind(this);
    const { localeStrings } = context;
    this.initializeLocaleValues();
    const validationFields = [
      "description",
      "unpublishedPackageRate",
      "unpublishedStandaloneRate"
    ];
    const editableFields = [
      "description",
      "unpublishedPackageRate",
      "unpublishedStandaloneRate"
    ];
    const gridOptions = {
      // other state props
      searchKey: context.laborRatesGrid.searchKey,
      laborRatesGrid: context.laborRatesGrid,
      pageTitle: this.pageTitle,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      rowRecord: LaborRateRecord,
      sliderWidth: 500,
      flexWidth: false,
      showSlide: false,
      // ag-grid props
      services: null, // should be null - fix to skip "No records found" msg on grid load.
      floatingFilter: false, // true - enable column header filters
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(validationFields, editableFields, []),
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        laborRateCodeEditor: LaborRateCodeEditor,
        textEditor: TextEditor,
        priceEditor: PriceEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      //   totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            nullComparator: {
              equals: false,
              lessThan: false,
              greaterThan: false
            },
            clearButton: true
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "single",
      isRowSelectable(rowNode) {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {}
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
  }
  initializeLocaleValues() {
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.addRateLabel = xlate("xmm.portal.laborrate.add_rate_lbl");
    this.errorLabel = xlate(
      "xmm.portal.errors.miissing_labor_rate_code_or_description"
    );
    this.pageTitle = xlate("xmm.portal.nav.labor_rates");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const laborRatesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        sortState: this.gridApi.getSortModel(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        laborRatesGrid
      });
      this.context.setLaborRatesGridState(laborRatesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const {
      colState,
      filterState,
      sortState,
      pivotState,
      searchKey
    } = this.state.laborRatesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      this.gridApi.setSortModel(sortState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        prevState => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerLaborRateCodeId; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  updateState(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      this.setState(
        {
          services: datalist
        },
        () => {
          this.sizeToFit();
          this.restoreGridState();
        }
      );
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const restUrl = "/ops/proxyapi/ddsproxy/rest/table/dealerLaborRateCode";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          const filterList = data.filter(function(obj) {
            return obj.pricingMethod === 1;
          });
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(filterList);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sort: "asc"
      },
      {
        colId: "name",
        sort: "asc"
      }
    ];
    this.gridApi && this.gridApi.setSortModel(defaultSortModel);
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function() {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent(params) {
    // do nothing for now
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    // console.log("onCellValueChanged", params.oldValue, params.newValue, params);
    const field = params.colDef.field;
    const datalist = this.state.services;
    if (
      toEmptyStringIfUndefined(params.oldValue) ===
      toEmptyStringIfUndefined(params.newValue)
    ) {
      return;
    }
    if (
      doesEmpty(params.data.laborRateCode) ||
      doesEmpty(params.data.description)
    ) {
      // params.newValue = params.oldValue;
      // params.value = params.oldValue;
      // params.data[field] = params.oldValue;
      this.setFieldValidation(
        params.data,
        field,
        "xmm.portal.errors.miissing_labor_rate_code_or_description"
      );
      return;
    }
    let count = 0;
    if (field === "laborRateCode" || field === "description") {
      let i;
      for (i = 0; i < datalist.length; i++) {
        const s = datalist[i];
        if (
          s.make === params.data.make &&
          (s.laborRateCode.toUpperCase() === params.newValue.toUpperCase() ||
            s.description.toUpperCase() === params.newValue.toUpperCase())
        ) {
          count++;
          if (count > 1) {
            // console.log("Found duplicates", params.data.make, params.newValue);
            // params.newValue = params.oldValue;
            // params.value = params.oldValue;
            // params.data[field] = params.oldValue;
            this.setFieldValidation(
              params.data,
              field,
              "xmm.portal.errors.miissing_labor_rate_code_or_description"
            );
            return;
          }
        }
      }
    }
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (
        params.colDef.field === "description" ||
        params.colDef.field === "unpublishedPackageRate" ||
        params.colDef.field === "unpublishedStandaloneRate"
      ) {
        if (this.validateField(params)) {
          this.clearFieldValidation(params);
          this.onSaveCellEdit(params);
        }
        return;
      } else {
        // DO Nothing
      }
    }
  }
  onSaveCellEdit(params) {
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  savePayload(gridParams, value) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const record = gridParams.data;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborRateCode/${
      record.dealerLaborRateCodeId
    }?_method=put`;
    const payload = this.getCellEditPayload(record, value);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        if (!response.success) {
          this.updateStatusBox(this.saveErrorMsg, "error", false, true);
        } else {
          this.updateStatusBox(this.savedMsg, "success", true);
          const { dealerLaborRateCode } = response;
          this.context.updateDealerLaborRates("edit", dealerLaborRateCode);
          const { field } = gridParams.colDef;
          this.refreshCell(record, field);
        }
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  }
  confirmDeleteLaborRate(dealerLaborCode) {
    this.setState({ dealerLaborCode, showDeleteConfirmation: true });
  }
  deleteLaborRate(dealerLaborRateCode) {
    this.updateStatusBox(this.deletingMsg, "pending", false);
    const { dealerLaborRateCodeId } = dealerLaborRateCode;
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/proc/deleteLaborRateCode`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: { dealerLaborRateCodeId }
      },
      response => {
        if (response[0].retVal !== 0) {
          this.updateStatusBox(this.deletingErrorMsg, "error", false, true);
        } else {
          const res = this.gridApi.updateRowData({
            remove: [dealerLaborRateCode]
          });
          res.remove.forEach(function(rowNode) {
            console.log("Removed Row Node", rowNode);
          });
          this.updateStatusBox(this.deletedMsg, "success", true);
          this.context.updateDealerLaborRates("delete", dealerLaborRateCode);
          // GTM - push click event to dataLayer
          gtmEvents.gtmTrackEvent("xmm.laborrates.delete_rate_click");
        }
        this.closeDeleteModal();
      },
      error => {
        this.closeDeleteModal();
        const msg = error["message"] ? error.message : this.deletingErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  getCellEditPayload(record, value) {
    let payload = {};

    payload = {
      dealerCode: record.dealerCode,
      make: record.make,
      laborRateCodeId: record.laborRateCodeId,
      laborRateCode: record.laborRateCode,
      description: record.description,
      unpublishedPackageRate: record.unpublishedPackageRate,
      unpublishedStandaloneRate: record.unpublishedStandaloneRate
    };

    return payload;
  }
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#laborrate-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.setState({
        searchKey
      });
    }
  };
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "make",
        sortingOrder: ["asc", "desc"],
        maxWidth: 150,
        minWidth: 100,
        editable: false,
        valueFormatter(params) {
          return params.value;
        },
        filter: "agSetColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          clearButton: true,
          comparator: sortByMake
        }
      },
      {
        headerName: localeStrings["xmm.portal.parts_labor.grid_code"],
        field: "laborRateCode",
        // eslint-disable-next-line no-constant-condition
        editable: params => {
          return params.data["laborRateCodeId"] == null;
        },
        cellEditor: "textEditor",
        cellClass: params => {
          if (params.data["laborRateCodeId"] === null) {
            return "editable-cell";
          } else {
            return "xmm-wrap-cell";
          }
        },
        tooltipField: "Labor Rate Code",
        tooltipComponentParams: { field: "laborRateCode" },
        width: 120,
        sortingOrder: ["asc", "desc"],
        valueFormatter: blankValueFormatter,
        filter: "agSetColumnFilter",
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.description"],
        field: "description",
        editable: true,
        cellEditor: "textEditor",
        cellEditorParams: this.textCellEditorParams,
        cellClass: "editable-cell",
        tooltipField: "description",
        tooltipComponentParams: { field: "description" },
        enableRowGroup: false,
        valueFormatter: blankValueFormatter,
        width: 420,
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: localeStrings["xmm.portal.parts_labor.grid_package_rate"],
        field: "unpublishedPackageRate",
        colId: "unpublishedPackageRate",
        editable: true,
        headerClass: "ag-numeric-header",
        cellEditor: "priceEditor",
        cellEditorParams: { keepInvalidValue: true },
        cellClass: "editable-cell",
        cellStyle: { "text-align": "right" },
        tooltipField: "unpublishedPackageRate",
        tooltipComponentParams: { field: "unpublishedPackageRate" },
        enableRowGroup: false,
        // valueGetter(params) {
        //   return params.data.unpublishedPackageRate;
        // },
        valueFormatter: priceFormatter,
        // valueSetter: priceValueSetter,
        type: "numberColumn"
        // width: 150
      },
      {
        headerName:
          localeStrings["xmm.portal.parts_labor.grid_a_la_carte_rate"],
        field: "unpublishedStandaloneRate",
        colId: "unpublishedStandaloneRate",
        // valueGetter(params) {
        //   return params.data.unpublishedStandaloneRate;
        // },
        valueFormatter: priceFormatter,
        // valueSetter: priceValueSetter,
        editable: true,
        headerClass: "ag-numeric-header",
        cellEditor: "priceEditor",
        cellEditorParams: { keepInvalidValue: true },
        cellClass: "editable-cell",
        cellStyle: { "text-align": "right" },
        tooltipField: "unpublishedStandaloneRate",
        tooltipComponentParams: { field: "unpublishedStandaloneRate" },
        enableRowGroup: false,
        type: "numberColumn"
        // width: 150
      },
      {
        headerName: "",
        field: "",
        // tooltipField: "description",
        // tooltipComponentParams: { field: "description" },
        // cellClass: "xmm-wrap-cell",
        pinned: "right",
        editable: true,
        cellClass: "editable-cell",
        cellEditorSelector(params) {
          const { laborRateCodeId } = params.data;
          if (!laborRateCodeId) {
            return { component: "laborRateCodeEditor" };
          }
          params.api.stopEditing();
          return null;
        },
        cellEditorParams: {
          parentHandle: this
        },
        cellRendererFramework: this.renderMoreButton,
        enableRowGroup: false,
        maxWidth: 61,
        minWidth: 61,
        width: 61,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true
      }
    ];
    return baseCols;
  }
  textCellEditorParams(params) {
    const { newValue } = params;
    if (
      doesEmpty(newValue) ||
      formvalidator.validateAlphaNumPunctuation(newValue)
    ) {
      return { keepInvalidValue: true };
    }
    params.api.stopEditing();
    return null;
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerLaborRateCodeId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "description":
          if (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          ) {
            errorKey = "xmm.portal.errors.invalid_field";
          }
          break;
        case "unpublishedPackageRate":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        case "unpublishedStandaloneRate":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  // bind to add rate click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: LaborRateRecord,
      operationName: this.addRateLabel
    }));
  };
  handleRowSelected = event => {
    // console.log(
    //   `labor rate code id: ${event.node.data.laborRateCodeId} is selected=${
    //     event.node.selected
    //   }`,
    //   event.node
    // );
  };

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  hideSlider = event => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: LaborRateRecord,
      operationName: this.addRateLabel
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = event => {
    const callback = () => {
      this.setState({
        showSlide: false
      });
    };
    const isDirtyFunc = () => {
      return this.refs.laborRateFormRef.state.dirty;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showSlide: false
      });
    }
  };
  /* update Grid rows when labor rate added */
  updateLaborRateAfterSave = dealerLaborRateCode => {
    if (this.gridApi && dealerLaborRateCode) {
      const res = this.gridApi.updateRowData({
        add: [dealerLaborRateCode]
      });
      // get the row node with ID
      const rowNode = this.gridApi.getRowNode(
        dealerLaborRateCode.dealerLaborRateCodeId
      );
      this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
      rowNode.setSelected(false);
      const gridParams = {
        force: true,
        rowNodes: [rowNode]
      };
      const { services } = this.state;
      services.push(dealerLaborRateCode);
      this.setState({ services });
      this.context.updateDealerLaborRates("add", dealerLaborRateCode);
      this.callRefreshAfterMillis(gridParams, this.gridApi);
    }
  };
  renderMoreButton(params) {
    const { laborRateCodeId } = params.data;
    return !laborRateCodeId ? (
      <IconMore className="xmm-rule-dropdown-menu-renderer" />
    ) : (
      ""
    );
  }
  closeDeleteModal = () => {
    this.setState({ showDeleteConfirmation: false });
  };
  renderDeleteLaborCodeConfirmation() {
    const { dealerLaborCode, showDeleteConfirmation } = this.state;
    const { localeStrings } = this.context;
    return showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteMenuType"
        message={localeStrings["xmm.portal.menutype.delete_confirmation"]}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.deleteLaborRate(dealerLaborCode);
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  render() {
    const deleteModal = this.renderDeleteLaborCodeConfirmation();
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.services}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            floatingFilter={this.state.floatingFilter}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={50}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    const header = (
      <React.Fragment>
        <div>
          <Grid htmlId="pageHead">
            <Row className="show-grid">
              <Col xs={4} sm={4} className="full-col">
                <h3>{this.state.pageTitle}</h3>
              </Col>
              <Col xs={8} sm={8} className="full-col xmm-header-right">
                <div className="xmm-input-search float-right">
                  <input
                    type="text"
                    id="laborrate-search-box"
                    className="xmm-input"
                    placeholder={this.searchLabel}
                    onChange={this.onSearchBoxChanged}
                    value={this.state.searchKey}
                    autoComplete="off"
                  />
                </div>
                <Button
                  htmlId="addActionBtn"
                  disabled={this.state.disableAction}
                  onClick={this.addActionSlider}
                  className="float-right"
                >
                  {this.addRateLabel}
                </Button>
                <div className="float-right">{statusHtml}</div>
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
    // Add components within slider
    const modalTitle = <span>{this.state.operationName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
      >
        <div>
          <AddLaborRateFormPage
            key={"editSlider"}
            ref="laborRateFormRef"
            rowRecord={this.state.rowRecord}
            editForm={true}
            laborRates={this.state.services}
            editOption={this.state.editOption}
            hideSlider={this.hideSlider}
            updateLaborRateAfterSave={this.updateLaborRateAfterSave}
          />
        </div>
      </GenericSlider>
    );
    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {formSlider}
        {deleteModal}
      </div>
    );
  }
}

export default LaborRatesPage;

LaborRatesPage.propTypes = {};
