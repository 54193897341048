import { makeSecureRestApi } from "../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../commonUtil/utils/string";
import { isArrayExist } from "../../../commonUtil/utils/object";

export const MAX_VEHICLE_GROUP_NAME_LENGTH = 50;

export function createSets(value) {
  const list = !value ? [] : Array.isArray(value) ? value : [value];
  return list.map(elem => {
    const label = elem.content;
    const value = elem.id.toString();
    return { label, value };
  });
}

export function toArrayOfLabelAndValue(value) {
  const list = typeof value === "string" ? [value] : value;
  const labelAndValueList = !list
    ? []
    : list.map(value => {
        return { label: value.toString(), value: value.toString() };
      });
  return labelAndValueList;
}

export function findMakeVehicleGroupMap(
  metaVehicleFilter,
  sortedVehicleGroups
) {
  const list = sortedVehicleGroups.filter(map => {
    return map[metaVehicleFilter.make];
  });
  return list.length !== 0 ? list[0] : null;
}
export function removeItemFromMap(metaVehicleFilter, map) {
  const list = map[metaVehicleFilter.make];
  if (list) {
    const newList = list.filter(item => {
      return (
        item.metaVehicleFilterId.toString() !==
        metaVehicleFilter.metaVehicleFilterId.toString()
      );
    });
    map[metaVehicleFilter.make] = newList;
  }
}

export function createVehicleGroupOptions(makeVehicleGroupsMap, make) {
  const datalist = makeVehicleGroupsMap[make] || [];
  if (Array.isArray(datalist)) {
    const vehicleGroupOptions = datalist.map(v => {
      return {
        label: v.name,
        value: v.metaVehicleFilterId.toString()
      };
    });
    // console.log("make,vehicleGroupOptions", make, vehicleGroupOptions);
    return vehicleGroupOptions;
  }
  return [];
}
function createMakeVehicleGroupsMap(context, datalist) {
  const { makelist } = context;
  const makeVehicleGroupsMap = {}; // hashmap
  const dealerMakeVehiclesGroupMap = {};
  makelist.forEach(m => {
    dealerMakeVehiclesGroupMap[m.make] = [];
  });
  const variantMakeVehiclesGroupList = [];
  // build a hash map of key/value pair: key: make value: [item]
  datalist.forEach(item => {
    if (!item.name || item.name === "") {
      item.name = item.description;
      item.isUnnamed = true;
    } else {
      item.isUnnamed = false;
    }
    if (makeVehicleGroupsMap[item.make] === undefined) {
      makeVehicleGroupsMap[item.make] = [item];
    } else {
      makeVehicleGroupsMap[item.make].push(item);
    }
    if (item.dealerCode && item.dealerCode !== "") {
      if (dealerMakeVehiclesGroupMap[item.make] === undefined) {
        dealerMakeVehiclesGroupMap[item.make] = [item];
      } else {
        dealerMakeVehiclesGroupMap[item.make].push(item);
      }
    } else {
      variantMakeVehiclesGroupList.push(item);
    }
  });
  // put in the missing makes into the maps
  const makeKeys = Object.keys(makeVehicleGroupsMap);
  const missingMakes = makelist
    .filter(m => {
      return !makeKeys.includes(m.make);
    })
    .map(m1 => {
      return m1.make;
    });
  missingMakes.forEach(m => {
    makeVehicleGroupsMap[m] = [];
    dealerMakeVehiclesGroupMap[m] = [];
  });
  return {
    makeVehicleGroupsMap,
    dealerMakeVehiclesGroupMap,
    variantMakeVehiclesGroupList
  };
}

function createSortedVehicleGroupsByMake(makeVehicleGroupMap) {
  // console.log("hashmap groups", JSON.stringify(makeVehicleGroupObject, null, 2));
  // sort the make keys alphbatically
  const makeKeys = Object.keys(makeVehicleGroupMap);
  const sortMakeKeys = makeKeys.sort((a, b) => {
    let comparison = 0;
    if (a > b) {
      comparison = 1;
    } else if (a < b) {
      comparison = -1;
    }
    return comparison;
  });
  // build the one-dimensional array of objects (each object has key = make and value = [item])
  const sortedMakeVehicleGroupList = sortMakeKeys.map(key => {
    return { [key]: makeVehicleGroupMap[key] };
  });
  // console.log("createSortedVehicleGroupsByMake", sortedMakeVehicleGroupList);
  return sortedMakeVehicleGroupList;
}

// got all named and unnamed metaVehicleId
function getGroupNameListPart3(context, datalist) {
  const {
    makeVehicleGroupsMap,
    dealerMakeVehiclesGroupMap
  } = createMakeVehicleGroupsMap(context, datalist);
  const sortedVehicleGroups = createSortedVehicleGroupsByMake(
    dealerMakeVehiclesGroupMap
  );
  const { vehicleGroupCallbackParams } = context;
  const { vehicleGroupCallback } = vehicleGroupCallbackParams;
  vehicleGroupCallback(
    dealerMakeVehiclesGroupMap,
    makeVehicleGroupsMap,
    sortedVehicleGroups
  );
  // const vehicleGroupOptions = this.createVehicleGroupOptions(
  //   makeVehicleGroupsMap,
  //   this.props.data.make
  // );
  // this.setVehicleGroupName(makeVehicleGroupsMap);
  // this.setState({
  //   dealerMakeVehiclesGroupMap,
  //   makeVehicleGroupsMap,
  //   sortedVehicleGroups,
  //   vehicleGroupOptions,
  //   filteredVehicleGroupOptions: [...vehicleGroupOptions]
  // });
  // if (vehicleGroup && this.refs.manageVehGpModalRef) {
  //   this.refs.manageVehGpModalRef.setSelectedItem(vehicleGroup);
  // }
}

function getGroupNameListPart2a(metaVehicleFilterId, context, datalist) {
  const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/simpleMetaVehicleFilter`;
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "get",
      data: {},
      params: {
        metaVehicleFilterId
      }
    },
    response => {
      if (response && response.length > 0) {
        const metaVehicleFilter = response[0];
        const unnamedMetaVehicleFitler = {
          dealerCode: metaVehicleFilter.dealerCode,
          description: metaVehicleFilter.description,
          externallyManaged: 0,
          make: metaVehicleFilter.make,
          metaVehicleFilterId,
          name: toEmptyStringIfUndefined(metaVehicleFilter.name),
          variant: metaVehicleFilter.variant
        };
        datalist.push(unnamedMetaVehicleFitler);
        getGroupNameListPart3(context, datalist);
      }
    },
    error => {
      toast.error(error.message);
    }
  );
}

function getGroupNameListPart2(metaVehicleFilterId, context, datalist) {
  // const { metaVehicleFilterId } = this.state.data;
  if (metaVehicleFilterId && metaVehicleFilterId !== "") {
    const matchedItems = datalist.filter(f => {
      return !isDifferentValue(f.metaVehicleFilterId, metaVehicleFilterId);
    });
    if (matchedItems.length === 0) {
      // got unnamed metaVehicleFilterId
      getGroupNameListPart2a(metaVehicleFilterId, context, datalist);
    }
  }
  // got all named metaVehicleFilterId
  getGroupNameListPart3(context, datalist);
}

/* This call made get named filters for a dealercode
 */
export function getGroupNameList(metaVehicleFilterId, context) {
  const { dealerCode } = context;
  if (!dealerCode) {
    return;
  }
  const restEndPoint = "/ops/proxyapi/ddsproxy/rest/table/dealerNamedFilter";
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "get",
      data: {},
      params: {
        dealerCode,
        externallyManaged: 0
      }
    },
    response => {
      if (response) {
        const namelist = response;
        let datalist = [];
        if (!isArrayExist(namelist) && typeof namelist === "object") {
          datalist.push(namelist);
        } else if (isArrayExist(namelist) && namelist.length > 0) {
          datalist = namelist;
        }
        const { makelist } = context;
        const makeMap = {};
        makelist.forEach(m => {
          makeMap[m.make] = true;
        });
        const supportedMakeList = datalist.filter(d => {
          return makeMap[d.make];
        });
        // filter out unsupported makes
        getGroupNameListPart2(metaVehicleFilterId, context, supportedMakeList);
      }
    },
    error => {
      toast.error(error.message);
    }
  );
}
