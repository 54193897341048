/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React, { Component } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import CustomTooltip from "../../../../../commonUtil/components/reusable/CustomToolTip";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { array, func, string } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { toEmptyStringIfUndefined } from "../../../../../commonUtil/utils/string";
import { getAllVehicleGroupName } from "../../../../../commonUtil/utils/menu";
import { xlate } from "../../../../../commonUtil/i18n/locales";

/** Find Op Codes */
export default class ShowInheritedRulesModal extends Component {
  constructor(props) {
    super(props);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.findInheritedScheduleRules = this.findInheritedScheduleRules.bind(
      this
    );
    this.initializeLocaleValues();
    const gridOptions = {
      columnDefs: this.getColumnList(props.localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        suppressMenu: true,
        // width: 220,
        sortingOrder: ["asc", "desc", null],
        rowGroup: false,
        getQuickFilterText: params => {
          return params.value;
        }
      },
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      rowData: null,
      inheritedRules: null,
      frameworkComponents: {
        customTooltip: CustomTooltip,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        // loadingMessage: "No Inherited Rules",
        isLoading: false,
        noRows: true
      },

      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: this.filteredRowsLabel,
        selectedRows: this.selectedRowsLabel,
        totalRows: this.totalRowsLabel,
        totalAndFilteredRows: this.totalAndFilteredRowsLabel,
        noRowsToShow: this.noRowsToShowLabel
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      }
    };

    this.state = gridOptions;
  }
  initializeLocaleValues() {
    this.filteredRowsLabel = xlate("xmm.portal.ag_grid.filteredRows");
    this.selectedRowsLabel = xlate("xmm.portal.ag_grid.selectedRows");
    this.totalRowsLabel = xlate("xmm.portal.ag_grid.totalRows");
    this.totalAndFilteredRowsLabel = xlate(
      "xmm.portal.ag_grid.totalAndFilteredRows"
    );
    this.noRowsToShowLabel = xlate("xmm.portal.ag_grid.noRowsToShow");
    this.vehicleGroupHeader = xlate("xmm.portal.common.vehiclegroup");
    this.allVehiclesTmpl = xlate("xmm.portal.common.all_make_vehicles");
    this.intervalHeader = xlate("xmm.portal.common.interval");
    this.menuTypeHeader = xlate("xmm.portal.common.menutype");
    this.modalTitle = xlate(
      "xmm.portal.dealermenus.show_inherited_rules_title"
    );
    this.searchLabel = xlate("xmm.portal.common.search_label");
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.findInheritedScheduleRules();
    this.gridApi.closeToolPanel();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (this.gridApi) {
      const { value } = event.target;
      this.gridApi.setQuickFilter(value);
    }
  };

  getColumnList(localeStrings) {
    const { allVehiclesTmpl } = this;
    const baseCols = [
      {
        headerName: this.vehicleGroupHeader,
        field: "metaVehicleFilterDesc",
        autoHeight: true,
        cellClass: "xmm-wrap-cell",
        width: 424,
        valueFormatter(params) {
          const { make, metaVehicleFilterDesc } = params.data;
          if (toEmptyStringIfUndefined(metaVehicleFilterDesc) === "") {
            return getAllVehicleGroupName(
              toEmptyStringIfUndefined(make),
              allVehiclesTmpl
            );
          }
          return metaVehicleFilterDesc;
        }
      },
      {
        headerName: this.intervalHeader,
        field: "intervalName",
        autoHeight: true,
        cellClass: "xmm-wrap-cell"
      },
      {
        headerName: this.menuTypeHeader,
        field: "menuTypeName",
        autoHeight: true,
        cellClass: "xmm-wrap-cell"
      }
    ];
    return baseCols;
  }

  // Get Automatic scores
  findInheritedScheduleRules = () => {
    const { serviceId, menuTypeId } = this.props;
    const { locale } = this.context;
    showBodyMask();
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findInheritedScheduleRules",
        method: "get",
        params: { serviceId, menuTypeId, locale }
      },
      inheritedRules => {
        if (!inheritedRules || inheritedRules.length === 0) {
          this.gridApi && this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi && this.gridApi.hideOverlay();
        }
        this.setState({ inheritedRules }, () => {
          this.sizeToFit();
        });
        hideBodyMask();
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };

  render() {
    const { inheritedRules } = this.state;
    return (
      <div>
        <ModalDialog
          htmlId="showInheritedRulesModal"
          show={true}
          className="xmm-show-inherited-rules-modal"
          backdrop={"static"}
          animation={false}
          header={<ModalDialog.Title>{this.modalTitle}</ModalDialog.Title>}
          onHide={this.props.closeDialog}
        >
          <div className="xmm-input-search full-row">
            <input
              type="text"
              id="page-search-box"
              className="xmm-input"
              placeholder={this.searchLabel}
              onInput={this.onSearchBoxChanged}
              autoComplete="off"
            />
          </div>
          <div className="xmm-show-inherited-rules-container ag-theme-balham">
            <AgGridReact
              localeText={this.state.localeText}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              // enterMovesDownAfterEdit={true}
              // enterMovesDown={true}
              rowDeselection={true}
              isRowSelectable={this.state.isRowSelectable}
              // suppressRowClickSelection={true}
              suppressMenuHide={false}
              // suppressContextMenu={true}
              rowData={inheritedRules}
              onGridReady={this.onGridReady}
              onColumnResized={this.handleColumnResized}
              onSelectionChanged={this.handleSelectionChanged}
              frameworkComponents={this.state.frameworkComponents}
              loadingOverlayComponent={this.state.loadingOverlayComponent}
              loadingOverlayComponentParams={
                this.state.loadingOverlayComponentParams
              }
              noRowsOverlayComponent={this.state.noRowsOverlayComponent}
              noRowsOverlayComponentParams={
                this.state.noRowsOverlayComponentParams
              }
              animateRows={true}
              rowStyle={this.state.rowStyle}
              rowHeight={50}
              statusBar={this.state.statusBar}
              // onCellValueChanged={this.onCellValueChanged}
              // onCellClicked={this.onCellClickedEvent}
              enableRangeSelection={false}
              enableCharts={false}
              enableCellTextSelection={true}
              enableBrowserTooltips={false}
            />
          </div>
        </ModalDialog>
      </div>
    );
  }
}

ShowInheritedRulesModal.propTypes = {
  serviceId: string,
  menuTypeId: string,
  localeStrings: array,
  // show: bool,
  closeDialog: func
};
