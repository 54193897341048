/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { bool, func, string } from "prop-types";
import { FormattedMessage } from "react-intl";
export default class SimpleModalDialog extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.show !== prevState.show) {
      return {
        show: nextProps.show
      };
    }
    return null;
  }
  constructor(props) {
    super(props);

    this.state = {
      show: props.show
    };
  }

  render() {
    return (
      <div>
        <ModalDialog
          htmlId={this.props.dialogHtmlId}
          className="sample-modal-dialog"
          show={this.state.show}
          backdrop={"static"}
          header={
            <ModalDialog.Title>
              <FormattedMessage
                defaultMessage={this.props.title}
                id={this.props.titleId}
              />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <Button
                htmlId={this.props.actionHtmlId}
                onClick={this.props.doAction}
                text={this.props.actionButtonLabel}
                // buttonStyle="primary"
              >
                <FormattedMessage
                  defaultMessage={this.props.actionButtonLabel}
                  id={this.props.actionButtonLabelId}
                />
              </Button>
            </div>
          }
          onHide={this.props.doClose}
        >
          <div>
            <p>{this.props.message}</p>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

SimpleModalDialog.propTypes = {
  show: bool,
  // default labels
  title: string,
  message: string,
  actionButtonLabel: string,
  // html ids
  dialogHtmlId: string,
  actionHtmlId: string,
  // localization ids
  titleId: string,
  messageId: string,
  actionButtonLabelId: string,
  // action functions
  doAction: func,
  doClose: func
};
