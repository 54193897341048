/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { toast } from "@cx/ui/Toast";
import { getVehicleImage, makeSecureRestApi } from "../../../api/xmmAxios";
import {
  isEmpty,
  isArrayExist,
  isObject,
  doesEmpty
} from "../../../commonUtil/utils/object";
import {
  formatNumberWithThousands,
  upperCaseFirstLetter
} from "../../../commonUtil/utils/string";
import {
  convertMinutesToTenths,
  convertMinutesToHundredths,
  defaultToZeroIfNullOrEmpty
} from "../../../commonUtil/utils/value";
import { menuTypeComparator } from "../../../commonUtil/utils/list";
import PreviewTabs from "./PreviewTabs";
import ResultTabs from "./ResultTabs";
import VehicleDetails from "./content/VehicleDetails";
import { AppContext } from "../../../components/app-context";
import PreviewContext from "./preview-context";
import {
  getUserPreviewed,
  getUserFavs,
  addUserPreviewed
} from "./content/selectors/UserPrefs";
import * as gtmEvents from "../../utils/gtag-eventlist";
import { formatRegDate } from "../../../commonUtil/utils/date";

class PreviewModal extends Component {
  static propTypes = {
    show: PropTypes.bool
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.previewVehicle = this.previewVehicle.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.showChangeVehicle = this.showChangeVehicle.bind(this);
    this.updateVehicleHeader = this.updateVehicleHeader.bind(this);
    this.updateVehicleDetail = this.updateVehicleDetail.bind(this);
    this.updatePreviewMenus = this.updatePreviewMenus.bind(this);
    this.updateAlacarteResults = this.updateAlacarteResults.bind(this);
    this.updateMileages = this.updateMileages.bind(this);
    this.setPreviewTabs = this.setPreviewTabs.bind(this);
    this.showLoadingIndicator = this.showLoadingIndicator.bind(this);
    this.showVehiclesLoadingIndicator = this.showVehiclesLoadingIndicator.bind(
      this
    );
    // Read values from App context
    const { search } = context.preview;
    let hasContextPreview = false;
    hasContextPreview = !isEmpty(search) ? true : false;
    console.log(
      "preview init App context",
      context.preview,
      context.previewSettings
    );
    this.state = {
      /* local state props */
      showMask: false, // flag used to mask containers and spinner
      isPreviewReady: hasContextPreview,
      isVehicleReady: hasContextPreview,
      changeVehicle: !hasContextPreview,
      vehicleInfo: isEmpty(search) ? {} : search,
      drivingConditions: context.preview.drivingConditions,
      mileagePoints: isEmpty(context.preview.mileagePoints)
        ? []
        : context.preview.mileagePoints,
      menuResults: isEmpty(context.preview.menuResults)
        ? null
        : context.preview.menuResults,
      alacarteResults: isEmpty(context.preview.alacarteResults)
        ? null
        : context.preview.alacarteResults,
      selectedMakeObj: { selectedMake: "all" }
    };
  }
  componentDidMount() {
    const { dealerCode, preview, previewSettings } = this.context;
    const username = this.context.user.userName;
    getUserPreviewed(
      dealerCode,
      username,
      previewSettings,
      this.setPreviewTabs
    );
    getUserFavs(dealerCode, username, previewSettings, this.setPreviewTabs);
    const { menuResults } = preview;
    if (!isEmpty(menuResults)) {
      this.reloadPreviewData();
    }
  }
  componentWillUnmount() {}

  reloadPreviewData = () => {
    const { preview } = this.context;
    const { search, drivingConditions } = preview;
    this.previewVehicle(search, drivingConditions);
  };

  hide = () => {
    this.props.closeModal();
  };

  setPreviewTabs = () => {
    const previewTabsRef = this.refs.previewTabsRef;
    if (previewTabsRef) {
      const { previewSettings } = this.context;
      previewTabsRef.setPreviewTabs(previewSettings);
    }
  };

  resetVehicleSelector = () => {
    const previewTabsRef = this.refs.previewTabsRef;
    if (previewTabsRef) {
      const vehicleSelectorRef = previewTabsRef.refs.vehicleSelectorRef;
      if (vehicleSelectorRef) {
        vehicleSelectorRef.resetVehicleSelector();
      }
    }
  };
  /* Just to update vehicle image even before menus preview loaded */
  updateVehicleHeader = (search, drivingConditions) => {
    if (Object.keys(search).length !== 0) {
      // console.log("updateVehicleHeader", search, drivingConditions);
      const { make, modelValue, year } = search;
      const countryCode = this.context.locale.split("_")[1];
      search.image = getVehicleImage(make, modelValue, year, countryCode);
      const defaultDriving = this.setDrivingCondition(
        search,
        drivingConditions
      );
      search.defaultDrivingCondition = defaultDriving;
      search.drivingCondition = defaultDriving;
      const { vehicleDetailsRef } = this.refs;
      if (vehicleDetailsRef) {
        const { drivingCondition } = vehicleDetailsRef.state;
        if (drivingCondition !== defaultDriving) {
          vehicleDetailsRef.setState({
            drivingCondition: defaultDriving
          });
        }
      }
    }
    // Do we need to update AppContext previewSettings
    const { updatePreviewSearch, updatePreviewDrivingCond } = this.context;
    updatePreviewSearch(search);
    updatePreviewDrivingCond(drivingConditions);
    this.setState({
      isVehicleReady: true,
      vehicleInfo: search,
      drivingConditions
    });
  };
  // update Menu packages  to preview context
  updatePreviewMenus = (isPreview, response) => {
    let mileagePoints = [];
    if (response) {
      mileagePoints = this.updateMileages(response);
    }
    this.setState(
      {
        isPreviewReady: true,
        showMask: false,
        changeVehicle: false,
        menuResults: response,
        mileagePoints
      },
      () => {
        this.context.updatePreviewMenus(isPreview, response);
      }
    );
  };
  /* update Mileage label based on Time based menus */
  updateMileages = data => {
    if (!data) {
      data.servicePoints = [];
    }
    const { servicePoints } = data;
    const mileageDataPoints = [];
    if (servicePoints && servicePoints.length > 0) {
      servicePoints.forEach(servicePoint => {
        // const mileage = parseInt(servicePoint.mileage / 1000, 10).toString() + "k";
        const mileageVal = servicePoint.mileage.toString();
        let mileageLabel =
          formatNumberWithThousands(servicePoint.mileage) +
          " " +
          upperCaseFirstLetter(servicePoint.units.toString());
        // Add Time based months to label as string
        if (
          servicePoint.hasOwnProperty("months") &&
          !doesEmpty(servicePoint.months)
        ) {
          const monthLabel =
            " or " + servicePoint.months.toString() + " Months ";
          mileageLabel = mileageLabel + monthLabel;
        }
        mileageDataPoints.push({
          label: mileageLabel,
          value: mileageVal,
          mileage: servicePoint.mileage
        });
        return servicePoint;
      });
      this.context.updateMileagePoints(mileageDataPoints);
    }
    return mileageDataPoints;
  };
  // update Alacarte results to preview context
  updateAlacarteResults = (isPreview, response) => {
    const { statusCode } = response;
    let newResponse = {};
    if (statusCode !== 0) {
      newResponse.message =
        "Individual Operations are not available for this vehicle at this time.  Please check back later.";
    } else {
      newResponse = this.convertAlacarteResult(response);
    }
    if (!isEmpty(newResponse)) {
      this.setState({
        alacarteResults: newResponse
      });
      this.context.updatePreviewAlacarte(isPreview, newResponse);
    }
  };
  /* Always set default driving by checking driving conditions list */
  setDrivingCondition(search, drivingConditions) {
    let defaultDriving = "";
    const isExist = !search.defaultDrivingCondition ? false : true;
    if (isExist) {
      if (
        drivingConditions &&
        drivingConditions.length === 1 &&
        drivingConditions[0].data !== search.defaultDrivingCondition
      ) {
        search.defaultDrivingCondition = drivingConditions[0].data;
      }
      defaultDriving = search.defaultDrivingCondition;
    } else if (!isExist && drivingConditions.length > 0) {
      defaultDriving = drivingConditions[0].data;
    }
    return defaultDriving;
  }
  // Update mask state when preview button is clicked; hide edit/change vehicle selector containers here
  updateMask = isReady => {
    this.setState({
      showMask: isReady,
      changeVehicle: false
    });
  };
  setWrapperRef = node => {
    this.wrapperRef = node;
  };
  // Show Search pane when change vehicle is clicked; Hide pane when cancel is clicked
  showChangeVehicle = (changeVehicle, addBorderStyle) => {
    this.setState({
      changeVehicle,
      addBorderStyle
    });
  };

  /* Method to transform response to support A la carte preview component
   */
  convertAlacarteResult(response) {
    const modifiedResponse = {};
    let pricingMethod = 1; // set default value as 1, to show parts,labor columns for Alacarte packages
    let vehicleReadinessPricingMethod = 0; // by default, 0 - No show pricing, 1 - default pricing; show banner for calculated workflow
    let pricingReady = true; // by default, set true - "Pricing Ready" to hide banner, false - when "Pricing Not ready"; show banner for calculated workflow
    let laborOpsScale = "TENTHS"; // default  case
    let filterServices = [];
    const makeDataFilter = [];
    let anyCount = 0;
    let makeCount = 0;

    // Read dealer level Setting
    const { makeVariantMap } = this.context;
    const { search } = this.context.preview;
    const { make } = search;
    let searchMake = make;
    const dealerCatalog = makeVariantMap[make];
    if (
      dealerCatalog.laborTimePrecision &&
      !isEmpty(dealerCatalog.laborTimePrecision)
    ) {
      laborOpsScale = dealerCatalog.laborTimePrecision.toUpperCase();
    }
    if (!isEmpty(response)) {
      // modifiedResponse = response;
      const origServices =
        response.hasOwnProperty("services") && response.services;
      if (isEmpty(origServices)) {
        return modifiedResponse;
      }

      // convert single service object, into array
      if (!isEmpty(origServices)) {
        if (!isArrayExist(origServices)) {
          modifiedResponse.services = [origServices];
        } else if (isArrayExist(origServices) && origServices.length > 0) {
          modifiedResponse.services = origServices;
        }
      }
      /* Flag "pricingMethod" is turned off/on in configuration screen (0 - NOPricing; 1 - Calculated Parts, labor);
       * Both publish/unpublish alacarte, show parts, labor based on this flag (pricingMethod) binded to each service object
       */
      if (!doesEmpty(response.pricingMethod)) {
        pricingMethod = response.pricingMethod;
        modifiedResponse.pricingMethod = pricingMethod;
      }
      /* Flag "pricingReady" returned for calculated workflow (false - Pricing Not ready; true - pricing ready);
       * This flag used to show "disable pricing" banner for calculated workflow - based on (pricingMethod)
       */
      if (!doesEmpty(response.pricingReady)) {
        pricingReady = response.pricingReady;
        modifiedResponse.pricingReady = pricingReady;
      }
      /* Flag "vehicleReadinessPricingMethod" returned for calculated workflow where (0 - Show No pricing; 1 - Default pricing operations);
       * This flag used to show "disable pricing" banner for calculated workflow - based on (pricingMethod = 1 & pricingReady = false)
       */
      if (!doesEmpty(response.vehicleReadinessPricingMethod)) {
        vehicleReadinessPricingMethod = response.vehicleReadinessPricingMethod;
        modifiedResponse.vehicleReadinessPricingMethod = vehicleReadinessPricingMethod;
      }
      // Loop thr services [], filter services those have "maintenance" and "repair" only
      modifiedResponse.services.forEach(aService => {
        const origParts = aService.parts; // response has association model as "parts"

        // Note: set key with unique id, to support react
        aService.key = aService.id;
        // Check if description {key} missing in service
        const isExistDesc = isObject(aService, "description");
        aService.description = isExistDesc ? aService.description : null;

        // set service "price" here
        aService.priceLabel = "";
        if (defaultToZeroIfNullOrEmpty(aService.price) !== 0) {
          aService.priceLabel =
            aService.price > 0 ? "$" + aService.price.toFixed(2) : " ";
        }

        // set "pricingMethod" flag at each service object
        if (!doesEmpty(pricingMethod)) {
          aService.pricingMethod = pricingMethod;
        }
        // set "pricingReady" flag at each service object
        if (!doesEmpty(pricingReady)) {
          aService.pricingReady = pricingReady;
        }
        // set "vehicleReadinessPricingMethod" flag at each service object
        if (!doesEmpty(vehicleReadinessPricingMethod)) {
          aService.vehicleReadinessPricingMethod = vehicleReadinessPricingMethod;
        }
        // Note: Filter to show maintenance/repair services
        if (
          !doesEmpty(aService.serviceKind) &&
          (aService.serviceKind === "maintenance" ||
            aService.serviceKind === "repair")
        ) {
          // convert service "duration" to minutes based on dealer-setting has 'HUNDREDTHS' or TENTHS
          aService.laborTimePrecision = laborOpsScale;
          aService.durationMins = isObject(aService, "durationMins")
            ? defaultToZeroIfNullOrEmpty(aService.durationMins)
            : 0;
          aService.durationInMins = 0;
          if (aService.durationMins) {
            let durationInMins = 0;
            if (laborOpsScale === "HUNDREDTHS") {
              durationInMins = parseFloat(
                convertMinutesToHundredths(aService.durationMins)
              );
            } else if (laborOpsScale === "TENTHS") {
              durationInMins = parseFloat(
                convertMinutesToTenths(aService.durationMins)
              );
            }
            aService.durationInMins = durationInMins;
          }
          // Transform "unscheduledLaborPrice" to null when value is 0 or property missing in service{}
          const localLaborPrice = isObject(aService, "unscheduledLaborPrice")
            ? defaultToZeroIfNullOrEmpty(aService.unscheduledLaborPrice) !== 0
              ? aService.unscheduledLaborPrice
              : null
            : null;
          aService.unscheduledLaborPrice = localLaborPrice;
          // console.log(
          //   "labor obj =>",
          //   aService.durationValue,
          //   aService.unscheduledLaborPrice
          // );

          /* check if service has "make" and then add entry to makeDataFilter */
          if (
            !doesEmpty(aService.make) &&
            aService.make.toUpperCase() === "ANY"
          ) {
            anyCount++;
          }
          if (
            !doesEmpty(aService.make) &&
            aService.make.toUpperCase() === searchMake
          ) {
            makeCount++;
            searchMake = aService.make;
          }
          /* check if parts object has part or fluid details */
          let filterParts = [];
          if (!isEmpty(origParts)) {
            if (!isArrayExist(origParts)) {
              filterParts.push(origParts);
            } else if (isArrayExist(origParts) && origParts.length > 0) {
              filterParts = origParts;
            }
            aService.parts = filterParts.map(aPart => {
              aPart.description = isObject(aPart, "description")
                ? aPart.description
                : null;
              if (aPart.partType === "part") {
                aPart.oemPartNumber = isObject(aPart, "oemPartNumber")
                  ? aPart.oemPartNumber
                  : null;
              }
              /* Fluid case - either oilType or oemPartNumber should be displayed */
              if (aPart.partType === "fluid") {
                aPart.oemPartNumber = isObject(aPart, "oemPartNumber")
                  ? aPart.oemPartNumber
                  : null;
                const oilType = isObject(aPart, "oilType")
                  ? aPart.oilType
                  : null;
                aPart.oilType = !isEmpty(oilType) ? oilType : null;
                // aPart.oemPartNumber = !isEmpty(oilType)
                //   ? oilType
                //   : oemPartNumber;
              }
              aPart.adjustedQuantity = doesEmpty(aPart.adjustedQuantity)
                ? null
                : aPart.adjustedQuantity;
              aPart.unitPrice = doesEmpty(aPart.unitPrice)
                ? null
                : parseFloat(aPart.unitPrice);
              aPart.partsPrice = doesEmpty(aPart.partsPrice)
                ? null
                : parseFloat(aPart.partsPrice);
              return aPart;
            });
            filterParts = [];
          } else {
            aService.parts = filterParts;
          }
          // console.log("parts exist for serviceId", aService.id, aService.parts);
          filterServices.push(aService);
        }
      }); // services
      // console.log("convert alacarte", modifiedResponse, filterServices);
      modifiedResponse.services = filterServices;
      filterServices = [];

      /* bind store to Brand filter when any service has specific make or 'any' */
      if (anyCount > 0) {
        makeDataFilter.push({
          label: "ANY",
          value: "ANY",
          make: "ANY"
        });
      }
      if (makeCount > 0) {
        makeDataFilter.push({
          label: searchMake,
          value: searchMake,
          make: searchMake
        });
      }
      modifiedResponse.makeDataFilter = makeDataFilter;
      return modifiedResponse;
    }
  }
  showLoadingIndicator = showMask => {
    this.setState({ showMask });
  };
  showVehiclesLoadingIndicator = showVehiclesMask => {
    this.setState({ showVehiclesMask });
  };
  // search can be search (from vehicle selector) or vehicle (from vehicle tabs)
  // updateState is a callback to update caller's local states
  previewVehicle = (search, drivingConditions, updateState) => {
    this.resetPreview();
    this.updateMask(true);
    this.getDefaultMetaVehicleAndDetail(search, drivingConditions, updateState);
  };
  getDefaultMetaVehicleAndDetail(search, drivingConditions, updateState) {
    // const { dealerCode, makeVariantMap } = this.context;
    const { dealerCode } = this.context;
    const {
      make,
      year,
      modelValue,
      trimValue,
      engineTypeValue,
      engineSizeValue,
      driveTypeValue,
      transmissionTypeValue
      // defaultDrivingCondition
    } = search;
    // const { variant } = makeVariantMap[make];
    const params = {
      make,
      model: modelValue,
      year,
      trim: trimValue,
      engineType: engineTypeValue,
      engineSize: engineSizeValue,
      driveType: driveTypeValue,
      transmissionType: transmissionTypeValue,
      // drivingCondition: defaultDrivingCondition || "",
      dealerCode: dealerCode.replace("__STG_", "")
      // variant
    };
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/proc/getDefaultMetaVehicleDetailForMetaVehicleAttrs`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params
      },
      datalist => {
        // console.log(datalist);
        if (Array.isArray(datalist) && datalist.length !== 0) {
          const { metaVehicleDetailId, metaVehicleId } = datalist[0];
          // console.log(metaVehicleDetailId, metaVehicleId, search.isPreview);
          search.metaVehicleId = metaVehicleId;
          search.metaVehicleDetailId = metaVehicleDetailId;
          search.make = make; // ???
          this.setSelectedVehicleId(metaVehicleId.toString());
          if (updateState) {
            updateState();
          }
          // update preview vehicle detail
          // this.updateVehicleHeader(search, drivingConditions);
          this.loadDrivingCondtionsAndChartData(search);
          const { previewSettings } = this.context;
          const username = this.context.user.userName;
          const userPreviewed = { username, make, metaVehicleId, dealerCode };
          addUserPreviewed(userPreviewed, () => {
            getUserPreviewed(
              dealerCode,
              username,
              previewSettings,
              this.setPreviewTabs
            );
          });
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  getUnscheduledServicesForPricing(search) {
    const { dealerCode, locale, makeVariantMap } = this.context;
    const { make, isPreview, metaVehicleId } = search;
    const { variant } = makeVariantMap[make];
    const params = {
      metaVehicleId,
      includeParts: true,
      isPreview,
      locale
    };
    const restEndPoint = `/ops/proxyapi/oeproxy/rest/services/dealer/${dealerCode}/make/${make}/variant/${variant}/dealerUnscheduledServicesForPricing`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params
      },
      response => {
        // console.log("Unscheduled services", isPreview, response);
        this.updateAlacarteResults(isPreview, response);
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  findPreviewDrivingConditions = (trims, search) => {
    const {
      trim,
      engineSize,
      engineType,
      driveType,
      transmissionType
    } = search;
    if (trims) {
      // iterate trims
      for (let trimIndex = 0; trimIndex < trims.length; trimIndex++) {
        if (trim === trims[trimIndex].data) {
          // trim is matched
          const { engineTypes } = trims[trimIndex];
          if (engineTypes) {
            // iterate engine types
            for (
              let engineTypeIndex = 0;
              engineTypeIndex < engineTypes.length;
              engineTypeIndex++
            ) {
              if (engineType === engineTypes[engineTypeIndex].data) {
                // engine type is matched
                const { engineSizes } = engineTypes[engineTypeIndex];
                if (engineSizes) {
                  // iterate engine sizes
                  for (
                    let engineSizeIndex = 0;
                    engineSizeIndex < engineSizes.length;
                    engineSizeIndex++
                  ) {
                    if (engineSize === engineSizes[engineSizeIndex].data) {
                      // engine size is matched
                      const { driveTypes } = engineSizes[engineSizeIndex];
                      if (driveTypes) {
                        // iterate drive types
                        for (
                          let driveTypeIndex = 0;
                          driveTypeIndex < driveTypes.length;
                          driveTypeIndex++
                        ) {
                          if (driveType === driveTypes[driveTypeIndex].data) {
                            // drive type is matched
                            const { transmissionTypes } = driveTypes[
                              driveTypeIndex
                            ];
                            if (transmissionTypes) {
                              // iterate transmission types
                              for (
                                let transmissionTypeIndex = 0;
                                transmissionTypeIndex <
                                transmissionTypes.length;
                                transmissionTypeIndex++
                              ) {
                                if (
                                  transmissionType ===
                                  transmissionTypes[transmissionTypeIndex].data
                                ) {
                                  // transmissiion type is matched
                                  const {
                                    drivingConditions
                                  } = transmissionTypes[transmissionTypeIndex];
                                  // return driving conditions
                                  return drivingConditions
                                    ? drivingConditions
                                    : [];
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return [];
  };

  loadDrivingCondtionsAndChartData = search => {
    const { webKey, locale, makeVariantMap } = this.context;
    const { make, model, year } = search;
    const variant = makeVariantMap[make].variant;
    const params = {
      webKey,
      variant,
      locale,
      isPreview: true,
      useSkipTrim: 0,
      page: 1,
      start: 0,
      limit: 25
    };
    const restEndPoint = `/ops/proxyapi/oeproxy/rest/vehicles/make/${make}/variant/${variant}/model/${model}/year/${year}/trims`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params
      },
      data => {
        const { vehicleInfo } = data;
        if (!vehicleInfo) {
          toast.error("Error in getting vehicle trim data.");
          return;
        }
        const { trims } = vehicleInfo;
        const { preview } = this.context;
        const { isPreview } = preview;
        search.isPreview = isPreview === null ? true : isPreview;
        if (!search.defaultDrivingCondition) {
          search.defaultDrivingCondition = vehicleInfo.defaultDrivingCondition;
        }
        search.drivingConditions = this.findPreviewDrivingConditions(
          trims,
          search
        );
        this.updateVehicleHeader(search, search.drivingConditions);
        this.loadChartData(search);
        this.getUnscheduledServicesForPricing(search);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  loadChartData = search => {
    const { dealerCode, locale, makeVariantMap } = this.context;
    const { make, isPreview, regDate } = search;
    const { metaVehicleId, defaultDrivingCondition } = search;
    const { showDrivingConditions, variant, cappedPricing } = makeVariantMap[
      make
    ];
    const drivingCondition = showDrivingConditions
      ? defaultDrivingCondition
      : "";

    const url = `/ops/proxyapi/oeproxy/rest/services/dealer/${dealerCode}/make/${make}/variant/${variant}/dealermenu`;
    console.log("preview > dealermenu API", search);
    const formatDate =
      cappedPricing === 1 ? formatRegDate(regDate, "YYYY-MM-DD") : "";
    makeSecureRestApi(
      {
        url,
        method: "get",
        params: {
          metaVehicleId,
          drivingCondition,
          locale,
          isPreview,
          isCappedPricing: cappedPricing === 1 ? true : false,
          regDate: formatDate
        }
      },
      data => {
        const { statusCode } = data;
        if (statusCode === 0) {
          // if (
          //   !showMenuPricing ||
          //   !servicePoints ||
          //   (servicePoints && servicePoints.length === 0)
          // ) {
          //   toast.info(
          //     "Menus are not available for this vehicle at this time. Please check back again"
          //   );
          //   return;
          // }
          if (!data.servicePoints) {
            data.servicePoints = [];
          } else if (!Array.isArray(data.servicePoints)) {
            data.servicePoints = [data.servicePoints];
          }
          this.fixupServicePointsResponse(data);
          this.setState({ showResult: true, data });
          search.isPreview = isPreview;
          this.updatePreviewMenus(isPreview, data);
          this.trackPreviewAction(isPreview ? "Unpublish" : "Publish");
        } else {
          data.servicePoints = [];
          data.sortedMenuTypes = [];
          this.setState({ showResult: true, data });
          search.isPreview = isPreview;
          this.updatePreviewMenus(isPreview, data);
          this.trackPreviewAction(isPreview ? "Unpublish" : "Publish");
          // toast.error(
          //   "Menus are not available for this vehicle at this time.  Please check back later."
          // );
          // this.updateMask(false);
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  fixupServicePointsResponse = response => {
    // check for empty/null servicePoints
    if (!response.servicePoints) {
      response.servicePoints = [];
    }
    if (response.servicePoints.length === 0) {
      return;
    }
    // create id to menu type map from servicePoints items
    const { servicePoints } = response;
    const idToMenuTypeMap = {};
    servicePoints.forEach(servicePoint => {
      if (!servicePoint.menuTypes) {
        servicePoint.menuTypes = [];
      } else if (!Array.isArray(servicePoint.menuTypes)) {
        servicePoint.menuTypes = [servicePoint.menuTypes];
      }
      const { menuTypes } = servicePoint;
      menuTypes.forEach(menuType => {
        const { id } = menuType;
        if (!idToMenuTypeMap[id.toString()]) {
          idToMenuTypeMap[id] = menuType;
        }
      });
    });
    // check if menu type count is > 4
    const originalMenuTypes = Object.values(idToMenuTypeMap);
    if (originalMenuTypes.length > 4) {
      // remove unnecessary menu types from map by rank
      const sortedMenuTypes = originalMenuTypes.sort(menuTypeComparator);
      for (let index = 4; index < sortedMenuTypes.length; index++) {
        const menuType = sortedMenuTypes[index];
        delete idToMenuTypeMap[menuType.id.toString()];
      }
      console.log(
        "originalMenuTypes",
        originalMenuTypes,
        "idToMenuTypeMap",
        idToMenuTypeMap
      );
    }
    // store sorted menu types in the response
    response.sortedMenuTypes = Object.values(idToMenuTypeMap).sort(
      menuTypeComparator
    );
    const { updateSortedMenuTyeps } = this.context;
    updateSortedMenuTyeps(response.sortedMenuTypes);

    // remove unnecessary menuTypes
    servicePoints.forEach(servicePoint => {
      const { menuTypes } = servicePoint;
      const checkedMenuTypeMap = {};
      const newMenuTypes = menuTypes.filter(menuType => {
        const { id } = menuType;
        const menuTypeId = id.toString();
        if (!idToMenuTypeMap[menuTypeId]) {
          return false;
        }
        // following logic is to remove duplicate menu types
        if (!checkedMenuTypeMap[menuTypeId]) {
          checkedMenuTypeMap[menuTypeId] = menuType;
          // calculate menu type level "selectableTotalPrice"
          menuType.selectableTotalPrice = this.calculateMenuTotalPrice(
            menuType
          );
          return true;
        }
        return false;
      });
      // assign new menu types to exclude the unnecessary ones
      servicePoint.menuTypes = newMenuTypes.sort(menuTypeComparator);
    });
  };
  /* Util method to check if atleast one service selectable;
     use this flag to display totalprice{sum of unselectable services} per menutype
  */
  hasSelectableService(services) {
    for (let index = 0; index < services.length; index++) {
      if (services[index].selectable) {
        return true;
      }
    }
    return false;
  }
  calculateMenuTotalPrice = menuType => {
    const { services } = menuType;
    const selectableExists = this.hasSelectableService(services);
    let totalPrice = defaultToZeroIfNullOrEmpty(menuType.totalPrice);
    if (selectableExists) {
      services.forEach(service => {
        const { price, selectable, selectByDefault } = service;
        if (selectable && !selectByDefault) {
          totalPrice -= defaultToZeroIfNullOrEmpty(price);
        }
      });
    }
    return Math.round(totalPrice * 100) / 100;
  };

  resetPreview = () => {
    this.setState({ menuResults: {}, mileagePoints: [] });
  };

  // push event to GTM
  trackPreviewAction = fieldName => {
    const tagObj = {
      eventResult: "Preview Menu packages - " + fieldName,
      eventFeature: "Preview Page",
      eventFeatures: "preview page> preview vehicle>" + fieldName,
      trackPageUrl: "preview/preview-vehicle/" + fieldName
    };
    gtmEvents.gtmTrackEventWithParams(
      "xmm.preview.preview_vehicle_click",
      tagObj
    );
  };

  updateVehicleDetail = () => {
    if (this.refs.vehicleDetailsRef) {
      this.refs.vehicleDetailsRef.setFavorite();
    }
  };

  setSelectedVehicleId = selectedVehicleId => {
    this.setState({ selectedVehicleId });
  };

  render() {
    // set component state to preview-context
    const contextValue = {
      appContext: this.context, // send appContent as props
      localeStrings: this.context.localeStrings,
      locale: this.context.locale,
      webKey: this.context.webKey,
      dealerCode: this.context.dealerCode,
      makelist: this.context.makelist,
      userName: this.context.user.userName,
      search: this.state.vehicleInfo,
      vehicleInfo: this.state.vehicleInfo,
      drivingConditions: this.state.drivingConditions,
      mileagePoints: this.state.mileagePoints,
      menuResults: this.state.menuResults,
      alacarteResults: this.state.alacarteResults,
      selectedMakeObj: this.state.selectedMakeObj,
      selectedVehicleId: this.state.selectedVehicleId,
      /* handlers */
      showChangeVehicle: this.showChangeVehicle,
      updateMask: this.updateMask,
      updateVehicleHeader: this.updateVehicleHeader,
      updatePreviewMenus: this.updatePreviewMenus,
      updateAlacarteResults: this.updateAlacarteResults,
      updateSelectedMakeObj: selectedMakeObj => {
        this.setState({ selectedMakeObj });
      },
      setPreviewTabs: this.setPreviewTabs,
      setSelectedVehicleId: this.setSelectedVehicleId,
      showLoadingIndicator: this.showLoadingIndicator,
      showVehiclesLoadingIndicator: this.showVehiclesLoadingIndicator,
      previewVehicle: this.previewVehicle
    };
    let loadmask = null;
    let vehicleSection = null;
    let searchSection = null;
    let resultSection = null;
    const { localeStrings } = this.context;
    const {
      vehicleInfo,
      changeVehicle,
      addBorderStyle,
      menuResults
    } = this.state;
    const modalHeader = (
      <ModalDialog.Title>
        {localeStrings["xmm.portal.nav.preview"]}
      </ModalDialog.Title>
    );
    let changeHideCls = changeVehicle ? "xmm-vehicle-preview-form" : "hide";
    let addShadowCls = "";
    if (addBorderStyle) {
      changeHideCls = changeHideCls + " xmm-content-border";
      addShadowCls = "xmm-box-shadow";
    }
    if (this.state.isVehicleReady) {
      vehicleSection = (
        <VehicleDetails ref="vehicleDetailsRef" vehicle={vehicleInfo} />
      );
    }
    if (!isEmpty(menuResults)) {
      resultSection = <ResultTabs />;
    }
    // To pre-fill selectors, don't destroy component
    searchSection = (
      <div className={changeHideCls} ref={this.setWrapperRef}>
        <PreviewTabs
          ref="previewTabsRef"
          showCancel={this.state.isPreviewReady}
          onUpdate={this.updateVehicleDetail}
          parentHandle={this}
        />
      </div>
    );

    /* placeholder to show loading mask */
    if (this.state.showMask) {
      loadmask = (
        <div className="xmm-mask-preview">
          <div className="xmm-mask-chart-container" />
          <LoadingIndicator htmlId="previewMask" size="large" />
          <div className="xmm-mask-content-container" />
        </div>
      );
    } else if (this.state.showVehiclesMask) {
      loadmask = (
        <div className="xmm-loading-mask">
          <div className="xmm-loading-label">Loading</div>
          <LoadingIndicator htmlId="previewMask" size="large" />
        </div>
      );
    }
    const maskStyle = this.state.showMask ? "" : "";
    return (
      <React.Fragment>
        <PreviewContext.Provider value={contextValue}>
          <ModalDialog
            htmlId="PreviewModal"
            show={this.props.show}
            onHide={this.hide}
            backdrop={"static"}
            header={modalHeader}
            className="xmm-preview-modal xmm-large-header"
          >
            <div id="previewBody" className={maskStyle}>
              {vehicleSection}
              <div className={"xmm-float-section " + addShadowCls}>
                {searchSection}
              </div>
              {loadmask}
              {resultSection}
            </div>
            {this.props.children}
          </ModalDialog>
        </PreviewContext.Provider>
      </React.Fragment>
    );
  }
}
export default PreviewModal;

PreviewModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.object,
  closeModal: PropTypes.func,
  okAction: PropTypes.func
};
/* eslint-enable no-console */
