/* Declare AG-GRID value formatter, valueParser in this file */
import React from "react";
import { toEmptyStringIfUndefined, upperCaseFirstLetter } from "./string";
import { xlate } from "../i18n/locales";

export function blankValueFormatter(params) {
  const val = params.value;
  if (toEmptyStringIfUndefined(val)) {
    return val;
  } else {
    return "-";
  }
}

export function boolToStringFormatter(params) {
  const val = params.value;
  return val
    ? val === "1"
      ? xlate("xmm.portal.common.enabled")
      : val === "0"
      ? xlate("xmm.portal.common.disabled")
      : val
    : val;
}

export function serviceKindFormatter(params) {
  let value = params.value;
  if (value && typeof value !== "string") {
    const keys = Object.keys(value || {});
    value = value[keys[0]];
  }
  return upperCaseFirstLetter(value);
}

export function formatPrice(inputValue) {
  let value = inputValue;
  if (typeof value === "string") {
    value = value.toString().replace(",", "");
  }
  if (value !== null && value !== undefined && value !== "" && !isNaN(value)) {
    const price = Math.round(value * 100) / 100;
    return price.toFixed(2);
  }
  return "";
}

function internalFormatPrice(value) {
  const price = Math.round(value * 100) / 100;
  if (!isNaN(price) && price >= 0) {
    return "$".concat(price.toFixed(2));
  }
  return "-";
}

export const priceFormatter = params => {
  // const price = Math.floor(params.value);
  // const currency = Number(Math.random(params.value).toFixed(2));
  if (
    params.value === "" ||
    params.value === undefined ||
    params.value === null
  ) {
    return "-";
  }
  return internalFormatPrice(params.value);
};

export function yesnoFormatter(params) {
  if (!params || !params.data) {
    return "-";
  }
  let val = toEmptyStringIfUndefined(params.value);
  if (val) {
    val = typeof val === "number" ? val.toString() : val;
    return val === "1" ? "YES" : "NO";
  } else {
    return "-";
  }
}

export function numberParser(params) {
  return Number(params.newValue);
}
// Mostly called in operatons > Preview
export function floatParser(params) {
  const newValue = params.newValue;
  let valueAsNumber;
  if (newValue === null || newValue === undefined || newValue === "") {
    valueAsNumber = null;
  } else {
    valueAsNumber = parseFloat(params.newValue);
  }
  return valueAsNumber;
}

/* ~~~~~~~~  generic Value Formatters ~~~~~~~~~~~   */
export const formatNumber = number => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

// eslint-disable-next-line react/no-multi-comp
export const currFormatter = obj => {
  const value = Number(obj.value);
  const text = value.toLocaleString(undefined, {
    style: "currency",
    currency: "EUR"
  });
  return <span>{text}</span>;
};

export const currencyFormatter = params => {
  const value = Math.floor(params.value);
  if (isNaN(value)) return "";
  return "\x24" + formatNumber(params.value);
};

export const commasFormatter = params => {
  const val = params.value;
  return !val ? "" : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// eslint-disable-next-line react/no-multi-comp
export const toastMessageFormatter = msgToShow => {
  const lines = msgToShow.split("\n");
  return (
    <div>
      {lines.map((line, index) => {
        const key = `key_${index}`;
        const msg = getAddPackageMessage(line);
        return <p key={key}>{msg}</p>;
      })}
    </div>
  );
};

function getAddPackageMessageArgs(line) {
  const lines = line.split('"');
  return [lines[1], lines[3]];
}
function getAddPackageMessage(line) {
  let overrideErrorLabel = "";
  let overrideSuccessLabel = "";
  let msg = line;
  if (line.indexOf("Opcode Override:") !== -1) {
    overrideErrorLabel = xlate("xmm.portal.package.opcode_override");
  } else if (line.indexOf("Price Override:") !== -1) {
    overrideErrorLabel = xlate("xmm.portal.package.price_override");
  } else if (line.indexOf("Shop Duration Override:") !== -1) {
    overrideErrorLabel = xlate("xmm.portal.package.shop_duration_override");
  } else if (line.indexOf("Opcode Override") !== -1) {
    overrideSuccessLabel = xlate("xmm.portal.package.opcode_override");
  } else if (line.indexOf("Price Override") !== -1) {
    overrideSuccessLabel = xlate("xmm.portal.package.price_override");
  } else if (line.indexOf("Shop Duration Override") !== -1) {
    overrideSuccessLabel = xlate("xmm.portal.package.shop_duration_override");
  } else if (line.indexOf("Menu type changed for requested packages") !== -1) {
    msg = xlate("xmm.portal.msg.package_menu_type_changed");
  } else if (line.indexOf("At least one of the selected packages is") !== -1) {
    msg = xlate("xmm.portal.errors.menu_type_exists_in_at_least_one_pacakge");
  } else if (line.indexOf("Invalid package type/override value") !== -1) {
    msg = xlate("xmm.portal.errors.invalid_package_type_or_override_combo");
  } else if (line.indexOf("Package override") !== -1) {
    overrideErrorLabel = "xxx";
  } else if (line.indexOf("Invalid arguments") !== -1) {
    msg = xlate("xmm.portal.errors.invalid_arguments");
  } else if (line.indexOf("Error: Cycle detected in filter") !== -1) {
    msg = xlate("xmm.portal.errors.cycle_in_filter");
  } else if (line.indexOf("Scope changed and/or meta-vehicles") !== -1) {
    const str = line.substring(
      "Scope changed and/or meta-vehicles added for ".length
    );
    const args = str.split(" ");
    msg = xlate("xmm.portal.msg.scope_changed_in_x_packages").replace(
      "%1",
      args[0]
    );
  } else if (line.indexOf("Filter applied to ") !== -1) {
    const str = line.substring("Filter applied to ".length);
    const args = str.split(" ");
    msg = xlate("xmm.portal.msg.filter_applied_in_x_packages").replace(
      "%1",
      args[0]
    );
  } else if (line.indexOf("More than one attribute value supplied") !== -1) {
    msg = xlate("xmm.portal.errors.more_than_one_attribute_value");
  } else if (line.indexOf("Price changed") !== -1) {
    msg = xlate("xmm.portal.msg.price_changed");
  } else if (line.indexOf("Opcode changed") !== -1) {
    msg = xlate("xmm.portal.msg.opcode_changed");
  } else if (line.indexOf("Scheduling Duration changed") !== -1) {
    msg = xlate("xmm.portal.msg.scheduling_duration_changed");
  } else if (line.indexOf("No attribute value supplied") !== -1) {
    msg = xlate("xmm.portal.msg.no_attribute_value_supplied");
  } else if (line.indexOf("package(s) copied") !== -1) {
    const args = line.split(" ");
    msg = xlate("xmm.portal.msg.x_packages_copied").replace("%1", args[0]);
  }
  if (overrideErrorLabel) {
    const args = getAddPackageMessageArgs(line);
    msg = xlate("xmm.portal.errors.package_override_overlapped");
    msg = msg.replace("%1", args[0]).replace("%2", args[1]);
    msg = overrideErrorLabel === "xxx" ? msg : `${overrideErrorLabel}: ${msg}`;
  } else if (overrideSuccessLabel) {
    msg = xlate("xmm.portal.msg.override_created");
    msg = msg.replace("%1", overrideSuccessLabel);
  }
  return msg;
}

export function formatLaborTime(value) {
  const laborTimeInHours = Math.round(value * 100) / 100;
  if (!isNaN(laborTimeInHours) && laborTimeInHours >= 0) {
    return laborTimeInHours.toFixed(2);
  }
  return "-";
}

export const laborTimeFormatter = params => {
  // const price = Math.floor(params.value);
  // const currency = Number(Math.random(params.value).toFixed(2));
  if (
    params.value === "" ||
    params.value === undefined ||
    params.value === null
  ) {
    return "-";
  }
  return formatLaborTime(params.value);
};

export function extractLaborRateValues(makeRateCodesMap, make) {
  const laborRates = makeRateCodesMap[make];
  const keys = laborRates.map(item => {
    return item.value.toString();
  });
  keys.splice(0, 0, "");
  return keys;
}

export function extractLaborRates(dealerLaborRateCodes) {
  const result = {};
  const firstRec = {
    dealerLaborRateCodeId: "",
    description: ""
  };
  if (dealerLaborRateCodes && dealerLaborRateCodes.length > 0) {
    dealerLaborRateCodes.splice(0, 0, firstRec);
  }
  // convert array of objects into single object
  dealerLaborRateCodes.forEach(item => {
    result[item.dealerLaborRateCodeId.toString()] = item.description;
  });
  return Object.freeze(result);
}
