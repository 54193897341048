import React from "react";
import ChartPreview from "./chart/ChartPreview";
import MenuPackagesTable from "./menus/MenuPackagesTable";
import PreviewContext from "../preview-context";
import { DisplayFormikState } from "../../../reusable/helper";
import Card from "@cx/ui/Card";
import { isNoPricing } from "./../../../../commonUtil/utils/object";
import * as gtmEvents from "../../../utils/gtag-eventlist";
class MenusPanel extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    const { make } = context.appContext.preview.search;
    const pricingFlow = !isNoPricing(context.makelist, make);
    this.state = {
      pricingFlow
    };
  }
  componentDidMount() {
    gtmEvents.trackGtagPageview("/preview/menu-packages");
  }

  render() {
    let menusTable = null;
    const { pricingFlow } = this.state;
    let chartWidget = null;
    if (pricingFlow) {
      chartWidget = (
        <div className="xmm-chart-container">
          <ChartPreview data={this.context.menuResults} />
        </div>
      );
    }
    const localstate = {
      search: this.context.appContext.preview.search
      // mileages: this.context.appContext.preview.mileagePoints
    };

    const stateHeader = (
      <div style={{ overflow: "auto", height: "120px" }}>
        <DisplayFormikState {...localstate} />
      </div>
    );

    if (this.context.menuResults) {
      menusTable = (
        <MenuPackagesTable
          menuResults={this.context.menuResults}
          mileagePoints={this.context.mileagePoints}
          currentMileagePoint={
            this.context.appContext.preview.currentMileagePoint
          }
        />
      );
    }
    const menusWidget = (
      <React.Fragment>
        <Card htmlId="paneCard" className="hide">
          {stateHeader}
        </Card>
        {chartWidget}
        {menusTable}
      </React.Fragment>
    );

    return <div className="xmm-menu-tabs-content">{menusWidget}</div>;
  }
}

export default MenusPanel;
