/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { bool, PropTypes, string } from "prop-types";
import { FormattedMessage } from "react-intl";

export default class Confirmation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { htmlId, proceedButtonStyle } = this.props;
    const modalHtmlId = `${htmlId}Modal`;
    const cancelHtmlId = `${htmlId}CancelButton`;
    const titleId = this.props.titleId
      ? this.props.titleId
      : "xmm.portal.common.are_you_sure_label";
    return (
      <div>
        <ModalDialog
          htmlId={modalHtmlId}
          className="sample-modal-dialog"
          backdrop={"static"}
          show={this.props.show}
          header={
            <ModalDialog.Title>
              <FormattedMessage defaultMessage="Are you sure" id={titleId} />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <Button
                htmlId={cancelHtmlId}
                onClick={this.props.closeDialog}
                text="Close Button"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="xmm.portal.common.cancel_button"
                />
              </Button>
              <Button
                htmlId="okDeleteOpsButton"
                onClick={this.props.actionFunction}
                text="Delete Button"
                buttonStyle={proceedButtonStyle}
              >
                <FormattedMessage
                  defaultMessage="Proceed"
                  id="xmm.portal.common.proceed_button"
                />
              </Button>
            </div>
          }
          onHide={this.props.closeDialog}
        >
          <div>
            {/* <p>
              <FormattedMessage defaultMessage="Proceed" id={messageId} />
            </p>
             */}
            <p>{this.props.message}</p>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

Confirmation.propTypes = {
  htmlId: string,
  titleId: string,
  message: string,
  proceedButtonStyle: string,
  show: bool,
  actionFunction: PropTypes.func,
  closeDialog: PropTypes.func
};
