/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import MenusPanel from "./content/MenusPanel";
import IndividualOperationsTable from "./content/individual/IndividualOperationsTable";
import PreviewContext from "./preview-context";

class ResultTabs extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    const { localeStrings } = context.appContext;
    this.menuPackagesLabel =
      localeStrings["xmm.portal.preview.vehicle.menu_packages_lbl"];
    this.individualOperationsLabel =
      localeStrings["xmm.portal.preview.vehicle.individual_operations_lbl"];
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <Tabs htmlId="ResultTabs">
        <Tab label={this.menuPackagesLabel}>
          <MenusPanel />
        </Tab>
        <Tab label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable />
        </Tab>
      </Tabs>
    );
  }
}
export default ResultTabs;

ResultTabs.propTypes = {
  showCancel: PropTypes.bool
};

/* eslint-enable no-console */
