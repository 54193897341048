export const navigateToMenuLink = (parent, node) => {
  window.dispatchEvent(
    new CustomEvent("navMenuLinkChanged", {
      detail: { parent, node },
      bubbles: true,
      cancelable: true
    })
  );
};

export const shouldShowContentPanelUp = (parentId, childNode, panelHeight) => {
  const gridBoundingBox = document
    .querySelector(parentId)
    .getBoundingClientRect();
  const childBoundingBox = childNode.getBoundingClientRect();
  return childBoundingBox.bottom + panelHeight > gridBoundingBox.bottom;
};

export function getAllVehicleGroupName(make, template) {
  return template.replace("%1", make);
  // return localeStrings["xmm.portal.common.all_make_vehicles"].replace(
  //   "%1",
  //   make
  // );
}
