import Alert from "@cx/ui/Alert";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** Generic Alert With config */
function AlertBox(props) {
  const [type, setType] = useState(props.type || "info");
  const [closeOption] = useState(props.closeButton || false);

  useEffect(
    function updateBanner() {
      if (!type) {
        setType("warning");
      } else {
        let icontype = "info";
        switch (type) {
          case "success":
            icontype = "success";
            break;

          case "danger":
            icontype = "danger";
            break;

          case "warning":
            icontype = "warning";
            break;

          default:
            icontype = "info";
            break;
        }

        setType(icontype);
      }
    },
    [type]
  );

  return (
    <React.Fragment>
      <Alert type={type} displayCloseButton={closeOption}>
        <LoadingIndicator
          htmlId="LoadingIndicatorWhite"
          className={!props.loading ? "hide" : ""}
        />
        {props.message}
        {""}
        {typeof props.linkHtml === "object" ? props.linkHtml : ""}
      </Alert>
    </React.Fragment>
  );
}
export default AlertBox;

AlertBox.propTypes = {
  message: PropTypes.string,
  linkHtml: PropTypes.object,
  type: PropTypes.string,
  closeButton: PropTypes.bool,
  loading: PropTypes.bool
};
