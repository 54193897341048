/* eslint-disable no-console */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { toast } from "@cx/ui/Toast";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import Grid from "@cx/ui/Grid";
import IconMore from "@cx/ui/Icons/IconMore";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import TextInput from "@cx/ui/TextInput";
import CriteriaContainer from "../GroupCriteria";
import ShowVehiclesGrid from "./ShowVehiclesGrid";
import StatusBox from "../../../commonUtil/components/templates/StatusBox";
import {
  AddMetaVehicleFilter,
  FindMetaVehDetWFilter,
  MetaVehicleFilterParams
} from "../../../constants/ModuleConstants";
import { makeSecureRestApi } from "../../../../src/api/xmmAxios";
import { AppContext } from "../../../components/app-context";
import * as gtmEvents from "../../utils/gtag-eventlist";
import { isDifferentValue, sanitize } from "../../../commonUtil/utils/string";
import { xlate } from "../../../commonUtil/i18n/locales";

export default class VehicleGroupForm extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };
  static contextType = AppContext;
  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sortedVehicleGroups !== prevState.sortedVehicleGroups) {
      return {
        sortedVehicleGroups: nextProps.sortedVehicleGroups
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props);
    // const criteria = this.buildCriteriaFields(props.metaVehicleFilter);
    // console.log("buildCriteriaFields", criteria);
    this.handleSave = this.handleSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.setDirty = this.setDirty.bind(this);
    this.reset = this.reset.bind(this);
    this.onRemoveVehicleGroup = this.onRemoveVehicleGroup.bind(this);
    this.onDirtyVehicleGroupFormEvent = this.onDirtyVehicleGroupFormEvent.bind(
      this
    );
    this.updateStatusBox = this.updateStatusBox.bind(this);
    const { sortedVehicleGroups } = props;
    // const { localeStrings } = context;
    this.initializeLocaleValues();
    this.state = {
      allCriteriaOptions: [],
      criteriaFields: [],
      metaVehicleFilter: {},
      dirty: false,
      errors: {},
      sortedVehicleGroups
    };
  }
  componentDidMount() {
    window.addEventListener(
      "dirtyVehicleGroupFormEvent",
      this.onDirtyVehicleGroupFormEvent,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "dirtyVehicleGroupFormEvent",
      this.onDirtyVehicleGroupFormEvent,
      false
    );
  }
  initializeLocaleValues(localeStrings) {
    this.nameLabel = xlate("xmm.portal.common.name_lbl");
    this.criteriaLabel = xlate("xmm.portal.vehiclegroups.criteria");
    this.saveButtonLabel = xlate("xmm.portal.common.save_button");
    this.usedByOor1RuleLabel = xlate("xmm.portal.common.used_by_0_or_1_rule");
    this.usedByRulesLabel = xlate("xmm.portal.common.used_by_x_rules");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }
  isDirty() {
    const { dirty } = this.state;
    return dirty;
  }

  setDirty = dirty => {
    window.dispatchEvent(
      new CustomEvent("dirtyVehicleGroupFormEvent", {
        detail: { dirty },
        bubbles: true,
        cancelable: true
      })
    );
  };

  reset = dirty => {
    this.updateStatusBox("", "text", true);
    this.setDirty(dirty);
    if (this.refs.showVehicleGridRef) {
      this.refs.showVehicleGridRef.reset();
    }
  };

  handleAdd = criteriaFields => {
    //
    // const { localeStrings } = this.context;
    const data = this.createAddSimpleMetaVehicleWExcludeFilterData(
      criteriaFields
    );
    // console.log("createAddSimpleMetaVehicleWExcludeFilterData", data);
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/addSimpleMetaVehicleWExcludeFilter";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data
      },
      response => {
        if (response && response.length > 0) {
          const resp = response[0];
          const { isError } = resp;
          if (isError && isError === 1) {
            this.setState({ errors: { name: "Duplicate Filter Name" } });
            return;
          }
          const variant = this.context.makelist.filter(m => {
            return m.value === data.make;
          })[0].variant;
          resp.make = data.make;
          resp.dealerCode = data.dealerCode;
          resp.variant = variant;
          resp.name = data.filterName;
          // console.log("addSimpleMetaVehicleWExcludeFilter", resp);
          window.dispatchEvent(
            new CustomEvent("dealerNameFilterEvent", {
              detail: resp,
              bubbles: true,
              cancelable: true
            })
          );
          window.dispatchEvent(
            new CustomEvent("addNamedVehicleFilterEvent", {
              detail: resp,
              bubbles: true,
              cancelable: true
            })
          );
          window.dispatchEvent(
            new CustomEvent("closeCreateVehicleModalEvent", {
              detail: {},
              bubbles: true,
              cancelable: true
            })
          );
          // const status = localeStrings["xmm.portal.common.successful_save"];
          // toast.success(status);
          this.updateStatusBox(this.savedMsg, "success", true);
        }
      },
      error => {
        // toast.error(error.message);
        this.updateStatusBox(error.message, "error", false, true);
      }
    );
  };

  handleEdit = criteriaFields => {
    // const { localeStrings } = this.context;
    const data = this.createEditSimpleMetaVehicleWExcludeFilterParams(
      criteriaFields
    );
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/editSimpleMetaVehicleWExcludeFilterP";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data
      },
      response => {
        if (response && response.length > 0) {
          // const data = response[0];
          // console.log("editSimpleMetaVehicleWExcludeFilter", data);
          window.dispatchEvent(
            new CustomEvent("dealerNameFilterEvent", {
              detail: this.state.metaVehicleFilter,
              bubbles: true,
              cancelable: true
            })
          );
          // const status = localeStrings["xmm.portal.common.successful_save"];
          // toast.success(status);
          this.updateStatusBox(this.savedMsg, "success", true);
        }
      },
      error => {
        this.updateStatusBox(
          "Cannot Delete",
          "error",
          false,
          true,
          error.message
        );
        // toast.error(error.message);
        this.updateStatusBox(error.message, "error", false, true);
      }
    );
  };

  convertKeyToLabel(key) {
    return key.replace(/[^a-zA-Z]/g, "").replace(/^\w/g, c => {
      return c.toUpperCase();
    });
  }

  getValueFromSelectOption(option) {
    return option.value === undefined ? option : option.value;
  }

  checkDuplicateGroupName = (metaVehicleFilter, metaVehicleFilters) => {
    const { name, metaVehicleFilterId } = metaVehicleFilter;
    let metaVehicleFiltersToCompare = metaVehicleFilters;
    if (metaVehicleFilterId !== "") {
      metaVehicleFiltersToCompare = metaVehicleFilters.filter(m => {
        return isDifferentValue(m.metaVehicleFilterId, metaVehicleFilterId);
      });
    }
    for (
      let filterIndex = 0;
      filterIndex < metaVehicleFiltersToCompare.length;
      filterIndex++
    ) {
      if (name === metaVehicleFiltersToCompare[filterIndex].name) {
        this.setState({ errors: { name: "Duplicate Filter Name" } });
        return true;
      }
    }
    return false;
  };

  isDuplicateVehicleGroupName = () => {
    const { metaVehicleFilter, sortedVehicleGroups } = this.state;
    // console.log(sortedVehicleGroups, metaVehicleFilter);
    const { make } = metaVehicleFilter;
    for (let index = 0; index < sortedVehicleGroups.length; index++) {
      const makeMetaVehicleFiltersMap = sortedVehicleGroups[index];
      const metaVehicleFilters = makeMetaVehicleFiltersMap[make];
      if (metaVehicleFilters) {
        return this.checkDuplicateGroupName(
          metaVehicleFilter,
          metaVehicleFilters
        );
      }
    }
    return false;
  };

  isValidCriteriaFields = criteriaFields => {
    let isValid = true;

    // let hasIncludedYears = false;
    let hasStartYear = false;
    let hasEndYear = false;
    let startYear = null;
    let endYear = null;
    const nameOperatorMap = {};
    const duplicateCriteriaFieldsMap = {};

    criteriaFields.forEach(c => {
      if (nameOperatorMap[c.name + c.operator]) {
        duplicateCriteriaFieldsMap[c.key] = true;
        isValid = false;
      } else {
        nameOperatorMap[c.name + c.operator] = true;
      }
      if (!c.operator) {
        isValid = false;
      }
      if (!c.name) {
        isValid = false;
      }
      if (c.name === "startYear") {
        hasStartYear = true;
        if (c.values && c.values.length !== 0) {
          startYear = Number(this.getValueFromSelectOption(c.values[0]));
        }
      } else if (c.name === "endYear") {
        hasEndYear = true;
        if (c.values && c.values.length !== 0) {
          endYear = Number(this.getValueFromSelectOption(c.values[0]));
        }
      } else if (!c.values || c.values.length === 0) {
        isValid = false;
      }
    });

    if (hasStartYear && hasEndYear) {
      if (
        (!startYear && !endYear) ||
        (startYear && endYear && startYear > endYear)
      ) {
        isValid = false;
      }
    }

    if (criteriaFields.length === 0) {
      toast.error(xlate("xmm.portal.errors.criteria_required"));
      isValid = false;
    }

    const { metaVehicleFilter } = this.state;
    if (!metaVehicleFilter.name || metaVehicleFilter.name.trim() === "") {
      isValid = false;
    }

    if (!isValid) {
      this.refs.criteriaContainerRef.validateAllCriteria(
        duplicateCriteriaFieldsMap
      );
    }

    return isValid;
  };

  handleSave = event => {
    this.refs.criteriaContainerRef.removeBlankCriteria();
    const criteriaFields = this.refs.criteriaContainerRef.getCriteriaFields();
    const duplicateGroupName = this.isDuplicateVehicleGroupName();
    const validCriteria = this.isValidCriteriaFields(criteriaFields);
    if (!validCriteria || duplicateGroupName) {
      return;
    }
    this.updateStatusBox(this.savingMsg, "pending", false);
    gtmEvents.gtmTrackEvent("xmm.vehiclegroups.save_click");
    if (this.state.metaVehicleFilter.metaVehicleFilterId === "") {
      this.handleAdd(criteriaFields);
    } else {
      this.handleEdit(criteriaFields);
    }
    this.setDirty(false);
  };

  buildCriteriaMap = criteriaFields => {
    const criteriaMap = {};
    criteriaFields.forEach(c => {
      if (criteriaMap[c.name] === undefined) {
        criteriaMap[c.name] = [c];
      } else {
        criteriaMap[c.name].push(c);
      }
    });
    return criteriaMap;
  };

  getPostData = (name, operator, map) => {
    //
    const criteriaList = map[name];
    if (criteriaList === undefined) {
      return [""];
    }
    const paramList = criteriaList.filter(p => {
      return p.operator === operator;
    });
    if (paramList.length === 0) {
      return [""];
    }
    return paramList[0].values.map(v => {
      return v.value === undefined ? v : v.value;
    });
  };

  createAddSimpleMetaVehicleWExcludeFilterData = criteriaFields => {
    const criteriaMap = this.buildCriteriaMap(criteriaFields);

    const startYearList = this.getPostData("startYear", "is", criteriaMap);
    const startYear = startYearList.length > 0 ? startYearList[0] : "";
    const endYearList = this.getPostData("endYear", "is", criteriaMap);
    const endYear = endYearList.length > 0 ? endYearList[0] : "";

    const body = Object.assign({}, AddMetaVehicleFilter);
    body.filterName = this.state.metaVehicleFilter.name;
    body.dealerCode = this.state.metaVehicleFilter.dealerCode;
    body.make = this.state.metaVehicleFilter.make;
    body.models = this.getPostData("models", "is", criteriaMap);
    body.startYear = startYear;
    body.endYear = endYear;
    body.trims = this.getPostData("trims", "is", criteriaMap);
    body.engineTypes = this.getPostData("engineTypes", "is", criteriaMap);
    body.engineSizes = this.getPostData("engineSizes", "is", criteriaMap);
    body.driveTypes = this.getPostData("driveTypes", "is", criteriaMap);
    body.transmissionTypes = this.getPostData(
      "transmissionTypes",
      "is",
      criteriaMap
    );
    body.bodyTypes = this.getPostData("bodyTypes", "is", criteriaMap);
    body.fuelTypes = this.getPostData("fuelTypes", "is", criteriaMap);
    body.excludedModels = this.getPostData("models", "isnot", criteriaMap);
    body.excludedYears = this.getPostData("years", "isnot", criteriaMap);
    body.excludedTrims = this.getPostData("trims", "isnot", criteriaMap);
    body.excludedEngineTypes = this.getPostData(
      "engineTypes",
      "isnot",
      criteriaMap
    );
    body.excludedEngineSizes = this.getPostData(
      "engineSizes",
      "isnot",
      criteriaMap
    );
    body.excludedDriveTypes = this.getPostData(
      "driveTypes",
      "isnot",
      criteriaMap
    );
    body.excludedTransmissionTypes = this.getPostData(
      "transmissionTypes",
      "isnot",
      criteriaMap
    );
    const includedSetsAny = this.getPostData("sets", "isany", criteriaMap);
    const includedSetsAll = this.getPostData("sets", "isall", criteriaMap);
    if (includedSetsAny[0] !== "" || includedSetsAll[0] !== "") {
      if (includedSetsAny[0] !== "") {
        body.includedSets = includedSetsAny;
        body.includedSetOp = "any";
      } else {
        body.includedSets = includedSetsAll;
        body.includedSetOp = "all";
      }
    } else {
      body.includedSets = includedSetsAny;
      body.includedSetOp = "any";
    }
    // body.excludedSets = this.getPostData("sets", "isnot", criteriaMap);
    // body.excludedSetOp = "any"; // TODO
    const excludedSetsAny = this.getPostData("sets", "isnotany", criteriaMap);
    const excludedSetsAll = this.getPostData("sets", "isnotall", criteriaMap);
    if (excludedSetsAny[0] !== "" || excludedSetsAll[0] !== "") {
      if (excludedSetsAny[0] !== "") {
        body.excludedSets = excludedSetsAny;
        body.excludedSetOp = "any";
      } else {
        body.excludedSets = excludedSetsAll;
        body.excludedSetOp = "all";
      }
    } else {
      body.excludedSets = excludedSetsAny;
      body.excludedSetOp = "any";
    }
    body.excludedBodyTypes = this.getPostData(
      "bodyTypes",
      "isnot",
      criteriaMap
    );
    body.excludedFuelTypes = this.getPostData(
      "fuelTypes",
      "isnot",
      criteriaMap
    );

    return body;
  };

  getParamValue = (name, operator, paramName, map) => {
    const criteriaList = map[name];
    if (criteriaList === undefined) {
      if (name === "startYear" || name === "endYear") {
        return "";
      }
      return [];
    }
    const paramList = criteriaList.filter(p => {
      return (
        p.operator === operator ||
        (name === "sets" && p.operator.indexOf(operator) !== -1)
      );
    });
    if (paramList.length === 0) {
      return [];
    }
    const params = [];
    paramList.forEach(criteria => {
      if (criteria.name === "sets") {
        // set up includedSetOp or excludedSetOp
        const included = paramName.indexOf("included") !== -1;
        const anyOrAll = included
          ? criteria.operator.substr(2)
          : criteria.operator.substr(5);
        const setOp = included ? "includedSetOp" : "excludedSetOp";
        map[setOp] = anyOrAll;
      }
      if (criteria && criteria.values && criteria.values.length !== 0) {
        criteria.values.forEach(v => {
          const theValue = v.value === undefined ? v : v.value;
          params.push(theValue || "");
        });
      } else {
        params.push(paramName);
      }
    });
    if (name === "startYear" || name === "endYear") {
      // params[0] will be same as name if it is not selected
      return params.length === 0 || params[0] === name ? "" : params[0];
    }
    return params;
  };

  createEditSimpleMetaVehicleWExcludeFilterParams = criteriaFields => {
    const criteriaMap = this.buildCriteriaMap(criteriaFields);
    const metaVehicleFilterParams = Object.assign({}, MetaVehicleFilterParams);
    metaVehicleFilterParams.dealerCode = this.context.dealerCode;
    metaVehicleFilterParams.make = this.state.metaVehicleFilter.make;
    metaVehicleFilterParams.metaVehicleFilterId = this.state.metaVehicleFilter.metaVehicleFilterId;
    metaVehicleFilterParams.filterName = this.state.metaVehicleFilter.name;
    metaVehicleFilterParams.includedSets = this.getParamValue(
      "sets",
      "isa",
      "includedSets",
      criteriaMap
    ); // isany/isall
    metaVehicleFilterParams.excludedSets = this.getParamValue(
      "sets",
      "isnota",
      "excludedSets",
      criteriaMap
    ); // isnotany/isanotall
    metaVehicleFilterParams.models = this.getParamValue(
      "models",
      "is",
      "models",
      criteriaMap
    );
    metaVehicleFilterParams.excludedModels = this.getParamValue(
      "models",
      "isnot",
      "excludedModels",
      criteriaMap
    );
    metaVehicleFilterParams.trims = this.getParamValue(
      "trims",
      "is",
      "trims",
      criteriaMap
    );
    metaVehicleFilterParams.excludedTrims = this.getParamValue(
      "trims",
      "isnot",
      "excludedTrims",
      criteriaMap
    );
    metaVehicleFilterParams.startYear = this.getParamValue(
      "startYear",
      "is",
      "startYear",
      criteriaMap
    );
    metaVehicleFilterParams.endYear = this.getParamValue(
      "endYear",
      "is",
      "endYear",
      criteriaMap
    );
    metaVehicleFilterParams.excludedYears = this.getParamValue(
      "years",
      "isnot",
      "excludedYears",
      criteriaMap
    );
    metaVehicleFilterParams.engineTypes = this.getParamValue(
      "engineTypes",
      "is",
      "engineTypes",
      criteriaMap
    );
    metaVehicleFilterParams.excludedEngineTypes = this.getParamValue(
      "engineTypes",
      "isnot",
      "excludedEngineTypes",
      criteriaMap
    );
    metaVehicleFilterParams.engineSizes = this.getParamValue(
      "engineSizes",
      "is",
      "engineSizes",
      criteriaMap
    );
    metaVehicleFilterParams.excludedEngineSizes = this.getParamValue(
      "engineSizes",
      "isnot",
      "excludedEngineSizes",
      criteriaMap
    );
    metaVehicleFilterParams.driveTypes = this.getParamValue(
      "driveTypes",
      "is",
      "driveTypes",
      criteriaMap
    );
    metaVehicleFilterParams.excludedDriveTypes = this.getParamValue(
      "driveTypes",
      "isnot",
      "excludedDriveTypes",
      criteriaMap
    );
    metaVehicleFilterParams.transmissionTypes = this.getParamValue(
      "transmissionTypes",
      "is",
      "transmissionTypes",
      criteriaMap
    );
    metaVehicleFilterParams.excludedTransmissionTypes = this.getParamValue(
      "transmissionTypes",
      "isnot",
      "excludedTransmissionTypes",
      criteriaMap
    );
    metaVehicleFilterParams.bodyTypes = this.getParamValue(
      "bodyTypes",
      "is",
      "bodyTypes",
      criteriaMap
    );
    metaVehicleFilterParams.excludedBodyTypes = this.getParamValue(
      "bodyTypes",
      "isnot",
      "excludedBodyTypes",
      criteriaMap
    );
    metaVehicleFilterParams.fuelTypes = this.getParamValue(
      "fuelTypes",
      "is",
      "fuelTypes",
      criteriaMap
    );
    metaVehicleFilterParams.excludedFuelTypes = this.getParamValue(
      "fuelTypes",
      "isnot",
      "excludedFuelTypes",
      criteriaMap
    );
    const includedSetOp = criteriaMap["includedSetOp"]
      ? criteriaMap["includedSetOp"]
      : "any";
    metaVehicleFilterParams.includedSetOp = includedSetOp;
    const excludedSetOp = criteriaMap["excludedSetOp"]
      ? criteriaMap["excludedSetOp"]
      : "any";
    metaVehicleFilterParams.excludedSetOp = excludedSetOp;
    return metaVehicleFilterParams;
  };

  createFindMetaVehDetWFilterAttr = criteriaFields => {
    const criteriaMap = this.buildCriteriaMap(criteriaFields);

    const startYearList = this.getPostData("startYear", "is", criteriaMap);
    const startYear = startYearList.length > 0 ? startYearList[0] : "";
    const endYearList = this.getPostData("endYear", "is", criteriaMap);
    const endYear = endYearList.length > 0 ? endYearList[0] : "";
    const variant = this.context.makelist.filter(m => {
      return m.value === this.state.metaVehicleFilter.make;
    })[0].variant;

    const body = Object.assign({}, FindMetaVehDetWFilter);
    body.dealerCode = this.state.metaVehicleFilter.dealerCode;
    body.make = this.state.metaVehicleFilter.make;
    body.variant = variant;
    body.models = this.getPostData("models", "is", criteriaMap);
    body.startYear = startYear;
    body.endYear = endYear;
    body.trims = this.getPostData("trims", "is", criteriaMap);
    body.engineTypes = this.getPostData("engineTypes", "is", criteriaMap);
    body.engineSizes = this.getPostData("engineSizes", "is", criteriaMap);
    body.driveTypes = this.getPostData("driveTypes", "is", criteriaMap);
    body.transmissionTypes = this.getPostData(
      "transmissionTypes",
      "is",
      criteriaMap
    );
    body.bodyTypes = this.getPostData("bodyTypes", "is", criteriaMap);
    body.fuelTypes = this.getPostData("fuelTypes", "is", criteriaMap);
    body.excludedModels = this.getPostData("models", "isnot", criteriaMap);
    body.excludedYears = this.getPostData("years", "isnot", criteriaMap);
    body.excludedTrims = this.getPostData("trims", "isnot", criteriaMap);
    body.excludedEngineTypes = this.getPostData(
      "engineTypes",
      "isnot",
      criteriaMap
    );
    body.excludedEngineSizes = this.getPostData(
      "engineSizes",
      "isnot",
      criteriaMap
    );
    body.excludedDriveTypes = this.getPostData(
      "driveTypes",
      "isnot",
      criteriaMap
    );
    body.excludedTransmissionTypes = this.getPostData(
      "transmissionTypes",
      "isnot",
      criteriaMap
    );
    const includedSetsAny = this.getPostData("sets", "isany", criteriaMap);
    const includedSetsAll = this.getPostData("sets", "isall", criteriaMap);
    if (includedSetsAny[0] !== "" || includedSetsAll[0] !== "") {
      if (includedSetsAny[0] !== "") {
        body.includedSets = includedSetsAny;
        body.includedSetOp = "any";
      } else {
        body.includedSets = includedSetsAll;
        body.includedSetOp = "all";
      }
    } else {
      body.includedSets = includedSetsAny;
      body.includedSetOp = "any";
    }
    const excludedSetsAny = this.getPostData("sets", "isnotany", criteriaMap);
    const excludedSetsAll = this.getPostData("sets", "isnotall", criteriaMap);
    if (excludedSetsAny[0] !== "" || excludedSetsAll[0] !== "") {
      if (excludedSetsAny[0] !== "") {
        body.excludedSets = excludedSetsAny;
        body.excludedSetOp = "any";
      } else {
        body.excludedSets = excludedSetsAll;
        body.excludedSetOp = "all";
      }
    } else {
      body.excludedSets = excludedSetsAny;
      body.excludedSetOp = "any";
    }
    body.excludedBodyTypes = this.getPostData(
      "bodyTypes",
      "isnot",
      criteriaMap
    );
    body.excludedFuelTypes = this.getPostData(
      "fuelTypes",
      "isnot",
      criteriaMap
    );

    return body;
  };

  showVehiclePayload = () => {
    const criteriaFields = this.refs.criteriaContainerRef.getCriteriaFields();
    return this.createFindMetaVehDetWFilterAttr(criteriaFields);
  };

  validateField = (name, value) => {
    if (!value) {
      this.setState({
        errors: { name: xlate("xmm.portal.errors.name_required") }
      });
    } else {
      this.setState({ errors: { name: "" } });
    }
  };

  onBlur = cxEvent => {
    const { name, value } = cxEvent.target;
    // console.log("VehicleGroupForm", value);
    this.validateField(name, value);
  };

  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (!this.state.metaVehicleFilter[name] && value === "") {
      return;
    }

    this.setState(
      prevState => {
        // add logic if any
        if (value !== prevState.metaVehicleFilter.name) {
          window.dispatchEvent(
            new CustomEvent("dirtyVehicleGroupFormEvent", {
              detail: { dirty: true },
              bubbles: true,
              cancelable: true
            })
          );
        }
        const { errors } = prevState;
        return {
          metaVehicleFilter: {
            ...prevState.metaVehicleFilter,
            [name]: sanitize(value)
          },
          errors
        };
      },
      () => {
        // callback parent handler to push changes
      }
    );
  };

  onRemoveVehicleGroup = (eventKey, event) => {
    // console.log("selected eventKey:", eventKey);
    gtmEvents.gtmTrackEvent("xmm.vehiclegroups.delete_click");
    window.dispatchEvent(
      new CustomEvent("removeVehicleGroupEvent", {
        detail: eventKey,
        bubbles: true,
        cancelable: true
      })
    );
    // window.document.dispatchEvent(new MouseEvent("click"));
    // callback something
  };

  onDirtyVehicleGroupFormEvent = event => {
    const { dirty } = event.detail;
    if (dirty !== this.state.dirty) {
      const { currentPage } = this.context;
      currentPage.dirty = dirty;
      this.setState({ dirty });
    }
  };

  setMetaVehicleFilter = (metaVehicleFilter, criteriaOptionsMap) => {
    this.refs.criteriaContainerRef.setCriteriaFields(
      metaVehicleFilter,
      criteriaOptionsMap
    );
    this.setState({ metaVehicleFilter, errors: {} });
  };

  setLoadingMask = showMask => {
    this.setState({ showMask });
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  render() {
    const { className, formClassName } = this.props;
    const hasRules =
      this.state.metaVehicleFilter.metaVehicleFilterId === "" ||
      this.state.metaVehicleFilter.countOperationRules === undefined ||
      this.state.metaVehicleFilter.countOperationRules > 0;
    const template =
      this.state.metaVehicleFilter.countOperationRules > 1
        ? this.usedByRulesLabel
        : this.usedByOor1RuleLabel;
    const ruleText =
      this.state.metaVehicleFilter.metaVehicleFilterId === "" ||
      this.state.metaVehicleFilter.countOperationRules === undefined
        ? ""
        : template.replace(
            "%1",
            this.state.metaVehicleFilter.countOperationRules
          );
    const isSelected =
      this.state.metaVehicleFilter && this.state.metaVehicleFilter.make;
    const { errors, showMask } = this.state;
    const formClass = showMask ? "xmm-opaque" : "";
    const loadingIndicator = showMask ? (
      <LoadingIndicator size="large" />
    ) : null;
    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    return (
      <React.Fragment>
        <div className={className}>
          <div className={!isSelected ? "" : "hide"}>
            <div className="xmm-modal-help-text">
              {xlate("xmm.portal.vehiclegroups.please_add_vehicle_group")}
            </div>
          </div>
          <div className={isSelected ? formClass : "hide"}>
            <Grid>
              <Row>
                <Col xs={4} md={4}>
                  <h3>{this.state.metaVehicleFilter.make}</h3>
                </Col>
                <Col xs={8} md={8}>
                  <div className="pull-right">
                    {statusBox}{" "}
                    <Badge htmlId="badgeBlue" color="blue">
                      {ruleText}
                    </Badge>{" "}
                    <Button
                      htmlId="saveVehicleGroupAction"
                      form="vehicleGroupForm"
                      buttonStyle="primary"
                      disabled={!this.state.dirty}
                      onClick={this.handleSave}
                    >
                      {this.saveButtonLabel}
                    </Button>
                    <DropdownButton
                      title={<IconMore />}
                      id="operationsActionBtn"
                      className="xmm-dotted-dropdown btn--icon"
                      pullRight
                    >
                      <DropdownMenuItem
                        htmlId="singleDeleteBtn"
                        eventKey={this.state.metaVehicleFilter}
                        disabled={hasRules}
                        onSelect={this.onRemoveVehicleGroup}
                      >
                        <FormattedMessage
                          defaultMessage="Delete"
                          id="xmm.portal.common.delete_button"
                        />
                      </DropdownMenuItem>
                    </DropdownButton>
                  </div>
                </Col>
              </Row>
              <div className={formClassName}>
                <form id="vehicleGrpForm" autoComplete="off">
                  <Row>
                    <Col xs={12} md={12} className="full-col">
                      <Col xs={11} md={11}>
                        <TextInput
                          htmlId="fullPageModalWithTextInput"
                          label={this.nameLabel}
                          name="name"
                          maxLength="50"
                          autoComplete="off"
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          value={this.state.metaVehicleFilter.name || ""}
                          displayLabel={true}
                          error={errors.name}
                        />
                      </Col>
                      <Col xs={1} md={1}>
                        &nbsp;
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="full-col">
                      <label
                        className="textInput-label control-label"
                        style={{ paddingLeft: "15px" }}
                      >
                        {this.criteriaLabel}
                      </label>
                      <CriteriaContainer ref="criteriaContainerRef" />
                    </Col>
                  </Row>
                </form>
                <Row>
                  <Col xs={12} md={12}>
                    <ShowVehiclesGrid
                      ref="showVehicleGridRef"
                      disabled={!this.state.metaVehicleFilter.make}
                      payloadFunc={this.showVehiclePayload}
                    />
                  </Col>
                </Row>
              </div>
            </Grid>
          </div>
          {loadingIndicator}
        </div>
      </React.Fragment>
    );
  }
}

VehicleGroupForm.propTypes = {
  className: PropTypes.string,
  formClassName: PropTypes.string,
  sortedVehicleGroups: PropTypes.array
};

VehicleGroupForm.defaultProps = {
  className: "xmm-manage-vehicle-root",
  formClassName: "xmm-manage-vehicle-form"
};
