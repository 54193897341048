/* eslint-disable no-console */
import React, { Component } from "react";
// import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import CardGroup from "@cx/ui/CardGroup";
import SelectInput from "@cx/ui/SelectInput";
import Button from "@cx/ui/Button";
import { toast } from "@cx/ui/Toast";
import { AppContext } from "../../../components/app-context";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../commonUtil/utils/string";
import { baseUrl, makeSecureRestApi } from "../../../api/xmmAxios";
import { formatDateTimezone } from "../../../commonUtil/utils/date";
import { isEmpty } from "../../../commonUtil/utils/object";
import AlertBox from "../../../commonUtil/components/templates/AlertBox";
import StatusBox from "../../../commonUtil/components/templates/StatusBox";
import { xlate } from "../../../commonUtil/i18n/locales";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";

class ReportsPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.cancelDealerPublish = this.cancelDealerPublish.bind(this);
    this.compileReports = this.compileReports.bind(this);
    const { dealerCode, makelist, reportsGrid, makeVariantMap } = context;
    const make = isEmpty(reportsGrid.make)
      ? makelist[0].make
      : reportsGrid.make;
    const pricingReportMetadata = {};
    const isCappedPricing = this.checkCappedPricing(makeVariantMap[make]);
    this.initializeLocaleValues();
    this.state = {
      dealerCode,
      make,
      makelist,
      pricingReportMetadata,
      isCappedPricing
    };
  }
  componentDidMount() {
    this.getReportsByMake();
  }
  checkCappedPricing = makeObj => {
    return (
      makeObj.hasOwnProperty("cappedPricing") && makeObj.cappedPricing === 1
    );
  };
  initializeLocaleValues() {
    this.reportsTitle = xlate("xmm.portal.nav.reports");
    this.reportPageInstructions = xlate(
      "xmm.portal.common.report_page_instructions"
    );
    this.compileReportsLabel = xlate(
      "xmm.portal.common.compile_reports_button"
    );
    this.downloadLabel = xlate("xmm.portal.common.download_button");
    this.cancelLabel = xlate("xmm.portal.common.cancel_button");
    this.menuPricingHeaderLabel = xlate(
      "xmm.portal.common.menu_package_pricing_details"
    );
    this.operationPricingHeaderLabel = xlate(
      "xmm.portal.common.operation_pricing_details"
    );
    this.vehicleOperationHeaderLabel = xlate(
      "xmm.portal.common.menu_package_pricing_details"
    );
    this.detailMenuReportLabel = xlate(
      "xmm.portal.common.detail_menu_report_lbl"
    );
    this.detailVehicleOperationReportLabel = xlate(
      "xmm.portal.common.detail_vehicle_operation_report_lbl"
    );
  }
  getReportsByMake() {
    const { dealerCode, make } = this.state;
    const { makeVariantMap } = this.context;
    const { variant } = makeVariantMap[make];
    const isCappedPricing = this.checkCappedPricing(makeVariantMap[make]);

    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/services/dealer/${dealerCode}/make/${make}/variant/${variant}/reports`,
        method: "get"
      },
      response => {
        const { pricingReportMetadata, statusCode, success } = response;
        if (success && statusCode === 0 && pricingReportMetadata) {
          this.setState({ pricingReportMetadata, isCappedPricing });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : `There was an error while getting reports for ${make}.`;
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  compileReports(event) {
    // /rest/services/dealer/XTIMEMOTORS14/make/ACURA/variant/ACURAUSA_ENH2/reports?_dc=158586734870
    const { dealerCode, make } = this.state;
    const { locale, makeVariantMap, user } = this.context;
    const { email, username } = user;
    const { variant } = makeVariantMap[make];
    const data = {
      authId: "",
      make,
      variant,
      dealerCode,
      username,
      inHours: 0,
      adminEmail: "",
      userEmail: toEmptyStringIfUndefined(email),
      isSnapshot: 1,
      isAutoPublish: 0,
      locale
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/publishDealerToRuntime2",
        method: "post",
        data
      },
      response => {
        this.getReportsByMake();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : `There was an error while compiling reports for ${make}.`;
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  cancelDealerPublish() {
    const { dealerCode, make } = this.state;
    const { makeVariantMap } = this.context;
    const { variant } = makeVariantMap[make];
    const data = {
      authId: "",
      make,
      variant,
      dealerCode,
      isSnapshot: 1
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/cancelDealerPublishToRuntime",
        method: "post",
        data
      },
      response => {
        this.getReportsByMake();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : `There was an error while canceling reports for ${make}.`;
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  // ----------------------------------------------------------------------------------------------------------------
  // NOTE: Can't use axios since content-disposition is not exposed in its headers so that we can't get the filename
  // ----------------------------------------------------------------------------------------------------------------
  // downloadReport(type) {
  //   const { dealerCode, make } = this.state;
  //   const { makeVariantMap } = this.context;
  //   const { variant } = makeVariantMap[make];
  // const headers = {
  //   Accept: "application/octet-stream",
  //   "Content-Type": "application/octet-stream"
  // };
  // const responseType = "blob";
  // makeSecureRestApi(
  //   {
  //     url: `/ops/proxyapi/oeproxyzip/rest/services/dealer/${dealerCode}/make/${make}/variant/${variant}/oReport`,
  //     method: "get",
  //     params: { type },
  //     headers,
  //     responseType
  //   },
  //   (data, headers) => {
  //     // const resp = response;
  //     // eslint-disable-next-line no-console
  //     this.exportZipFile(data);
  //     console.log(headers);
  //   },
  //   error => {
  //     const msg = error["message"]
  //       ? error.message
  //       : `There was an error while canceling reports for ${make}.`;
  //     toast.error(msg, {
  //       closeOnClick: true
  //     });
  //   }
  // );
  // }
  // exportZipFile(zip) {
  //   // const exportedFilename = "report.zip";
  //   const blob = new Blob([zip], {
  //     type: "application/octet-stream"
  //   });
  //   if (navigator.msSaveBlob) {
  //     // IE 10+
  //     navigator.msSaveBlob(blob);
  //   } else {
  //     const link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       // feature detection
  //       // Browsers that support HTML5 download attribute
  //       const url = URL.createObjectURL(blob);
  //       document.body.appendChild(link);
  //       link.setAttribute("href", url);
  //       // link.setAttribute("download", exportedFilename);
  //       link.style.display = "none";
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // }
  downloadReport(type) {
    const { dealerCode, make } = this.state;
    const { makeVariantMap } = this.context;
    const { variant } = makeVariantMap[make];
    const url = `${baseUrl}/ops/proxyapi/oeproxyzip/rest/services/dealer/${dealerCode}/make/${make}/variant/${variant}/oReport?type=${type}`;
    // Creating new DOM element <a>

    // IE browser - this method that allows to save file sent via response headers
    if (window.navigator.msSaveOrOpenBlob) {
      const strWindowFeatures =
        "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
      window.open(url, "Report download", strWindowFeatures);
    } else {
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.style.display = "none";
      document.body.appendChild(link);
      link.setAttribute("href", url);
      link.click();
      document.body.removeChild(link);
    }
  }
  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { make } = this.state;
    if (isDifferentValue(make, value)) {
      const { reportsGrid, makeVariantMap } = this.context;
      reportsGrid.make = value;
      const isCappedPricing = this.checkCappedPricing(makeVariantMap[make]);
      this.setState({ make: value, isCappedPricing }, () => {
        this.getReportsByMake();
      });
    }
  };
  getMenuAndOperationCompleted(pricingReportsMetadata) {
    let menuCompleted = false;
    let operationCompleted = false;
    if (!pricingReportsMetadata || pricingReportsMetadata.length === 0) {
      // return { menuCompleted, operationCompleted };
    } else {
      pricingReportsMetadata.forEach(reportMetaData => {
        const { status, type } = reportMetaData;
        if (type === "menu") {
          menuCompleted = status === COMPLETE;
        } else if (type === "operation") {
          operationCompleted = status === COMPLETE;
        }
      });
    }
    return { menuCompleted, operationCompleted };
  }
  getCompileStatusMessag() {
    const { locale } = this.context;
    const { make, pricingReportMetadata } = this.state;
    const compileStatus = pricingReportMetadata.status;
    const { pricingReportsMetadata } = pricingReportMetadata;
    const {
      menuCompleted,
      operationCompleted
    } = this.getMenuAndOperationCompleted(pricingReportsMetadata);
    let compileStatusMessage = "";
    let statusType = "";
    if (compileStatus === COMPLETE) {
      const { snapshotGenerationHoursAgo } = pricingReportMetadata;
      const nowMillisecs = new Date().getTime();
      const snapshotGenerationMillisecs =
        nowMillisecs - snapshotGenerationHoursAgo * 60 * 60 * 1000;
      const snapshotGenerationDate = new Date(snapshotGenerationMillisecs);
      const dateStr = formatDateTimezone(snapshotGenerationDate, true, locale);
      compileStatusMessage = xlate(
        "xmm.portal.reports.compile_on_date"
      ).replace("%1", dateStr);
      statusType = "text";
    } else if (compileStatus === IN_PROGRESS) {
      // const { cancelAllowed } = pricingReportMetadata;
      // const className = cancelAllowed ? "" : "hide";
      compileStatusMessage = xlate(
        "xmm.portal.reports.compile_in_progress"
      ).replace("%1", make);
      // compileStatusMessage = (
      //   <div style={{ display: "inline-block" }}>
      //     <labe>{msg}</labe>{" "}
      //     <Button
      //       htmlId="cancelReportLink"
      //       className={className}
      //       buttonStyle="link"
      //       size="sm"
      //       onClick={() => {
      //         this.cancelDealerPublish();
      //       }}
      //     >
      //       Cancel
      //     </Button>
      //   </div>
      // );
      statusType = "pending";
    } else {
      statusType = "info";
      compileStatusMessage = xlate("xmm.portal.reports.compile_no_reports");
    }
    const { cancelAllowed } = pricingReportMetadata;
    return {
      compileStatus,
      compileStatusMessage,
      statusType,
      cancelAllowed,
      menuCompleted,
      operationCompleted
    };
  }
  render() {
    const { make, makelist, isCappedPricing } = this.state;
    const {
      compileStatus,
      compileStatusMessage,
      statusType,
      cancelAllowed,
      menuCompleted,
      operationCompleted
    } = this.getCompileStatusMessag();
    const statusBox =
      compileStatusMessage !== "" && compileStatus !== IN_PROGRESS ? (
        <StatusBox
          htmlId="statusBox"
          type={statusType}
          message={compileStatusMessage}
          errorInTooltip={this.state.errorInTooltip}
        />
      ) : (
        ""
      );
    const msgSection =
      compileStatus === IN_PROGRESS ? (
        <AlertBox
          htmlId="cancelReportBanner"
          type="info"
          closeButton={true}
          linkHtml={
            cancelAllowed ? (
              <Button
                htmlId="cancelAllOpCodeValidation"
                buttonStyle="link"
                onClick={this.cancelDealerPublish}
              >
                {this.cancelLabel}
              </Button>
            ) : (
              ""
            )
          }
          message={compileStatusMessage}
          loading={true}
        />
      ) : (
        ""
      );

    const menuIcon = !isCappedPricing ? (
      <span style={{ height: "26px" }}> &nbsp; </span>
    ) : (
      <Tooltip
        htmlId="menuTooltip"
        position="right"
        tooltipContent={xlate("xmm.portal.reports.capped_pricing_tip")}
      >
        <IconInfoOutline htmlId="iconMenu" />
      </Tooltip>
    );
    const compileReportDisabled = compileStatus === IN_PROGRESS;
    const reportsDownloadable = compileStatus === COMPLETE;
    return (
      <div className="xmm-center-container">
        <h3>{this.reportsTitle}</h3>
        <div>{msgSection}</div>
        <div className="xmm-main-help-text">{this.reportPageInstructions}</div>
        <div className="content-header">
          <div className="xmm-main-title">
            <SelectInput
              htmlId="makeFilter"
              name="make"
              label="Make"
              className="xmm-dealermenu-make-label"
              placeholder="Select a Make"
              displayPlaceholder={false}
              displayLabel={false}
              layout="horizontal"
              onChange={this.onChange}
              options={makelist}
              value={make}
              maxHeight={200}
            />
          </div>
          <div>
            {statusBox}{" "}
            <Button
              htmlId="compileReports"
              buttonStyle="primary"
              disabled={compileReportDisabled}
              onClick={this.compileReports}
            >
              {this.compileReportsLabel}
            </Button>
          </div>
        </div>
        <CardGroup htmlId="CardGroup">
          <Card htmlId="CardGroupExample1">
            <div className="settings-card-header">
              <div className="header-left">
                <h4>
                  {this.menuPricingHeaderLabel}
                  {menuIcon}
                </h4>
              </div>
            </div>

            <div>{this.detailMenuReportLabel}</div>
            <Button
              htmlId="ButtonLink"
              buttonStyle="link"
              className="pull-right"
              disabled={!reportsDownloadable || !menuCompleted}
              onClick={() => {
                this.downloadReport("menu");
              }}
            >
              {this.downloadLabel}
            </Button>
          </Card>

          <Card
            htmlId="CardGroupExample2"
            header={this.operationPricingHeaderLabel}
          >
            <div>{this.detailVehicleOperationReportLabel}</div>
            <Button
              htmlId="ButtonLink"
              buttonStyle="link"
              className="pull-right"
              disabled={!reportsDownloadable || !operationCompleted}
              onClick={() => {
                this.downloadReport("operation");
              }}
            >
              {this.downloadLabel}
            </Button>
          </Card>
          <Card htmlId="CardGroupExample3" className="hidden" />
        </CardGroup>
      </div>
    );
  }
}

const COMPLETE = "COMPLETE";
const IN_PROGRESS = "IN_PROGRESS";

export default ReportsPage;
