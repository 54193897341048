/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import { PropTypes } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import * as gtmEvents from "../../../../../utils/gtag-eventlist";
import {
  priceFormatter,
  floatParser
} from "../../../../../../commonUtil/utils/formatter";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import {
  isArrayExist,
  getMakeFromDealerCatalogs,
  doesEmpty
} from "../../../../../../commonUtil/utils/object";
import {
  convertMinutesToTenths,
  convertMinutesToHundredths,
  defaultToZeroIfNullOrEmpty
} from "../../../../../../commonUtil/utils/value";
import { OperationContext } from "../../../operation-context";
import CustomLoadingOverlay from "../../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import CustomNoRowsOverlay from "../../../../../../commonUtil/components/loadingmask/CustomNoRowsOverlay";
import { loadAgGridLocale } from "../../../../../../i18n/LocaleSender";
export default class Preview extends Component {
  static contextType = OperationContext;
  constructor(props, context) {
    super(props, context);
    // Bind functions
    this.onPreviewSearchHandler = this.onPreviewSearchHandler.bind(this);
    this.clearPreviewFilters = this.clearPreviewFilters.bind(this);
    // console.log("preview > operation-context", context);
    const { localeStrings } = context;
    this.initializeLocaleValues(localeStrings);
    this.state = {
      pricingMethod: null,
      status: "pending",
      rowData: null,
      services: [],
      columnDefs: [],
      totalAvg: 0,
      totalMin: 0,
      totalMax: 0,
      // ag-grid props
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        suppressMenu: true,
        headerClass: "ag-numeric-header",
        cellStyle: {
          color: "black",
          textAlign: "right"
        },
        minWidth: 10
      },
      columnTypes: {
        nonEditableColumn: { editable: false },
        priceColumn: {
          editable: false,
          valueFormatter: priceFormatter,
          valueParser: floatParser,
          cellStyle: {
            color: "black",
            textAlign: "right"
          }
        },
        numberFilterColumn: {
          editable: false,
          filter: "agNumberColumnFilter",
          filterParams: {
            nullComparator: {
              equals: false,
              lessThan: false,
              greaterThan: false
            }
          }
        }
      },
      multiSortKey: "ctrl",
      sortingOrder: ["desc", "asc", null],
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      localeTextFunc(key, defaultValue) {
        console.log(`${key}: ${defaultValue}`);
        return defaultValue;
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false
      },
      components: {
        timeCellRenderer
      },
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomNoRowsOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRows: true,
        noRowsMessageFunc() {
          return "No records found.";
        }
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" }
          /* Below functions are disabled for preview
          {
            statusPanel: "agAggregationComponent",
            statusPanelParams: {
              // possible values are: 'count', 'sum', 'min', 'max', 'avg'
              aggFuncs: ["count", "avg", "sum", "min", "max"]
            }
          }
          */
        ]
      }
    };
  }
  initializeLocaleValues(localeStrings) {
    this.averageLabel = localeStrings["xmm.portal.ag_grid.average"];
    this.minLabel = localeStrings["xmm.portal.ag_grid.min"];
    this.maxLabel = localeStrings["xmm.portal.ag_grid.max"];
  }
  updateColumnDefs = () => {
    const { makelist, localeStrings } = this.context.appContext;
    const filteredMake = makelist.filter(
      m => m.value === this.props.loadOperation.make
    );
    const hidePartsLaborColumn =
      filteredMake &&
      filteredMake.length !== 0 &&
      filteredMake[0].pricingMethod === 2; // Flat pricing
    // For Flat Pricing and Calculated Pricing, the opcode column would not be displayed by default.
    const hideOpcodeColumn =
      filteredMake &&
      filteredMake.length !== 0 &&
      filteredMake[0].pricingMethod !== 0;

    const isCalculatedFlow =
      filteredMake &&
      filteredMake.length !== 0 &&
      filteredMake[0].pricingMethod === 1; // Calculated pricing

    const isNoPricingFlow =
      filteredMake &&
      filteredMake.length !== 0 &&
      filteredMake[0].pricingMethod === 0; // No pricing

    if (hidePartsLaborColumn) {
      this.setState({
        columnDefs: [
          {
            headerName: localeStrings["xmm.portal.grid.vehicle"],
            headerClass: "ag-text-header",
            groupId: "vehicleGroup",
            children: [
              {
                headerName: localeStrings["xmm.portal.grid.model"],
                headerClass: "ag-text-header",
                field: "model",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  textAlign: "left"
                }
              },
              {
                headerName: localeStrings["xmm.portal.grid.year"],
                headerClass: "ag-text-header",
                field: "year",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                },
                valueParser: floatParser
              },
              {
                headerName: localeStrings["xmm.portal.grid.attributes"],
                headerClass: "ag-text-header",
                field: "vehicleAttributes",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                }
              }
            ]
          },
          {
            headerName: localeStrings["xmm.portal.grid.opcode"],
            headerClass: "ag-text-header",
            field: "dmsOpcode",
            pinned: "left",
            hide: hideOpcodeColumn,
            sortingOrder: ["asc", "desc"],
            cellStyle: {
              color: "black",
              textAlign: "left"
            },
            minWidth: 110,
            maxWidth: 150
          },
          {
            headerName: localeStrings["xmm.portal.grid.pricing_source"],
            headerClass: "ag-text-header",
            field: "pricingSource",

            cellStyle: {
              color: "black",
              textAlign: "left"
            },
            cellClass(params) {
              return params.value === "Dealer" ? "rag-green" : "";
            }
          },
          {
            headerName: localeStrings["xmm.portal.grid.package_pricing"],
            groupId: "packageGroup",
            cellStyle: {
              color: "black",
              textAlign: "center"
            },
            children: [
              {
                headerName: localeStrings["xmm.portal.preview.grid.total"],
                field: "flatTotalPrice",
                type: "priceColumn",
                columnGroupShow: "open"
                // width: 120
              }
            ]
          },
          {
            headerName: localeStrings["xmm.portal.grid.alacarte_pricing"],
            groupId: "alacarteGroup",
            children: [
              {
                headerName: localeStrings["xmm.portal.preview.grid.total"],
                field: "flatTotalUnscheduledPrice",
                type: "priceColumn",
                columnGroupShow: "open"
                // width: 120
              }
            ]
          }
        ]
      });
    } else if (isCalculatedFlow) {
      this.setState({
        columnDefs: [
          {
            headerName: localeStrings["xmm.portal.grid.vehicle"],
            headerClass: "ag-text-header",
            groupId: "vehicleGroup",
            children: [
              {
                headerName: localeStrings["xmm.portal.grid.model"],
                headerClass: "ag-text-header",
                field: "model",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  textAlign: "left"
                }
              },
              {
                headerName: localeStrings["xmm.portal.grid.year"],
                headerClass: "ag-text-header",
                field: "year",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                },
                valueParser: floatParser
                /*
                cellClassRules: {
                  "rag-green": "x < 2018",
                  "rag-amber": "x >= 2000 && x < 2010",
                  "rag-red": "x >= 1990",
                  "rag-green-outer": "x == 2019"
               }
               */
              },
              {
                headerName: localeStrings["xmm.portal.grid.attributes"],
                headerClass: "ag-text-header",
                field: "vehicleAttributes",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                }
                // width: 120
              },
              {
                headerName: localeStrings["xmm.portal.grid.opcode"],
                headerClass: "ag-text-header",
                field: "dmsOpcode",
                pinned: "left",
                hide: hideOpcodeColumn,
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                },
                minWidth: 110,
                maxWidth: 150
              }
            ]
          },
          {
            headerName: localeStrings["xmm.portal.grid.pricing_source"],
            headerClass: "ag-text-header",
            field: "pricingSource",
            // width: 100,
            cellStyle: {
              color: "black",
              textAlign: "left"
            },
            cellClass(params) {
              return params.value === "Dealer" ? "rag-green" : "";
            }
          },
          {
            headerName: localeStrings["xmm.portal.grid.rate_code"],
            headerClass: "ag-text-header",
            field: "laborRateCode",
            sortingOrder: ["desc", null],
            cellStyle: {
              color: "black",
              textAlign: "left"
            }
            // width: 95
          },
          {
            headerName: localeStrings["xmm.portal.grid.package_pricing"],
            groupId: "packageGroup",
            cellStyle: {
              color: "black",
              textAlign: "center"
            },
            children: [
              {
                headerName: localeStrings["xmm.portal.preview.grid.time"],
                columnGroupShow: "open",
                field: "scheduledLaborTime",
                // cellRenderer: "timeCellRenderer",
                cellStyle: {
                  color: "black",
                  textAlign: "right"
                }
              },
              {
                headerName: localeStrings["xmm.portal.preview.grid.labor"],
                field: "scheduledPrice",
                type: "priceColumn",
                columnGroupShow: "open"
              },
              {
                headerName: localeStrings["xmm.portal.preview.grid.parts"],
                field: "partsPrice",
                type: "priceColumn",
                columnGroupShow: "open"
              },
              {
                headerName: localeStrings["xmm.portal.preview.grid.total"],
                field: "totalPrice",
                type: "priceColumn",
                columnGroupShow: "open"
              }
            ]
          },
          {
            headerName: localeStrings["xmm.portal.grid.alacarte_pricing"],
            groupId: "alacarteGroup",
            children: [
              {
                headerName: localeStrings["xmm.portal.preview.grid.time"],
                field: "unscheduledLaborTime",
                columnGroupShow: "open",
                cellStyle: {
                  color: "black",
                  textAlign: "right"
                }
              },
              {
                headerName: localeStrings["xmm.portal.preview.grid.labor"],
                field: "unscheduledPrice",
                type: "priceColumn",
                columnGroupShow: "open"
                /*
                cellStyle(params) {
                  const color = numberToColor(params.value);
                  return { backgroundColor: color };
                }
              */
              },
              {
                headerName: localeStrings["xmm.portal.preview.grid.parts"],
                field: "unscheduledPartsPrice",
                type: "priceColumn",
                columnGroupShow: "open"
              },
              {
                headerName: localeStrings["xmm.portal.preview.grid.total"],
                field: "unscheduledTotalPrice",
                type: "priceColumn",
                columnGroupShow: "open"
              }
            ]
          }
        ]
      });
    } else if (isNoPricingFlow) {
      this.setState({
        columnDefs: [
          {
            headerName: localeStrings["xmm.portal.grid.vehicle"],
            headerClass: "ag-text-header",
            groupId: "vehicleGroup",
            children: [
              {
                headerName: localeStrings["xmm.portal.grid.model"],
                headerClass: "ag-text-header",
                field: "model",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  textAlign: "left"
                }
              },
              {
                headerName: localeStrings["xmm.portal.grid.year"],
                headerClass: "ag-text-header",
                field: "year",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                },
                valueParser: floatParser
              },
              {
                headerName: localeStrings["xmm.portal.grid.attributes"],
                headerClass: "ag-text-header",
                field: "vehicleAttributes",
                pinned: "left",
                sortingOrder: ["asc", "desc"],
                cellStyle: {
                  color: "black",
                  textAlign: "left"
                }
              }
            ]
          },
          {
            headerName: localeStrings["xmm.portal.grid.opcode"],
            headerClass: "ag-text-header",
            field: "dmsOpcode",
            pinned: "left",
            sortingOrder: ["asc", "desc"],
            cellStyle: {
              color: "black",
              textAlign: "left"
            },
            minWidth: 110,
            maxWidth: 150
          }
        ]
      });
    }
  };

  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  // Testing GTM event
  simulateClick = url => {
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    });
    window.document.dispatchEvent(event);
    gtmEvents.trackGtagPageview("/operations/operations/preview");
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.updateColumnDefs();
    // Always load Preview data with rest API - no cache
    this.loadPreviewData();
    const defaultSortModel = [
      {
        colId: "model",
        sort: "asc"
      },
      {
        colId: "year",
        sort: "asc"
      }
    ];
    params.api.setSortModel(defaultSortModel);
    this.sizeToFit();
    // GTM - push pageview event to dataLayer
    this.simulateClick("preview");
    // gtmEvents.gtmTrackEvent("xmm.operations.preview_tab_click");
  };

  loadPreviewData() {
    const { dealerCode, locale } = this.context.appContext;
    const { make, variant, serviceId } = this.props.loadOperation;
    const url = `/ops/proxyapi/oeproxy/rest/services/dealer/${dealerCode}/make/${make}/variant/${variant}/oPreview`;
    makeSecureRestApi(
      {
        url,
        method: "get",
        params: { serviceId, locale }
      },
      data => {
        if (data) {
          const servicelist = data.laborPreview;
          let datalist = [];
          let services = [];
          // check if response has single object or array of objects
          if (!isArrayExist(servicelist) && typeof servicelist === "object") {
            datalist.push(servicelist);
          } else if (isArrayExist(servicelist) && servicelist.length > 0) {
            datalist = servicelist;
          }
          // transform unscheduled/ scheduled time based on "Labor Time scale" of Catalog
          const { dealerCatalogs } = this.context.appContext;
          const cloneCatalog = getMakeFromDealerCatalogs(dealerCatalogs, make);
          if (cloneCatalog && datalist.length > 0) {
            this.getTotals(datalist);
            services = datalist.map(s => {
              const service = Object.assign({}, s);
              service.scheduledLaborTime = convertLaborTime(
                service.scheduledLaborTime,
                cloneCatalog
              );
              service.unscheduledLaborTime = convertLaborTime(
                service.unscheduledLaborTime,
                cloneCatalog
              );
              return service;
            });
          }

          this.setState(
            {
              status: "success",
              services
            },
            (prevState, props) => {
              this.gridApi.setRowData(services); // This setter will update ag-grid rowData
              this.sizeToFit();
            }
          );
        }
      }
    );
  }
  getTotals(datalist) {
    const arrTotals = [];
    let pkgTotal = 0;
    let alaTotal = 0;
    const totalRows = datalist.length;
    const { makelist } = this.context.appContext;
    const filteredMake = makelist.filter(
      m => m.value === this.props.loadOperation.make
    );
    const pricingMethod =
      filteredMake &&
      filteredMake.length !== 0 &&
      filteredMake[0].pricingMethod;
    if (datalist.length > 0) {
      datalist.forEach(service => {
        // Flat Pricing flow
        if (!doesEmpty(pricingMethod) && pricingMethod === 2) {
          const flatTotalPrice = !doesEmpty(service.flatTotalPrice)
            ? service.flatTotalPrice
            : 0;
          const flatTotalUnscheduledPrice = !doesEmpty(
            service.flatTotalUnscheduledPrice
          )
            ? service.flatTotalUnscheduledPrice
            : 0;
          pkgTotal += flatTotalPrice;
          alaTotal += flatTotalUnscheduledPrice;
          arrTotals.push(flatTotalPrice);
          arrTotals.push(flatTotalUnscheduledPrice);
          // calculated flow
        } else if (!doesEmpty(pricingMethod) && pricingMethod === 1) {
          const totalPrice = !doesEmpty(service.totalPrice)
            ? service.totalPrice
            : 0;
          const unscheduledTotalPrice = !doesEmpty(
            service.unscheduledTotalPrice
          )
            ? service.unscheduledTotalPrice
            : 0;
          pkgTotal += totalPrice;
          alaTotal += unscheduledTotalPrice;
          arrTotals.push(totalPrice);
          arrTotals.push(unscheduledTotalPrice);
        }
      });
      let totalAvg = (pkgTotal + alaTotal) / (totalRows * 2);
      totalAvg = Math.ceil(totalAvg);
      const totalMin = Math.min(...arrTotals);
      const totalMax = Math.max(...arrTotals);
      this.setState({
        totalAvg,
        totalMin,
        totalMax,
        pricingMethod
      });
      console.log(pricingMethod, totalAvg, totalMax, totalMin);
    }
  }
  /* Action event to clear column filters */
  clearPreviewFilters() {
    if (this.gridApi) {
      document.querySelector("#preview-search-box").value = "";
      this.gridApi.setQuickFilter("");
      this.gridApi.onFilterChanged();
      this.gridApi.setFilterModel(null);
    }
  }
  // Quick filter handler
  onPreviewSearchHandler = event => {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(
        document.querySelector("#preview-search-box").value
      );
    }
  };
  render() {
    const { localeStrings } = this.context;
    const {
      totalAvg,
      totalMax,
      totalMin,
      pricingMethod,
      services
    } = this.state;
    const clsHideTotals =
      services.length > 0 && pricingMethod !== 0
        ? "xmm-rates-container"
        : "hide";
    const totalLabels = (
      <div className="xmm-rates-content">
        <span>
          {this.averageLabel} <br />
          <b>
            {"$"}
            {totalAvg.toFixed(2)}
          </b>
          <br />
        </span>
        <span>
          {this.minLabel} <br />
          <b>
            {"$"}
            {totalMin.toFixed(2)}{" "}
          </b>
          <br />
        </span>
        <span>
          {this.maxLabel} <br />
          <b>
            {"$"}
            {totalMax.toFixed(2)}
          </b>
        </span>
      </div>
    );
    return (
      <div>
        <div className="content-header">
          <div className={clsHideTotals}>{totalLabels}</div>
          <div className="xmm-form-header">
            <Button
              htmlId="clearPreviewBtn"
              size="sm"
              style={{ height: "32px" }}
              onClick={this.clearPreviewFilters}
            >
              {localeStrings["xmm.portal.common.clear_filters"]}
            </Button>
            <div className="xmm-input-search">
              <input
                type="text"
                id="preview-search-box"
                className="xmm-input"
                placeholder={localeStrings["xmm.portal.common.search_label"]}
                onInput={this.onPreviewSearchHandler}
              />
            </div>
          </div>
        </div>
        <div id="previewGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.state.rowData}
            localeText={this.state.localeText}
            // localeTextFunc={this.state.localeTextFunc}
            animateRows={true}
            enableRangeSelection={true}
            enableCharts={true}
            columnTypes={this.state.columnTypes}
            components={this.state.components}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            sideBar={this.state.sideBar}
            statusBar={this.state.statusBar}
            multiSortKey={this.state.multiSortKey}
            sortingOrder={this.state.sortingOrder}
            onGridReady={this.onGridReady}
          />
        </div>
      </div>
    );
  }
}

Preview.propTypes = {
  loadOperation: PropTypes.object
};

// Just place holder
function timeCellRenderer(params) {
  const timeValue = defaultToZeroIfNullOrEmpty(params.value);
  let newValue = "-";
  const laborOpsScale = "HUNDREDTHS";
  // console.log(params.data, params.value);
  if (laborOpsScale === "HUNDREDTHS") {
    newValue = parseFloat(convertMinutesToHundredths(timeValue)).toFixed(2);
  } else if (laborOpsScale === "TENTHS") {
    newValue = parseFloat(convertMinutesToTenths(timeValue)).toFixed(1);
  }
  return newValue;
}

/* util to convert record {unscheduled/ scheduled labor time} based on "Labor Time scale" of Catalog  */
function convertLaborTime(value, dealerCatalog) {
  const laborTimePrecision = !dealerCatalog.laborTimePrecision
    ? "tenths"
    : dealerCatalog.laborTimePrecision;
  let timeValue = defaultToZeroIfNullOrEmpty(value);
  if (laborTimePrecision === "hundredths") {
    timeValue = parseFloat(convertMinutesToHundredths(timeValue)).toFixed(2);
  } else if (laborTimePrecision === "tenths") {
    timeValue = parseFloat(convertMinutesToTenths(timeValue)).toFixed(1);
  }
  return timeValue;
}
