/* This file used to transform rawData into UI supported data (common-ops) */
/* eslint-disable no-console */
import { isArrayExist } from "../../commonUtil/utils/object";
import { toEmptyStringIfUndefined } from "../../commonUtil/utils/string";
import { formatDateTimezone } from "../../commonUtil/utils/date";
import { xlate } from "../../commonUtil/i18n/locales";
/* Transform list of status objects; return single opcode status object to display opcode status banner */
export function transformOpcodeData(datalist, opcodeScoringEnabled) {
  let lastValidationDate = "";
  const currentDate = new Date();
  let numberOfDays = 0;
  const totalMakes = datalist.length;
  let completeCount = 0;
  let showBanner = false;
  if (isArrayExist(datalist) && totalMakes > 0) {
    datalist.forEach(function(obj, index) {
      if (obj.make && obj.make !== "ALL") {
        // if 0 - no scoring;  1 - allow scoring and check validation date
        if (opcodeScoringEnabled === 1) {
          const validationDateStr = toEmptyStringIfUndefined(
            obj.lastValidation
          );
          if (!validationDateStr) {
            showBanner = true;
          } else {
            lastValidationDate = new Date(obj.lastValidation);
            if (currentDate === lastValidationDate) {
              showBanner = false;
            }
            if (currentDate.getTime() > lastValidationDate.getTime()) {
              numberOfDays = daysBetweenDates(lastValidationDate, currentDate);
              // console.log(
              //   "opcodes validated for make since:",
              //   obj.make,
              //   numberOfDays,
              //   currentDate.toString(),
              //   lastValidationDate.toString()
              // );
              /* check if validation older than 90 days */
              if (numberOfDays && numberOfDays >= 90) {
                showBanner = true;
              }
            }
          }
        } else {
          showBanner = false;
        }

        if (showBanner) {
          completeCount = completeCount + 1;
        }
      }
    });
  }
  if (completeCount > 0) {
    return true;
  } else {
    return false;
  }
}

/* Prototype method returns No of days between two dates */
function daysBetweenDates(date1, date2) {
  // Get 1 day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  // checks always date2 > date1
  if (date2 > date1) {
    // Convert both dates to milliseconds
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMS = time2 - time1;
    // Convert back to days and return
    return Math.round(differenceMS / oneDay);
  } else {
    return 0;
  }
}
/* format date using time in hours */
function calculatePublishDate(scheduledInHours, locale) {
  const time = new Date().getTime();
  const millsec = scheduledInHours * 60 * 60 * 1000;
  const total = time + millsec;
  const d = new Date(total);
  return formatDateTimezone(d.toISOString(), true, locale);
}

/* Transform list of publish stateobject; return single status object to display publish status banner */
export function transformPublishStates(datalist, locale) {
  const totalMakes = datalist.length;
  const newlist = [];
  const futureMakes = [];
  const pendingMakes = [];
  let hasStatus = false;
  let statusMsg = null;
  let statusType = "";
  let statusCode = null;
  let scheduleDate = "";
  let startCount = 0;
  let completeCount = 0;
  let pendingCount = 0;
  let disablePublishCount = 0;
  if (datalist && datalist.length > 0) {
    if (isArrayExist(datalist) && totalMakes > 0) {
      datalist.forEach(function(obj, index) {
        if (obj.make) {
          const cloneObj = Object.assign({}, obj);
          const stateObj = getPublishStateForMake(obj);
          // console.log("publish stateobj", stateObj);

          if (Object.keys(stateObj).length > 0) {
            if (stateObj.statusCode === "NONE") {
              startCount = startCount + 1;
            } else if (stateObj.statusCode === "FUTURE") {
              completeCount = completeCount + 1;
              futureMakes.push(stateObj.make);
              // convert future schedule date from scheduledInHours
              if (
                cloneObj.scheduledInHours &&
                cloneObj.scheduledInHours > 0.0
              ) {
                scheduleDate = calculatePublishDate(
                  cloneObj.scheduledInHours,
                  locale
                );
              }
            } else if (stateObj.statusCode === "PENDING") {
              pendingCount = pendingCount + 1;
              pendingMakes.push(stateObj.make);
            }
            disablePublishCount = stateObj.disableAction
              ? disablePublishCount + 1
              : disablePublishCount;
          }
          newlist.push(cloneObj);
        }
      });
      console.log(
        "{totalMakes, start, future, pending, disable}",
        totalMakes,
        startCount,
        completeCount,
        pendingCount,
        disablePublishCount
      );
      if (startCount > 0 && startCount === totalMakes) {
        statusCode = "NONE";
        statusType = "";
        hasStatus = false;
        statusMsg = "";
      } else if (pendingCount > 0 && pendingMakes.length > 0) {
        statusCode = "PENDING";
        statusType = "info";
        hasStatus = true;

        statusMsg = xlate("xmm.portal.msg.publishing").replace(
          "%1",
          pendingMakes.join(", ")
        );
      } else if (pendingCount === 0 && completeCount > 0) {
        statusCode = "FUTURE";
        statusType = "info";
        hasStatus = true;
        statusMsg = xlate("xmm.portal.msg.scheduled_publish")
          .replace("%1", futureMakes.toString())
          .replace("%2", scheduleDate);
      }
    }

    if (!hasStatus && !statusMsg) {
      return null;
    } else {
      return {
        statusMsg,
        statusType,
        statusCode,
        disablePublishCount
      };
    }
  } else {
    return null;
  }
}

/* This returns formatted status text based on publish state object */
function getPublishStateForMake(data) {
  let statusCode = "";
  let make = "";
  let disableAction = false;
  if (
    data &&
    ((data.scheduled === 1 && data.scheduledInHours) || data.status)
  ) {
    if (data.scheduledInHours > 0.0) {
      statusCode = "FUTURE";
      make = data.make;
      disableAction = true;
      // show scheduledDate
    } else if (data.isAwaitingTurn === 1) {
      statusCode = "PENDING";
      make = data.make;
      disableAction = true;
    } else if (data.scheduledInHours <= 0.0 && data.status === "RECEIVED") {
      statusCode = "PENDING";
      make = data.make;
      disableAction = true;
    } else if (data.status === "RUNNING") {
      statusCode = "PENDING";
      make = data.make;
      disableAction = true;
    } else if (data.scheduledInHours <= 0.0) {
      statusCode = "NONE";
      disableAction = false;
    }
  } else {
    statusCode = "NONE";
    disableAction = false;
  }

  const status = {
    statusCode,
    disableAction,
    make
  };
  return status;
}
